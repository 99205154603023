import React from 'react';
import { connect } from 'react-redux';

import AccountManager from '../../../components/app/account/account-manager/AccountManager';

import '../../page.scss';

const Account = (props) => {
  return (
    <div className="page-container">
      <div className="manager-container">
        <AccountManager />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loginData: state.auth,
    userData: state.user,
    tenantData: state.tenant,
  };
};

export default connect(mapStateToProps)(Account);
