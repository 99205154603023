import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CountManager from '../../../components/app/counts/count-manager/CountManager';

import '../../page.scss';

const Count = (props) => {
  return (
    <div className="page-container w-70">
      <CountManager />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Count);
