import React, { memo } from 'react';

import SignUpForm from '../../../components/auth/sign-up-form/SignUpForm';
import SignUpButton from '../../../components/auth/sign-up-button/SignUpButton';
import SignUpTitle from '../../../components/auth/sign-up-title/SignUpTitle';
import TermsAccept from '../../../components/auth/terms-accept/TermsAccept';

const SignUp = (props) => {
  return (
    <div className="main-login-container d-flex direction-column">
      <SignUpButton url="/" buttonText="Sign In" buttonRoleInfo="Already have an account?" />
      <SignUpTitle height="20%" titleText="Get started with Scan" />
      <SignUpForm history={props.history} />
      <TermsAccept submitText="Get started for free" />
    </div>
  );
};

export default memo(SignUp);
