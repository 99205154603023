import React from 'react';
import DateFnsUtils from '@date-io/date-fns';

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import WeekPicker from './WeekPicker';

import './date-range-picker.scss';

const DateRangePicker = (props) => {
  const { step, date, onDateChange } = props;

  const renderDatePicker = () => {
    switch (step) {
      case 'Year':
        return <DatePicker views={['year']} label="Year" value={date} onChange={onDateChange} />;
      case 'Month':
        return (
          <DatePicker views={['year', 'month']} openTo="month" label="Month" value={date} onChange={onDateChange} />
        );
      case 'Week':
        return <WeekPicker selectedDate={date} handleChange={onDateChange} />;
      case 'Day':
        return <DatePicker value={date} onChange={onDateChange} />;
      default:
        return <span style={{ display: 'none' }}>All</span>;
    }
  };

  return (
    <div className="p-b-sm d-flex flex-center flex-1">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>{renderDatePicker()}</MuiPickersUtilsProvider>
    </div>
  );
};

export default DateRangePicker;
