import { createMuiTheme } from '@material-ui/core';

const theme = (mainColor) => {
  return createMuiTheme({
    typography: {
      fontFamily: 'Montserrat',
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '*': {
            'scrollbar-width': 'thin',
          },
          '*::-webkit-scrollbar': {
            width: '4px',
            height: '4px',
          },
        },
      },
      MuiTextField: {
        root: {},
      },
      MuiPickersTimePickerToolbar: {
        hourMinuteLabel: {
          alignItems: 'flex-start',
        },
      },
      MuiOutlinedInput: {
        notchedOutline: {
          borderColor: '#e9e9e9',
          borderRadius: 21,
        },
      },
      MuiInput: {
        underline: {
          '&:before': {
            borderBottom: '1px solid #E9E9E9',
          },
        },
      },
      MuiTableCell: {
        alignLeft: {
          minWidth: 120,
        },
      },
      MuiTab: {
        wrapper: {
          textTransform: 'none',
          fontWeight: 'bold',
          fontSize: '16px !important',
        },
      },
      MuiButton: {
        root: {
          height: 40,
        },
        label: {
          textTransform: 'none',
          fontWeight: 'bold',
          fontSize: 16,
        },
      },
      MuiTablePagination: {
        actions: {
          display: 'none',
        },
      },
      MuiPaginationItem: {
        root: {
          borderRadius: '50%',
          fontWeight: 'bold',
        },
      },
    },
    palette: {
      primary: {
        main: mainColor ? mainColor : '#00AAEF',
        light: mainColor ? mainColor : '#00AAEF',
        dark: '#5b667d',
        contrastText: '#ffffff',
        danger: '#d32f2f',
      },
      secondary: {
        main: '#e9e9e9',
        light: '#f2f2f2',
        dark: '#333333',
        contrastText: '#ffffff',
        danger: '#d32f2f',
      },
      inherit: {
        main: '#5b667d',
        light: '#f2f2f2',
        dark: '#333333',
        contrastText: '#ffffff',
        danger: '#d32f2f',
      },
    },
    button: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  });
};

export default theme;
