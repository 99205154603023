import {
  AUTH_RESET,
  COUNTS_COLLECTION_CLEAR,
  LOCATIONS_COLLECTION_CLEAR,
  SCHEDULES_COLLECTION_CLEAR,
  SUB_LOCATIONS_COLLECTION_CLEAR,
  TEAM_COLLECTION_CLEAR,
  TENANT_SIGN_OUT,
  USER_PHOTO_UPDATE,
  USER_SIGN_IN,
  USER_SIGN_OUT,
  USERS_COLLECTION_CLEAR,
} from './actionTypes';
import { TENANT_DATA_COLLECTION, USER_DATA_COLLECTION } from '../firebase/collections';

import { UNIQUE_TENANT } from '../errors/errors';

import { setCurrentTenant } from './tenantActions';

import { api } from '../util';
import urls from '../constants/urls';
import firebase from '../firebase/Firebase';

export const setCurrentUser = (user) => ({ type: USER_SIGN_IN, user });

export const removeCurrentUser = () => ({ type: USER_SIGN_OUT });

export const setUserPhoto = (link) => ({ type: USER_PHOTO_UPDATE, link });

export const userSignIn = (value) => async (dispatch) => {
  const { email, password } = value;
  const currentUserSnapshot = await firebase.db.collection(USER_DATA_COLLECTION).where('email', '==', email).get();
  const currentUser = { ...currentUserSnapshot.docs[0].data() };
  firebase.auth.tenantId = currentUser.tenantId;
  const auth = await firebase.doSignInWithEmailAndPassword(email, password);
  currentUser.id = currentUserSnapshot.docs[0].id;
  currentUser.emailVerified = auth.user.emailVerified;

  await api.post(urls.signIn, { tenantId: currentUser.tenantId }).then((res) => {
    if (res) {
      dispatch(setCurrentUser(currentUser));
      dispatch(setCurrentTenant(JSON.parse(res.body)));
      return 'success';
    } else {
      return 'problems';
    }
  });

  // return new Promise(((resolve, reject) => {
  //   firebase.db.collection(USER_DATA_COLLECTION).where('email', '==', value.email).get()
  //     .then(userData => {
  //       const currentUser = userData.docs[0].data();
  //
  //       currentUser.docId = userData.docs[0].id;
  //       firebase.auth.tenantId = currentUser.tenantId;
  //       firebase.doSignInWithEmailAndPassword(value.email, value.password)
  //         .then((res) => {
  //           currentUser.emailVerified = res.user.emailVerified;
  //           dispatch(setCurrentUser(currentUser));
  //           api.post(urls.signIn, {tenantId: currentUser.tenantId})
  //             .then((res) => {
  //               dispatch(setCurrentTenant(JSON.parse(res.body)));
  //               resolve();
  //             });
  //         }).catch((err) => {
  //         reject(err);
  //       });
  //     }).catch((err) => {
  //     reject(err);
  //   });
  // }));
};

export const userSignUp = (value) => async (dispatch) => {
  const { email, password } = value.userData;
  const isTenantNameUnique = await firebase.checkUnique(TENANT_DATA_COLLECTION, 'businessName', value.businessName);
  if (isTenantNameUnique) {
    const result = await api.post(urls.signUp, value);
    if (result.statusCode === 200) {
      return dispatch(userSignIn({ email, password }));
    }
  } else {
    return UNIQUE_TENANT;
  }
};

export const resetPassword = (email) => async (dispatch) => {
  try {
    const userSnapshot = await firebase.db.collection(USER_DATA_COLLECTION).where('email', '==', email).get();
    firebase.auth.tenantId = userSnapshot.docs[0].data().tenantId;
    return await firebase.doResetPassword(email);
  } catch (e) {
    console.log(e);
  }
};

export const checkSignUpData = (email) => async (dispatch) => {
  return await firebase.db.collection(USER_DATA_COLLECTION).where('email', '==', email).get();
};

export const getUser = () => async (dispatch) => {
  await firebase.doGetCurrentUser().onAuthStateChanged(async (user) => {
    if (user) {
      const userSnapshot = await firebase.db.collection(USER_DATA_COLLECTION).where('id', '==', user.uid).get();
      const currentUser = userSnapshot.docs[0].data();
      const tenantAuth = await api.post(urls.signIn, { tenantId: user.tenantId });
      currentUser.emailVerified = user.emailVerified;
      currentUser.docId = userSnapshot.docs[0].id;
      dispatch(setCurrentUser(currentUser));
      dispatch(setCurrentTenant(JSON.parse(tenantAuth.body)));

      await firebase.subscribeToNotifications();
    } else {
      dispatch({
        type: USER_SIGN_IN,
        user: { id: null },
      });
    }
  });
};

export const signOut = () => async (dispatch) => {
  return new Promise((resolve, reject) => {
    firebase.doSignOut().then(() => {
      dispatch({ type: AUTH_RESET });
      dispatch({ type: TENANT_SIGN_OUT });
      resolve();
    });
  });
};

export const uploadPhoto = (data) => (dispatch) => {
  const storage = firebase.storage.ref();
  return new Promise((resolve, reject) => {
    storage
      .child(`avatar/${data.tenantId}/${data.userId}/avatar`)
      .put(data.file)
      .then((res) => {
        storage
          .child(`avatar/${data.tenantId}/${data.userId}/avatar`)
          .getDownloadURL()
          .then((res) => {
            resolve(res);
          })
          .catch(reject);
      })
      .catch(reject);
  });
};

export const changePassword = (email, oldPassword, newPassword, tenantId) => (dispatch) => {
  firebase.auth.tenantId = tenantId;
  return firebase.doSignInWithEmailAndPassword(email, oldPassword).then(() => {
    firebase.auth.currentUser.updatePassword(newPassword).then(() => {
      return 0;
    });
  });
};

export const nullifyState = () => (dispatch) => {
  dispatch({ type: USERS_COLLECTION_CLEAR });
  dispatch({ type: TEAM_COLLECTION_CLEAR });
  dispatch({ type: LOCATIONS_COLLECTION_CLEAR });
  dispatch({ type: SUB_LOCATIONS_COLLECTION_CLEAR });
  dispatch({ type: SCHEDULES_COLLECTION_CLEAR });
  dispatch({ type: COUNTS_COLLECTION_CLEAR });
};
