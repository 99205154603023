import React, { useState } from 'react';
import { connect } from 'react-redux';

import remove from '../../../../../assets/icons/delete.svg';

import TextField from '@material-ui/core/TextField';
import Icon from '@material-ui/core/Icon';
import { Autocomplete } from '@material-ui/lab';

import './create-user-modal.scss';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

const Assign = (props) => {
  const { teams, locations, addLocation, removeLocation, locationsData, teamsData, addTeam, removeTeam, tenantData } =
    props;

  const [teamName, setTeamName] = useState('');
  const [locationName, setLocationName] = useState('');

  const getNames = (collection) => {
    return collection.map((item) => item.name);
  };

  const getTeamName = (team) => {
    return teamsData.find((item) => item.id === team).name;
  };

  const getTeamId = (name) => {
    return teamsData.find((team) => team.name === name).id;
  };

  const getItemByName = (collection, name) => {
    return collection.find((item) => item.name === name);
  };

  const getItemById = (collection, id) => {
    return collection.find((item) => item.id === id);
  };

  return (
    <div className="d-flex flex-1 direction-column">
      <div className="drawer-title">Assign to</div>
      <div className="m-t-lg assign-container">
        <div className="teams h-100 d-flex direction-column">
          <div className="d-flex align-end">
            <div className="flex-1">
              <Autocomplete
                options={getNames(teamsData.filter((item) => item.active === true))}
                onChange={(e, v) => {
                  setTeamName(v);
                }}
                value={teamName}
                id="teams"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Team"
                    placeholder="E.g Sales Rep"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </div>
            <div className="p-l-sm">
              <IconButton
                onClick={(e) => {
                  if (teamName) {
                    addTeam(getTeamId(teamName));
                    setTeamName('');
                  }
                  e.preventDefault();
                }}
                aria-label=""
                color={teamName ? 'primary' : 'secondary'}
                size="small"
              >
                <Icon color={teamName ? 'primary' : 'secondary'}>add_circle_outline</Icon>
              </IconButton>
            </div>
          </div>
          <div className="input-hint">You can choose more than one team</div>
          <div className="teams-list">
            {teams.map((team) => {
              return (
                <div key={team} className="team-list-item">
                  <p
                    style={{
                      fontWeight: 'bold',
                      color: '#5b667d',
                      fontSize: 14,
                      display: 'flex',
                      alignItems: 'center',
                      textAlign: 'center',
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    {getTeamName(team)}
                  </p>
                  <IconButton
                    onClick={(e) => {
                      removeTeam(team);
                    }}
                    aria-label=""
                    color="secondary"
                    size="small"
                  >
                    <DeleteOutlineIcon style={{ color: '#d32f2f' }} />
                  </IconButton>
                </div>
              );
            })}
          </div>
        </div>
        <div className="locations h-100 d-flex direction-column">
          <div className="d-flex align-end">
            <div className="flex-1">
              <Autocomplete
                options={locationsData.filter((item) => item.active === true).map((item) => item.name)}
                onChange={(e, v) => {
                  setLocationName(v);
                }}
                value={locationName}
                id="teams"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Locations"
                    placeholder="E.g NSW"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </div>
            <div className="p-l-sm">
              <IconButton
                onClick={(e) => {
                  if (locationName) {
                    addLocation(getItemByName(locationsData, locationName).id);
                    setLocationName('');
                  }
                  e.preventDefault();
                }}
                aria-label=""
                color={locationName ? 'primary' : 'secondary'}
                size="small"
              >
                <Icon color={locationName ? 'primary' : 'secondary'}>add_circle_outline</Icon>
              </IconButton>
            </div>
          </div>
          <div className="input-hint">You can choose more than one location</div>
          <div className="locations-list">
            {locations.map((location) => {
              return (
                <div key={location} className="location-list-item">
                  <p
                    style={{
                      fontWeight: 'bold',
                      color: '#5b667d',
                      fontSize: 14,
                      display: 'flex',
                      alignItems: 'center',
                      textAlign: 'center',
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    {getItemById(locationsData, location).name}
                  </p>
                  <IconButton
                    onClick={(e) => {
                      removeLocation(location);
                    }}
                    aria-label=""
                    color="secondary"
                    size="small"
                  >
                    <DeleteOutlineIcon style={{ color: '#d32f2f' }} />
                  </IconButton>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    tenantData: state.tenant,
  };
};

export default connect(mapStateToProps)(Assign);
