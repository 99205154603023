import React from 'react';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';

import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';

import './schedules-modal.scss';

const ScheduleSettings = (props) => {
  const { name, date, setName, setDate } = props;

  const handleDateChange = (date) => {
    setDate(date);
  };

  return (
    <div className="d-flex flex-1 direction-column">
      <div className="drawer-title">Schedule Details</div>
      <div className="schedule-details m-b-lg">
        <TextField
          className="m-t-lg"
          value={name}
          onChange={(e) => setName(e.target.value)}
          id="team-name"
          fullWidth
          label="Schedule Name"
          placeholder="E.g Sydney Main Warehouse"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
      <div className="drawer-title m-t-lg">First Scheduled Count Date</div>
      <div className="flex-1 w-100">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justify="space-between" alignItems="center">
            <KeyboardDatePicker
              variant="inline"
              format="MM/dd/yyyy"
              id="Start Date"
              margin="normal"
              value={date}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <KeyboardTimePicker
              variant="inline"
              margin="normal"
              id="time-picker"
              value={date}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change time',
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    tenantData: state.tenant,
  };
};

export default connect(mapStateToProps)(ScheduleSettings);
