import React from 'react';
import { TableBody, TableCell, TableRow, Checkbox, Tooltip } from '@material-ui/core';

import { getComparator, stableSort } from '../../../../../util/helpers';

import edit from '../../../../../assets/icons/edit.svg';
import remove from '../../../../../assets/icons/delete.svg';
import address from '../../../../../assets/icons/address.svg';

export const LocationsTableBody = (props) => {
  const {
    classes,
    rows,
    page,
    order,
    orderBy,
    rowsPerPage,
    isSelected,
    handleRowClick,
    handleRowSelect,
    removeLocation,
  } = props;

  return (
    <TableBody classes={{ root: classes.tableBody }}>
      {stableSort(rows, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, index) => {
          const isItemSelected = isSelected(row.id);
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <TableRow
              hover
              onClick={(event) => handleRowSelect(event, row.id)}
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={row.id}
              selected={isItemSelected}
            >
              <TableCell padding="checkbox">
                <Checkbox
                  className={classes.checked}
                  checked={isItemSelected}
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </TableCell>
              <TableCell component="th" id={labelId} scope="row">
                <p className="table-black-text m-0">{row.name}</p>
              </TableCell>
              <TableCell align="left">
                <p className="table-black-text m-0">{row.description}</p>
              </TableCell>
              <TableCell align="left">
                <div className="d-flex flex-start align-center">
                  <img className="m-r-md" src={address} alt="address" />
                  <span className="table-black-text m-0">{row.address}</span>
                </div>
              </TableCell>
              <TableCell align="left">
                <div className="d-flex flex-end align-center p-r-lg">
                  <Tooltip title="Edit">
                    <img src={edit} onClick={(event) => handleRowClick(event, row)} alt="edit" />
                  </Tooltip>

                  <Tooltip title="Delete">
                    <img
                      style={{ marginLeft: '24px' }}
                      onClick={async (e) => {
                        e.stopPropagation();
                        await removeLocation(row);
                      }}
                      src={remove}
                      alt="delete"
                    />
                  </Tooltip>
                </div>
              </TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  );
};
