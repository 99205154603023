import React, { Fragment, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import UserDetails from './UserDetails';
import Assign from './Assign';
import StyledButton from '../../../../shared/Button';

import { displayFullName, FULL_NAME_REGEX } from '../../../../../util/helpers';

import { getUsersCollection, createUser, updateUser } from '../../../../../actions/usersCollectionActions';

import { resetPassword } from '../../../../../actions/userActions';

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import LoadScreen from '../../../../load-screen/LoadScreen';
import Alert from '../../../../shared/Alert';
import CancelButton from '../../../../shared/CancelButton';

import './create-user-modal.scss';

const useStyles = makeStyles({
  drawer: {
    width: '100%',
  },
  drawerPaper: {
    boxShadow: '-4px 1px 13px 1px  rgba(87,73,87,1)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '50%',
  },
  nameInput: {
    width: '40%',
  },
  positionInput: {
    width: '100%',
  },
  tab: {
    marginTop: 20,
    marginBottom: 20,
    borderBottom: '1px solid #E9E9E9',
    padding: 0,
  },
  tabItem: {
    width: 'max-content !important',
    display: 'flex',
    padding: '0 !important',
    justifyContent: 'flex-start !important',
  },
  radioGroup: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const CreateUserModal = (props) => {
  const classes = useStyles();
  const {
    drawerIsOpen,
    userName,
    closeDrawer,
    tenantData,
    createUser,
    editUser,
    updateUser,
    editMode,
    usersCollection,
    userData,
    resetPassword,
  } = props;

  const [drawerTabValue, setDrawerTabValue] = useState(0);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [position, setPosition] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [role, setRole] = useState('');
  const [description, setDescription] = useState('');
  const [teams, setTeams] = useState([]);
  const [locations, setLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorType, setErrorType] = useState('error');
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    const stateEditSet = (editUser) => {
      if (editMode) {
        const name = editUser.fullName.split(FULL_NAME_REGEX);
        setFirstName(name[0]);
        setLastName(name[name.length - 1]);
        setPosition(editUser.position);
        setEmail(editUser.email);
        setPhone(editUser.phone);
        setRole(editUser.role);
        setDescription(editUser.description);
        setLocations(editUser.locations);
        setTeams(editUser.teams);
      } else {
        stateReset();
      }
    };

    stateEditSet(editUser);
  }, [editMode, editUser]);

  const stateReset = () => {
    setFirstName('');
    setLastName('');
    setPosition('');
    setEmail('');
    setPhone('');
    setRole('');
    setDescription('');
    setTeams([]);
  };

  const singleSuccessCreate = (email) => {
    setIsLoading(false);
    setErrorText(`Invitation was sent to ${email}`);
    setErrorType('success');
    setError(true);
    closeDrawer();
  };

  const singleErrorCreate = (message) => {
    setIsLoading(false);
    setErrorText(message);
    setErrorType('error');
    setError(true);
  };

  const drawerTabValueHandleChange = (event, newValue) => {
    setDrawerTabValue(newValue);
  };

  const addTeam = (team) => {
    if (teams.some((item) => item === team)) {
      return;
    }
    setTeams([...teams, team]);
  };

  const addLocation = (location) => {
    if (locations.some((item) => item === location)) {
      return;
    }
    setLocations([...locations, location]);
  };

  const removeTeam = (id) => {
    setTeams(teams.filter((team) => team !== id));
  };

  const removeLocation = (id) => {
    setLocations(locations.filter((location) => location !== id));
  };

  const successResend = () => {
    setErrorText(`Invitation was sent to ${email}`);
    setErrorType('success');
    setError(true);
    setIsLoading(false);
  };

  const rejectedResend = (e) => {
    setErrorText(e.message);
    setErrorType('error');
    setError(true);
    setIsLoading(false);
  };

  const onSubmit = async (e) => {
    if (!firstName || !lastName || !email || !role) {
      setErrorText('Input all required fields');
      setErrorType('error');
      setError(true);
      return;
    }

    if (userData.role !== 'Admin') {
      setErrorText('Permission denied');
      closeDrawer();
      setErrorType('error');
      setError(true);
      return;
    }

    setIsLoading(true);

    const user = {
      firstName,
      lastName,
      email,
      tenantId: tenantData.id,
      phone,
      role,
      position,
      description,
      locations,
      teams,
    };

    if (editMode) {
      updateUser({
        ...user,
        id: editUser.id,
        docId: editUser.docId,
        lastEmail: editUser.email,
        photo: editUser.photo,
      })
        .then((res) => {
          const emailCheck = usersCollection.some((item) => item.email === user.email);
          setIsLoading(false);
          setErrorText(
            `User ${displayFullName(res.fullName)} was successfully updated${
              emailCheck ? `. Email was not updated. Current mail: ${editUser.email}` : ''
            } `,
          );
          setErrorType('success');
          setError(true);
          closeDrawer();
        })
        .catch(() => {
          setIsLoading(false);
          setErrorText('Email is already in use by another account or improperly formatted');
          setErrorType('error');
          setError(true);
        });
    } else {
      await createUser(user)
        .then((res) => {
          if (res.status === 'success') singleSuccessCreate(res.user.email);
          else singleErrorCreate(res.error);
        })
        .catch(() => {
          singleErrorCreate('Unexpected error. Please try again later');
        });
    }
  };

  const resendInvite = async () => {
    try {
      setIsLoading(true);
      await resetPassword(email);
      successResend();
    } catch (e) {
      rejectedResend(e);
    }
  };

  return (
    <Fragment>
      <Drawer
        className={classes.drawer}
        anchor={'right'}
        open={drawerIsOpen}
        classes={{ paperAnchorRight: classes.drawerPaper }}
        ModalProps={{ onBackdropClick: closeDrawer }}
      >
        <div className="drawer-container">
          <div className="drawer-title">{editMode ? 'Edit user' : 'Add user'}</div>
          {editMode ? <div className="table-black-text">{displayFullName(editUser.fullName)}</div> : null}
          <Paper elevation={0}>
            <Tabs
              value={drawerTabValue}
              indicatorColor="primary"
              textColor="primary"
              className={classes.tab}
              onChange={drawerTabValueHandleChange}
            >
              <Tab className={classes.tabItem} classes={{ wrapper: classes.tabItem }} label="User Details" />
              <Tab className={classes.tabItem} classes={{ wrapper: classes.tabItem }} label="Assign" />
            </Tabs>
          </Paper>
          {drawerTabValue === 0 ? (
            <UserDetails
              firstName={firstName}
              lastName={lastName}
              photo={editUser.photo}
              position={position}
              email={email}
              phone={phone}
              role={role}
              description={description}
              setFirstName={setFirstName}
              setLastName={setLastName}
              setPosition={setPosition}
              setEmail={setEmail}
              setPhone={setPhone}
              setRole={setRole}
              setDescription={setDescription}
              userName={userName}
            />
          ) : (
            <Assign
              teamsData={props.teamsData}
              locationsData={props.locationsData}
              addLocation={addLocation}
              removeLocation={removeLocation}
              addTeam={addTeam}
              removeTeam={removeTeam}
              teams={teams}
              locations={locations}
            />
          )}
        </div>
        <div className="user-modal-footer">
          <div className="d-flex align-center">
            {editMode ? <StyledButton text="Resend Invite" onClick={resendInvite} /> : null}
          </div>
          <div className="d-flex align-center">
            <CancelButton onClick={closeDrawer} />
            <StyledButton text={editMode ? 'Save' : 'Send Invite'} onClick={onSubmit} />
          </div>
        </div>
      </Drawer>
      {isLoading ? <LoadScreen /> : null}
      <Alert variant={errorType} message={errorText} open={error} onClose={() => setError(false)} />
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createUser,
      getUsersCollection,
      updateUser,
      resetPassword,
    },
    dispatch,
  );

const mapStateToProps = (state) => {
  return {
    tenantData: state.tenant,
    usersCollection: state.usersCollection,
    locationsData: state.locations,
    userData: state.user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateUserModal);
