import React, { Fragment, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { updateSubLocation } from '../../../../../actions/subLocationsCollectionActions';
import { createLocation } from '../../../../../actions/locationsCollectionActions';
import { createSubLocation } from '../../../../../actions/subLocationsCollectionActions';

import StyledButton from '../../../../shared/Button';
import LoadScreen from '../../../../load-screen/LoadScreen';
import Alert from '../../../../shared/Alert';

import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';

import './sub-locations-modal.scss';
import CancelButton from '../../../../shared/CancelButton';

const useStyles = makeStyles({
  drawer: {
    width: '100%',
  },
  drawerPaper: {
    boxShadow: '-4px 1px 13px 1px  rgba(87,73,87,1)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '50%',
  },
  nameInput: {
    width: '40%',
  },
  positionInput: {
    width: '100%',
  },
  tab: {
    marginTop: 20,
    marginBottom: 20,
  },
  radioGroup: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const SubLocationsModal = (props) => {
  const classes = useStyles();
  const {
    drawerIsOpen,
    closeDrawer,
    tenantData,
    locationsData,
    editMode,
    editSubLocation,
    createSubLocation,
    updateSubLocation,
  } = props;

  useEffect(() => {
    const stateEditSet = (editSubLocation) => {
      if (editMode) {
        setName(editSubLocation.name);
        setDescription(editSubLocation.description);
        setNote(editSubLocation.note);
        if (editSubLocation.location) {
          setActiveLocation(locationsData.find((item) => item.id === editSubLocation.location).name);
        }
      } else {
        stateReset();
      }
    };

    stateEditSet(editSubLocation);
  }, [editMode, editSubLocation, locationsData]);

  const [name, setName] = useState('');
  const [activeLocation, setActiveLocation] = useState('');
  const [description, setDescription] = useState('');
  const [note, setNote] = useState('');
  const [error, setError] = useState(false);
  const [errorType, setErrorType] = useState('error');
  const [errorText, setErrorText] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const stateReset = () => {
    setName('');
    setDescription('');
    setNote('');
    setActiveLocation('');
  };

  const getLocation = (name) => {
    return locationsData.find((item) => item.name === name);
  };

  const collectData = () => {
    return {
      name,
      description,
      note,
      tenantId: tenantData.id,
      location: activeLocation ? getLocation(activeLocation).id : '',
    };
  };

  const onSubmit = async () => {
    if (!name || !description) {
      setErrorText('Input all required data');
      setErrorType('error');
      setError(true);
      return;
    }

    setIsLoading(true);

    try {
      if (editMode) {
        await updateSubLocation({ ...collectData(), id: editSubLocation.id });
      } else {
        await createSubLocation(collectData());
      }
    } catch {
      setErrorText('Something went wrong');
      setErrorType('error');
      setError(true);
    } finally {
      setIsLoading(false);
      closeDrawer();
      stateReset();
    }
  };

  return (
    <Fragment>
      <Drawer
        className={classes.drawer}
        anchor={'right'}
        open={drawerIsOpen}
        classes={{ paperAnchorRight: classes.drawerPaper }}
        ModalProps={{ onBackdropClick: closeDrawer }}
      >
        <div className="d-flex direction-column" style={{ padding: '24px' }}>
          <div>
            <div className="drawer-title m-b-lg">{editMode ? 'Edit Sub-Location' : 'Add Sub-Location'}</div>
            {editMode ? <div className="table-black-text m-t-lg">{editSubLocation.name}</div> : null}
          </div>
          <div className="location-select">
            <p style={{ fontWeight: 'bold', fontSize: 16, color: tenantData.mainColor }}>Location</p>
            <Autocomplete
              options={locationsData.filter((item) => item.active === true).map((item) => item.name)}
              onChange={(e, v) => {
                setActiveLocation(v);
              }}
              value={activeLocation}
              id="sub-locations"
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Choose Location"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </div>
          <div className="sub-location-details m-t-lg">
            <div className="drawer-title">Titles</div>
            <TextField
              className="m-t-md"
              value={name}
              onChange={(e) => setName(e.target.value)}
              id="team-name"
              fullWidth
              label="Sub-Location Name"
              placeholder="E.g Sydney Main Warehouse"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              className="m-t-lg"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              id="description"
              fullWidth
              label="Description"
              placeholder="Enter description"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className="sub-location-notes m-t-lg">
            <div className="drawer-title m-t-md">Sub-Location Notes</div>
            <TextField
              className="m-t-md"
              value={note}
              onChange={(e) => setNote(e.target.value)}
              id="description"
              fullWidth
              label="Note"
              placeholder="Enter note"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </div>
        <div className="submit-modal-footer">
          <CancelButton onClick={closeDrawer} />
          <StyledButton text="Save" onClick={onSubmit} />
        </div>
      </Drawer>
      {isLoading ? <LoadScreen /> : null}
      <Alert variant={errorType} message={errorText} open={error} onClose={() => setError(false)} />
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateSubLocation,
      createLocation,
      createSubLocation,
    },
    dispatch,
  );

const mapStateToProps = (state) => {
  return {
    tenantData: state.tenant,
    locationsData: state.locations,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubLocationsModal);
