import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import BusinessSettings from './business-settings/BusinessSettings';
import CustomizeSettings from './customize-settings/CustomizeSettings';
import SettingsModal from './settings-modal/SettingsModal';

import StyledButton from '../../shared/Button';
import '../manager.scss';
import Alert from '../../shared/Alert';

const useStyles = makeStyles({
  searchInput: {
    margin: 0,
  },
  tab: {
    marginBottom: 10,
    borderBottom: '1px solid #E9E9E9',
    padding: 0,
  },
  tabItem: {
    width: 'max-content !important',
    display: 'flex',
    padding: '0 !important',
    justifyContent: 'flex-start !important',
  },
  drawer: {
    width: '100%',
  },
  selectHidden: {
    display: 'none',
  },
  inputText: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#00AAEF',
    textAlign: 'center',
    border: 'none',
  },
});

const SettingsManager = (props) => {
  const classes = useStyles();

  const { tenant, user } = props;

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [error, setError] = useState(false);
  const [errorType, setErrorType] = useState('error');
  const [errorText, setErrorText] = useState('');

  const openDrawer = () => {
    if (user.role !== 'Admin') {
      setErrorText('Permission denied');
      setErrorType('warning');
      setError(true);
      return;
    }

    setDrawerIsOpen(true);
  };

  const closeDrawer = () => {
    setDrawerIsOpen(false);
  };

  const tabValueHandleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const tabValueSwitch = () => {
    if (tabValue === 0) {
      return <BusinessSettings tenant={tenant} openDrawer={openDrawer} />;
    } else {
      return <CustomizeSettings tenant={tenant} openDrawer={openDrawer} />;
    }
  };

  return (
    <Fragment>
      <div className="manager-container">
        <div className="manager-head">
          <div className="page-title-container">
            <h3 className="page-title">Settings</h3>
          </div>
          <div>
            <StyledButton onClick={openDrawer} text="Edit" />
          </div>
        </div>
        <div className="table-switch">
          <Paper elevation={0}>
            <Tabs
              value={tabValue}
              indicatorColor="primary"
              textColor="primary"
              className={classes.tab}
              onChange={tabValueHandleChange}
            >
              <Tab className={classes.tabItem} classes={{ wrapper: classes.tabItem }} label="Business" />
              <Tab className={classes.tabItem} classes={{ wrapper: classes.tabItem }} label="Customize" />
            </Tabs>
          </Paper>
        </div>
        <div className="flex-1 bg-white">{tabValueSwitch()}</div>
      </div>
      <SettingsModal tenant={tenant} drawerIsOpen={drawerIsOpen} closeDrawer={closeDrawer} />
      <Alert variant={errorType} message={errorText} open={error} onClose={() => setError(false)} />
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const mapStateToProps = (state) => {
  return {
    tenant: state.tenant,
    user: state.user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsManager);
