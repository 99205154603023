const firebaseConfig = {
  apiKey: 'AIzaSyAddgtoidj1jfsgaqDzL0ycq-VXEcMAx3Y',
  authDomain: 'scan-opologic.firebaseapp.com',
  databaseURL: 'https://scan-opologic.firebaseio.com',
  projectId: 'scan-opologic',
  storageBucket: 'scan-opologic.appspot.com',
  messagingSenderId: '355784151606',
  appId: '1:355784151606:web:bef371152c46b88b263e97',
  measurementId: 'G-2HJW4H1SF1',
};

const firebaseConfigDev = {};

export default firebaseConfig;
