import React from 'react';

import './counts-modal.scss';

const CountNotes = (props) => {
  const { note } = props;

  return (
    <div className="w-100 h-100 p-t-lg">
      <div className="drawer-title-black">Note</div>
      <div className="drawer-title m-t-lg">{note ? note : 'Note is empty'}</div>
    </div>
  );
};

export default CountNotes;
