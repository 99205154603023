import React, { memo } from 'react';

import ResetPasswordForm from '../../../components/auth/reset-password-form/ResetPasswordForm';
import SignUpButton from '../../../components/auth/sign-up-button/SignUpButton';
import SignUpTitle from '../../../components/auth/sign-up-title/SignUpTitle';

import TermsAccept from '../../../components/auth/terms-accept/TermsAccept';

const ResetPassword = () => {
  return (
    <div className="main-login-container d-flex direction-column">
      <SignUpButton url="/sign-up" buttonText="Sign Up" buttonRoleInfo="Don`t yet have an account?" />
      <SignUpTitle height="20%" titleText="Reset password" />
      <ResetPasswordForm />
      <TermsAccept submitText="SignIn" />
    </div>
  );
};

export default memo(ResetPassword);
