import React from 'react';

import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const StyledButton = (props) => {
  const { text, onClick, tenant, maxContent, disabled } = props;

  const useStyles = makeStyles({
    button: {
      backgroundColor: tenant.mainColor,
      borderColor: tenant.mainColor,
      float: 'center',
      color: '#ffffff',
      borderRadius: 30,
      paddingLeft: 40,
      width: maxContent ? 'max-content' : 250,
      paddingRight: 40,
      fontSize: 14,
      textAlign: 'center',
      marginLeft: 12,
      '&:hover': {
        borderColor: tenant.mainColor,
        color: tenant.mainColor,
      },
      '&:disabled': {
        color: 'gray',
        backgroundColor: '#f9f9f9',
      },
    },
  });

  const classes = useStyles();

  return (
    <div>
      <Button onClick={onClick} className={classes.button} color="primary" variant="outlined" disabled={disabled}>
        {text}
      </Button>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    tenant: state.tenant,
  };
};

export default connect(mapStateToProps)(StyledButton);
