import React, { useEffect, useState } from 'react';

import { TextField } from '@material-ui/core';
import MuiPhoneNumber from 'material-ui-phone-number';

import './business-settings.scss';

const BusinessSettings = (props) => {
  useEffect(() => {}, [props.tenant]);

  const { tenant, openDrawer } = props;
  const [phone, setPhone] = useState(tenant.phone);

  return (
    <div className="w-100 h-100 d-flex direction-column">
      <div className="business-info-settings">
        <div className="drawer-title" style={{ marginTop: '4px' }}>
          Business Info
        </div>
        <div className="m-t-lg w-100">
          <TextField
            fullWidth
            label="Business Name"
            value={tenant.businessName}
            InputProps={{
              readOnly: true,
            }}
            onClick={openDrawer}
          />
        </div>
        <div className="w-100 m-t-lg">
          <TextField
            fullWidth
            label="TAX Number"
            value={tenant.TAXnumber}
            InputProps={{
              readOnly: true,
            }}
            onClick={openDrawer}
          />
        </div>
      </div>
      <div className="business-address-settings">
        <div className="drawer-title m-t-lg">Address</div>
        <div className="w-100 m-t-lg">
          <TextField
            fullWidth
            label="Address Line"
            value={tenant.address}
            InputProps={{
              readOnly: true,
            }}
            onClick={openDrawer}
          />
        </div>
      </div>
      <div className="business-contact-info">
        <div className="drawer-title m-t-lg">Contact</div>
        <div className="d-flex space-between w-100 m-t-lg">
          <div className="w-40">
            <TextField
              fullWidth
              label="Email"
              value={tenant.email}
              InputProps={{
                readOnly: true,
              }}
              onClick={openDrawer}
            />
          </div>
          <div className="w-40">
            <MuiPhoneNumber
              value={phone}
              onChange={(value) => setPhone(value)}
              fullWidth
              label="Phone number"
              placeholder="Your phone number"
              defaultCountry={'au'}
              InputProps={{
                readOnly: true,
              }}
              onClick={openDrawer}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessSettings;
