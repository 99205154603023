import React from 'react';

import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const CustomButton = (props) => {
  const { text, onClick, tenant, color, textColor, width, disabled, borderColor } = props;

  const useStyles = makeStyles({
    button: {
      backgroundColor: color,
      borderColor: borderColor ? borderColor : color,
      float: 'center',
      color: textColor,
      borderRadius: 30,
      paddingLeft: 40,
      width: width,
      paddingRight: 40,
      fontSize: 14,
      textAlign: 'center',
      marginLeft: 12,
      '&:hover': {
        borderColor: tenant.mainColor,
        color: tenant.mainColor,
      },
      '&:disabled': {
        color: 'gray',
        backgroundColor: '#f9f9f9',
      },
    },
  });

  const classes = useStyles();

  return (
    <div>
      <Button
        onClick={onClick}
        className={classes.button}
        color="primary"
        variant="outlined"
        disabled={disabled ? disabled : false}
      >
        {text}
      </Button>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    tenant: state.tenant,
  };
};

export default connect(mapStateToProps)(CustomButton);
