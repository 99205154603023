import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import TextField from '@material-ui/core/TextField';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Autocomplete from '@material-ui/lab/Autocomplete';

import LoadScreen from '../../load-screen/LoadScreen';
import Alert from '../../shared/Alert';

import { authCreateBusiness } from '../../../actions/authActions';
import { userSignUp } from '../../../actions/userActions';

import './business-form.scss';

const useStyles = makeStyles({
  input: {
    margin: 10,
    width: '95%',
    '&hover': {
      borderColor: '#00aeef',
    },
  },
  button: {
    backgroundColor: '#00aeef',
    borderColor: '#00aeef',
    float: 'center',
    color: '#ffffff',
    borderRadius: 30,
    paddingRight: 40,
    paddingLeft: 40,
    marginTop: 20,
    fontSize: 14,
    textAlign: 'center',
    '&:hover': {
      borderColor: '#00aeef',
      color: '#00aeef',
      backgroundColor: '#ffffff',
    },
    '&:disabled': {
      color: 'gray',
      backgroundColor: '#f9f9f9',
    },
  },
  buttonBack: {
    backgroundColor: '#ffffff',
    borderColor: '#00aeef',
    float: 'center',
    color: '#00aeef',
    borderRadius: 30,
    paddingRight: 40,
    paddingLeft: 40,
    fontSize: 14,
    marginTop: 20,
    textAlign: 'center',
    '&:hover': {
      borderColor: '#00aeef',
      backgroundColor: '#00aeef',
      color: '#ffffff',
    },
  },
  visibilityIcon: {
    cursor: 'pointer',
  },
  tab: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabItem: {
    flex: 1,
    zIndex: 2,
    color: '#00aeef',
    opacity: 1,
  },
  indicator: {
    zIndex: 1,
    height: '100%',
    borderRadius: '30px',
    backgroundColor: '#00aeef',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) !important',
  },
  activeTab: {
    border: '2px solid #00a157',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) !important;',
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

const BusinessForm = (props) => {
  const classes = useStyles();
  const { loginData, countriesData } = props;

  const [employeeValue, setEmployeeValue] = useState(0);
  const [businessName, setBusinessName] = useState(loginData.businessName);
  const [businessType, setBusinessType] = useState(loginData.businessType);
  const [country, setCountry] = useState(loginData.country);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    const { loginData } = props;

    if (loginData.email === '' || loginData.fullName === '') {
      props.history.push('/sign-up');
    }
  }, [props]);

  const changeEmployeeValue = (event, newValue) => {
    setEmployeeValue(newValue);
  };

  const employeeHandler = () => {
    if (employeeValue === 0) {
      return 10;
    } else {
      return 20;
    }
  };

  const changeBusinessName = (e) => {
    if (e.target.value !== ' ') {
      setBusinessName(e.target.value);
    } else {
      setBusinessName('');
      e.preventDefault();
    }
  };

  const changeBusinessType = (e) => {
    if (e.target.value !== ' ') {
      setBusinessType(e.target.value);
    } else {
      setBusinessType('');
      e.preventDefault();
    }
  };

  const changeCountry = (e, v) => {
    if (e.target.value !== ' ') {
      setCountry(v.label);
    } else {
      setCountry('');
      e.preventDefault();
    }
  };

  function countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== 'undefined'
      ? isoCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
      : isoCode;
  }

  const showError = (error) => {
    setIsLoading(false);
    console.log(error);
    setErrorText(error instanceof Error ? error.message : error);
    setError(true);
  };

  const onSubmit = async () => {
    if (!businessName || !businessType || !country) {
      return;
    }
    const { userSignUp, loginData } = props;
    const tenantData = { businessName, businessType, maxEmployee: employeeHandler(), country };
    const userData = {
      fullName: loginData.fullName,
      email: loginData.email,
      password: loginData.password,
      role: 'Admin',
      position: ' ',
      phone: ' ',
      description: ' ',
      teams: [],
      locations: [],
    };
    const signUpData = { ...tenantData, userData };
    setIsLoading(true);
    authCreateBusiness({ businessName, businessType, maxEmployee: employeeHandler(), country });
    await userSignUp(signUpData)
      .then((res) => {
        showError(res);
      })
      .catch((error) => showError(error));
  };

  return (
    <Fragment>
      <div className="d-flex flex-center align-center direction-column flex-1">
        <div className="subtitle-container">
          <Hidden smDown>
            <p className="subtitle-text">
              Please provide the following business Information so we can build out your profile and get your team
              scanning.
            </p>
          </Hidden>
        </div>
        <form className="d-flex direction-column form-container flex-1" noValidate autoComplete={false}>
          <div className="form-subtitle-container p-10 m-0 flex-1">
            <p className="business-title">Business</p>
            <Hidden smDown>
              <p className="business-form-hint m-0">
                Enter the name of your business and Its type so we can setup your admin panel
              </p>
            </Hidden>
          </div>
          <TextField
            className={classes.input}
            id="full-name"
            type="text"
            label="Business Name"
            placeholder="E.g. Opologic"
            InputLabelProps={{
              shrink: true,
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                onSubmit();
              }
            }}
            onChange={changeBusinessName}
          />

          <TextField
            className={classes.input}
            id="business-type"
            type="text"
            label="Business Type"
            placeholder="Business type"
            InputLabelProps={{
              shrink: true,
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                onSubmit();
              }
            }}
            onChange={changeBusinessType}
          />
          <div className="form-subtitle-container p-10 m-0">
            <p className="business-title">Number of employers</p>
            <p className="business-form-hint m-0">Select the number of employees you have.</p>
          </div>
          <div className="employee-count">
            <Tabs
              className={classes.tab}
              value={employeeValue}
              classes={{ indicator: classes.indicator }}
              onChange={changeEmployeeValue}
              indicatorColor="primary"
              variant="fullWidth"
              style={{ height: '32px' }}
            >
              <Tab label="0-10" className={classes.tabItem} />
              <Tab label="11 or more" className={classes.tabItem} />
            </Tabs>
          </div>
          <div className="w-100 h-100">
            <div className="p-10 m-0">
              <p className="business-title m-0">Country</p>
            </div>
            <Autocomplete
              id="country-select-demo"
              style={{ width: '100%' }}
              options={countriesData}
              classes={{
                option: classes.option,
              }}
              onChange={changeCountry}
              autoHighlight
              getOptionLabel={(option) => (typeof option.label === 'string' ? option.label : option)}
              renderOption={(option) => (
                <React.Fragment>
                  <span>{countryToFlag(option.code)}</span>
                  {option.label}
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  type="text"
                  label="Country"
                  className={classes.input}
                  placeholder="E.g Australia"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      onSubmit();
                    }
                  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )}
            />
          </div>
          <div className="d-flex w-100 align-end space-between submit-business-container flex-wrap-reverse">
            <Link to="/sign-up" style={{ textDecoration: 'none' }}>
              <Button className={classes.buttonBack} color="primary" variant="outlined">
                Back
              </Button>
            </Link>
            <Button
              onClick={onSubmit}
              className={classes.button}
              color="primary"
              variant="outlined"
              disabled={!businessName || !businessType || !country}
            >
              Next
            </Button>
          </div>
        </form>
      </div>
      <Alert variant="error" open={error} message={errorText} onClose={() => setError(false)} />
      {isLoading ? <LoadScreen /> : null}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loginData: state.auth,
    countriesData: state.countries,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      authCreateBusiness,
      userSignUp,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(BusinessForm);
