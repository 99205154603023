import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isBefore } from 'date-fns';

import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import SearchIcon from '@material-ui/icons/Search';
import DateFnsUtils from '@date-io/date-fns';
import subMonths from 'date-fns/subMonths';

import OverdueTable from './overdue-table/OverdueTable';
import CompletedTable from './completed-table/CompletedTable';
import ItemsCountedTable from './items-counted-table/ItemsCountedTable';

import StyledButton from '../../shared/Button';
import Alert from '../../shared/Alert';

import { isCountCompleted } from '../../../util/counts';
import { displayFullName } from '../../../util/helpers';
import { overdue, completedCounts, itemsCounted } from '../../../constants/pathNames';
import urls from '../../../constants/urls';

const useStyles = makeStyles((theme) => ({
  inputFilterText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    textAlign: 'left',
    border: 'none',
    width: 235,
  },
  inputFilterIcon: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    border: 'none',
  },
  datePicker: {
    paddingLeft: 25,
    paddingRight: 25,
  },
}));

const ReportsManager = (props) => {
  const { location, tenant, counts, locationsData, usersList } = props;
  const classes = useStyles();

  const [searchValue, setSearchValue] = useState('');
  const [searchVisible, setSearchVisible] = useState(false);
  const [selected, setSelected] = useState([]);

  const [errorType, setErrorType] = useState('error');
  const [errorText, setErrorText] = useState('');
  const [error, setError] = useState(false);

  const [locationOpen, setLocationOpen] = useState(false);
  const [productOpen, setProductOpen] = useState(false);
  const [nameOpen, setNameOpen] = useState(false);

  const [locationFilter, setLocationFilter] = useState('All');
  const [productFilter, setProductFilter] = useState('All');
  const [nameFilter, setNameFilter] = useState('All');

  const [selectedDateFrom, setSelectedDateFrom] = useState(subMonths(new Date(), 3));
  const [selectedDateTo, setSelectedDateTo] = useState(new Date()); // add new Date()

  const handleLocationOpen = () => setLocationOpen(!locationOpen);
  const handleLocationChange = (e) => setLocationFilter(e.target.value);

  const handleProductOpen = () => setProductOpen(!productOpen);
  const handleProductChange = (e) => setProductFilter(e.target.value);

  const handleNameOpen = () => setNameOpen(!nameOpen);
  const handleNameChange = (e) => setNameFilter(e.target.value);

  const filterRows = (data) => {
    return data
      .filter((count) => {
        if (location.pathname === overdue) {
          return count.date && isBefore(count.date, new Date()) && !isCountCompleted(count);
        } else if (location.pathname === completedCounts) {
          const countDate = new Date(count.date);
          return selectedDateTo && selectedDateFrom
            ? countDate >= selectedDateFrom && countDate <= selectedDateTo && isCountCompleted(count)
            : isCountCompleted(count);
        } else {
          return true;
        }
      })
      .filter((count) => {
        return locationFilter === 'All' || count.location.id === locationFilter;
      })
      .filter(
        (count) =>
          count.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
          count.id?.toLowerCase().includes(searchValue.toLowerCase()),
      );
  };

  const filterItemCountedRows = (data) => {
    const resultArray = [];

    data.forEach((count) => {
      if (!count.scans) {
        return true;
      }

      const { scans } = count;
      const entries = Object.entries(scans);

      entries.forEach((scan) => {
        const _scan = scan[1];
        const products = _scan.products;
        const counter = displayFullName(usersList.find((_user) => _user.id === _scan.userId)?.fullName);

        products.forEach((product) =>
          resultArray.push({
            id: count.id,
            location: { id: count.location.id, name: count.location.name },
            ref: product.ref,
            lot: product.lot,
            qty: product.quantity,
            date: count.date,
            name: count.name,
            counter,
            ...product,
          }),
        );
      });
    });
    return resultArray
      .filter((count) => {
        if (location.pathname === itemsCounted) {
          const countDate = new Date(count.date);

          return selectedDateTo && selectedDateFrom
            ? countDate >= selectedDateFrom && countDate <= selectedDateTo
            : count;
        } else {
          return true;
        }
      })
      .filter((count) => {
        return locationFilter === 'All' || count.location.id === locationFilter;
      })
      .filter((count) => {
        return productFilter === 'All' ||
          count.values?.find((item) => item?.label.toLowerCase() === productFilter) ||
          (count.ref && count?.hasOwnProperty(productFilter))
          ? count
          : null;
      })
      .filter((count) => {
        return nameFilter === 'All' || count.name === nameFilter;
      })
      .filter(
        (count) =>
          count.ref?.toLowerCase().includes(searchValue.toLowerCase()) ||
          count.location?.name.toLowerCase().includes(searchValue.toLowerCase()),
      );
  };

  const getTitleFromPathName = (path) => {
    switch (path) {
      case overdue:
        return 'Overdue';
      case completedCounts:
        return 'Completed Counts';
      case itemsCounted:
        return 'Items Counted';
      default:
        return 'Overdue';
    }
  };

  const getCountRows = (path) => {
    switch (path) {
      case overdue:
        return filterRows(counts);
      case completedCounts:
        return filterRows(counts);
      case itemsCounted:
        return filterItemCountedRows(counts);
      default:
        return filterRows(counts);
    }
  };

  const renderTables = (path) => {
    switch (path) {
      case overdue:
        return <OverdueTable tenant={tenant} rows={filterRows(counts)} selected={selected} setSelected={setSelected} />;
      case completedCounts:
        return (
          <CompletedTable tenant={tenant} rows={filterRows(counts)} selected={selected} setSelected={setSelected} />
        );
      case itemsCounted:
        return (
          <ItemsCountedTable
            tenant={tenant}
            rows={filterItemCountedRows(counts)}
            selected={selected}
            setSelected={setSelected}
          />
        );
      default:
        return null;
    }
  };

  const getUrlFromPathName = (path) => {
    switch (path) {
      case overdue:
        return urls.exportOverdueCounts;
      case completedCounts:
        return urls.exportCompletedCounts;
      case itemsCounted:
        return urls.exportCountedItems;
      default:
        return null;
    }
  };

  const getFileName = (path) => {
    switch (path) {
      case overdue:
        return 'Overdue Counts List.xlsx';
      case completedCounts:
        return 'Completed Counts List.xlsx';
      case itemsCounted:
        return 'Counted Items List.xlsx';
      default:
        return null;
    }
  };

  const exportFile = (event) => {
    event.preventDefault();

    if (location.pathname === overdue && !selected.length) {
      setErrorText('Please, select count(s) to export');
      setErrorType('error');
      setError(true);
      return;
    }

    const body = !selected.length
      ? getCountRows(location.pathname)
      : getCountRows(location.pathname).filter((count) => selected.includes(count.id));

    fetch(`${getUrlFromPathName(location.pathname)}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = getFileName(location.pathname);
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  };

  return (
    <>
      <div className="manager-container">
        <div className="manager-head">
          <div className="page-title-container w-40 flex-start flex-1">
            <h3 className="page-title">{getTitleFromPathName(location.pathname)}</h3>
            <p className="count">({getCountRows(location.pathname).length})</p>
          </div>
          <div className={location.pathname === overdue ? 'search-container m-r-md' : 'search-container-sub m-r-md'}>
            <div
              className={
                location.pathname === overdue
                  ? 'search-input d-flex align-start flex-center'
                  : 'search-input-sub d-flex align-start flex-center'
              }
            >
              {searchVisible ? (
                <div
                  className={location.pathname === overdue ? 'search-input-container' : 'search-input-container-sub'}
                >
                  <FormControl size="small" variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Search...</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      value={searchValue}
                      type="text"
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <div
                            className="h-100 d-flex align-center cursor-pointer"
                            onClick={() => {
                              setSearchValue('');
                              setSearchVisible(false);
                            }}
                          >
                            <SearchIcon />
                          </div>
                        </InputAdornment>
                      }
                      labelWidth={70}
                    />
                  </FormControl>
                </div>
              ) : (
                <IconButton
                  onClick={() => {
                    setSearchVisible(true);
                  }}
                >
                  <SearchIcon />
                </IconButton>
              )}
            </div>
          </div>
          <StyledButton onClick={exportFile} text="Export" />
        </div>

        {location.pathname !== overdue && (
          <>
            <div className="table-filters">
              <div className="filter-container datepicker-container">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <p className="tab-text d-flex flex-end align-center filter-name">From: </p>
                  <KeyboardDatePicker
                    className={classes.datePicker}
                    variant="inline"
                    label=""
                    value={selectedDateFrom}
                    onChange={(date) => setSelectedDateFrom(date)}
                    animateYearScrolling
                    allowKeyboardControl
                    format="dd/MM/yyyy"
                    disableToolbar
                    autoOk
                  />
                  <p className="tab-text d-flex flex-end align-center filter-name">To: </p>
                  <KeyboardDatePicker
                    className={classes.datePicker}
                    variant="inline"
                    label=""
                    value={selectedDateTo}
                    onChange={(date) => setSelectedDateTo(date)}
                    animateYearScrolling
                    allowKeyboardControl
                    format="dd/MM/yyyy"
                    disableToolbar
                    autoOk
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
            <div className="table-filters">
              <div className="filter-container selects-container">
                <p className="tab-text d-flex flex-end align-center filter-name">Location: </p>
                <Select
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'right',
                    },
                  }}
                  classes={{ root: classes.inputFilterText, icon: classes.inputFilterIcon }}
                  open={locationOpen}
                  onClose={handleLocationOpen}
                  onOpen={handleLocationOpen}
                  value={locationFilter}
                  onChange={handleLocationChange}
                >
                  <MenuItem value="All">All</MenuItem>
                  {locationsData.map((location) => (
                    <MenuItem key={location.id} value={location.id}>
                      {location.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              {location.pathname === itemsCounted && (
                <>
                  <div className="filter-container selects-container">
                    <p className="tab-text d-flex flex-end align-center filter-name">Product: </p>
                    <Select
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'right',
                        },
                      }}
                      classes={{ root: classes.inputFilterText, icon: classes.inputFilterIcon }}
                      open={productOpen}
                      onClose={handleProductOpen}
                      onOpen={handleProductOpen}
                      value={productFilter}
                      onChange={handleProductChange}
                    >
                      <MenuItem value="All">All</MenuItem>
                      <MenuItem value={'ref'}>REF</MenuItem>
                      <MenuItem value={'gtin'}>GTIN</MenuItem>
                      <MenuItem value={'reference'}>Reference</MenuItem>
                      <MenuItem value={'company'}>Company</MenuItem>
                      <MenuItem value={'reference no.'}>Reference No.</MenuItem>
                    </Select>
                  </div>
                  <div className="filter-container selects-container">
                    <p className="tab-text d-flex flex-end align-center filter-name">Name: </p>
                    <Select
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'right',
                        },
                      }}
                      classes={{ root: classes.inputFilterText, icon: classes.inputFilterIcon }}
                      open={nameOpen}
                      onClose={handleNameOpen}
                      onOpen={handleNameOpen}
                      value={nameFilter}
                      onChange={handleNameChange}
                    >
                      <MenuItem value="All">All</MenuItem>
                      {counts.map(
                        (count) =>
                          count?.name !== null &&
                          count?.name !== undefined &&
                          count?.name !== '' && (
                            <MenuItem key={count.id} value={count.name}>
                              {count?.name}
                            </MenuItem>
                          ),
                      )}
                    </Select>
                  </div>
                </>
              )}
            </div>
          </>
        )}

        <div className="table flex-1">{renderTables(location.pathname)}</div>
      </div>
      <Alert variant={errorType} message={errorText} open={error} onClose={() => setError(false)} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    tenant: state.tenant,
    counts: state.counts.list,
    locationsData: state.locations,
    usersList: state.usersCollection,
  };
};

export default withRouter(connect(mapStateToProps, null)(ReportsManager));
