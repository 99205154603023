import {
  SUB_LOCATIONS_COLLECTION_ADD,
  SUB_LOCATIONS_COLLECTION_GET,
  SUB_LOCATIONS_COLLECTION_UPDATE,
} from './actionTypes';

import { SUB_LOCATION_DATA_COLLECTION } from '../firebase/collections';

import firebase from '../firebase/Firebase';

export const createSubLocation = (data) => async (dispatch) => {
  const res = await firebase.addToCollection(SUB_LOCATION_DATA_COLLECTION, data);
  dispatch({
    type: SUB_LOCATIONS_COLLECTION_ADD,
    subLocation: {
      id: res,
      name: data.name,
      note: data.note,
      description: data.description,
      location: data.location,
      active: true,
    },
  });
};

export const getSubLocations = (tenantId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    firebase
      .getCollection(SUB_LOCATION_DATA_COLLECTION, tenantId)
      .then((res) => {
        dispatch({ type: SUB_LOCATIONS_COLLECTION_GET, value: res });
        resolve();
      })
      .catch(reject);
  });
};

export const updateSubLocation = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    firebase
      .updateItem(SUB_LOCATION_DATA_COLLECTION, {
        id: data.id,
        name: data.name,
        location: data.location,
        note: data.note,
        description: data.description,
        active: true,
      })
      .then(() => {
        dispatch({
          type: SUB_LOCATIONS_COLLECTION_UPDATE,
          subLocation: {
            id: data.id,
            name: data.name,
            location: data.location,
            note: data.note,
            description: data.description,
            active: true,
          },
        });
        resolve();
      })
      .catch(reject);
  });
};

export const deleteSubLocation = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    firebase
      .disableItem(SUB_LOCATION_DATA_COLLECTION, data)
      .then((res) => {
        dispatch({ type: SUB_LOCATIONS_COLLECTION_UPDATE, subLocation: { ...data, active: false } });
        resolve();
      })
      .catch(reject);
  });
};
