import React, { useState } from 'react';

import { TextField, Input, MenuItem, FormControl, Select, Icon, IconButton, InputLabel } from '@material-ui/core/';
import { Autocomplete } from '@material-ui/lab';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import { sortAlphaNum } from '../../../../../util/helpers';

import remove from '../../../../../assets/icons/delete.svg';

import './schedules-modal.scss';

const ScheduleLocations = (props) => {
  const {
    subLocations,
    subLocationsData,
    locationsData,
    mainLocation,
    setMainLocation,
    addSubLocation,
    listText,
    removeSubLocation,
  } = props;

  const [subLocationName, setSubLocationName] = useState([]);
  const [locationId, setLocationId] = useState('');

  const getNames = (collection) => {
    return collection.map((item) => item.name);
  };

  const getItemByName = (collection, name) => {
    return collection.filter((item) => name.includes(item.name));
  };

  const getItemById = (collection, id) => {
    return collection.find((item) => item.id === id);
  };

  const getSubLocationsData = () => {
    const temp = subLocationsData.filter(
      (item) => item.location === locationId && item.active === true && !subLocations.includes(item.id),
    );
    return temp.map((item) => item.name).sort(sortAlphaNum);
  };

  const handleSelectChange = (event) => {
    setSubLocationName(event.target.value);
  };

  return (
    <div className="d-flex flex-1 direction-column">
      <div className="schedule-locations">
        <div className="locations h-100 d-flex direction-column">
          <div className="main-location-select">
            <Autocomplete
              options={getNames(locationsData.filter((item) => item.active === true))}
              onChange={(e, v) => {
                setMainLocation(v);
                if (locationsData.find((item) => item.name === v)) {
                  setLocationId(locationsData.find((item) => item.name === v).id);
                } else {
                  setLocationId('');
                }
              }}
              value={mainLocation}
              id="locations"
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Main Location"
                  placeholder="E.g  Warehouse"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </div>
          <div className="schedule-sub-locations m-t-lg">
            <div className="d-flex align-end w-50">
              <div className="flex-1">
                <FormControl fullWidth>
                  <InputLabel id="label-sub-locations">Sub Locations</InputLabel>
                  <Select
                    labelId="label-sub-locations"
                    id="sub-locations"
                    multiple
                    fullWidth
                    value={subLocationName}
                    onChange={handleSelectChange}
                    input={<Input />}
                  >
                    {getSubLocationsData().map((name) => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}

                    {!getSubLocationsData().length && (
                      <MenuItem value={''} disabled>
                        <em>None</em>
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
              <div className="p-l-sm">
                <IconButton
                  onClick={() => {
                    addSubLocation(getItemByName(subLocationsData, subLocationName));
                    setSubLocationName([]);
                  }}
                  aria-label=""
                  color={subLocationName ? 'primary' : 'secondary'}
                  size="small"
                >
                  <Icon color={subLocationName ? 'primary' : 'secondary'}>add_circle_outline</Icon>
                </IconButton>
              </div>
            </div>
          </div>
          <div className="sub-locations-list m-t-md">
            {subLocations.map((subLocation) => {
              return (
                <div key={subLocation} className="d-flex w-50 space-between item align-center m-t-sm">
                  <div style={listText}>{getItemById(subLocationsData, subLocation).name}</div>
                  <IconButton
                    onClick={(e) => removeSubLocation(subLocation)}
                    aria-label=""
                    color="secondary"
                    size="small"
                  >
                    <DeleteOutlineIcon style={{ color: '#d32f2f' }} />
                  </IconButton>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleLocations;
