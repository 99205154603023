import React from 'react';
import { connect } from 'react-redux';
import DashboardManager from '../../../components/app/dashboard/DashboardManager';

const Dashboard = (props) => {
  const { changeActiveCount } = props;

  return (
    <div className="page-container w-70">
      <DashboardManager changeActiveCount={changeActiveCount} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loginData: state.auth,
    userData: state.user,
    tenantData: state.tenant,
  };
};

export default connect(mapStateToProps)(Dashboard);
