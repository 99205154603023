import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  startOfDay,
  endOfDay,
  isEqual,
  format,
  isBefore,
} from 'date-fns';

import { removeLocation } from '../../../../actions/locationsCollectionActions';
import { deleteSubLocation } from '../../../../actions/subLocationsCollectionActions';
import { getCounts, deleteCount } from '../../../../actions/countsActions';
import { setDate } from '../../../../actions/dateActions';

import { displayFullName } from '../../../../util/helpers';
import { isCountCompleted, isCountInProgress, isCountScheduled } from '../../../../util/counts';

import {
  makeStyles,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import RefreshIcon from '@material-ui/icons/Refresh';

import CountsTable from './counts-table/CountsTable';
import CountsModal from './counts-modal/CountsModal';
import NewCountModal from './new-count-modal/NewCountModal';

import DateRangePicker from './date-range-picker/DateRangePicker';
import LoadScreen from '../../../load-screen/LoadScreen';
import 'date-fns';

import StyledButton from '../../../shared/Button';
import Alert from '../../../shared/Alert';

import '../../manager.scss';
import SearchIcon from '@material-ui/icons/Search';

const CountsManager = (props) => {
  const {
    schedulesData,
    usersCollection,
    getCounts,
    locationsData,
    countsData,
    teamsData,
    tenantData,
    dateState,
    setDate,
    activeCount,
    changeActiveCount,
    deleteCount,
  } = props;

  const useStyles = makeStyles({
    searchInput: {
      margin: 0,
    },
    tab: {
      height: 50,
      marginBottom: 10,
      borderBottom: '1px solid #E9E9E9',
      padding: 0,
      width: '100%',
    },
    tabItem: {
      width: 'max-content !important',
      display: 'flex',
      padding: '0 !important',
      justifyContent: 'flex-start !important',
    },
    drawer: {
      width: '100%',
    },
    selectHidden: {
      display: 'none',
    },
    inputText: {
      fontSize: 24,
      fontWeight: 'bold',
      color: tenantData.mainColor,
      textAlign: 'center',
      border: 'none',
    },
    inputFilterText: {
      fontSize: 16,
      fontWeight: 'bold',
      color: tenantData.mainColor,
      textAlign: 'left',
      border: 'none',
      width: 150,
    },
    inputFilterIcon: {
      fontWeight: 'bold',
      color: tenantData.mainColor,
      border: 'none',
    },
    tabPaper: {
      display: 'flex',
    },
    refreshContainer: {
      height: 50,
      width: '10%',
      boxShadow: 'none',
      borderBottom: '1px solid #E9E9E9',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
  });

  const classes = useStyles();

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  // const [date, setDate] = useState(new Date());

  const [detailCount, setDetailCount] = useState({});
  const [nameFilter, setNameFilter] = useState('All');
  const [locationFilter, setLocationFilter] = useState('All');
  const [isTableRefreshLoading, setIsTableRefreshLoading] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [nameOpen, setNameOpen] = React.useState(false);
  const [locationOpen, setLocationOpen] = React.useState(false);

  const [searchValue, setSearchValue] = useState('');
  const [searchVisible, setSearchVisible] = useState(false);

  const [error, setError] = React.useState(false);
  const [errorType, setErrorType] = React.useState('success');
  const [errorText, setErrorText] = React.useState('');

  useEffect(() => {
    if (activeCount) {
      setDetailCount(activeCount);
      setDrawerIsOpen(true);
      changeActiveCount(null);
    }
  }, []);

  const handleModalOpen = () => {
    setModalIsOpen(true);
  };

  const handleModalClose = () => {
    setModalIsOpen(false);
  };

  const handleNameOpen = () => {
    setNameOpen(true);
  };

  const handleNameClose = () => {
    setNameOpen(false);
  };

  const handleNameChange = (e) => {
    setNameFilter(e.target.value);
  };

  const handleLocationOpen = () => {
    setLocationOpen(true);
  };

  const handleLocationClose = () => {
    setLocationOpen(false);
  };

  const handleLocationChange = (e) => {
    setLocationFilter(e.target.value);
  };

  const openDrawer = () => {
    setDrawerIsOpen(true);
  };

  const closeDrawer = () => {
    setDrawerIsOpen(false);
  };

  const handleChange = (event) => {
    const step = event.target.value;

    if (step === 'Week') {
      setDate({ ...dateState, step, startDate: startOfWeek(dateState.date), endDate: endOfWeek(dateState.date) });
    } else if (step === 'Month') {
      setDate({ ...dateState, step, startDate: startOfMonth(dateState.date), endDate: endOfMonth(dateState.date) });
    } else if (step === 'Year') {
      setDate({ ...dateState, step, startDate: startOfYear(dateState.date), endDate: endOfYear(dateState.date) });
    } else if (step === 'All') {
      setDate({ ...dateState, step, startDate: null, endDate: null });
    } else if (step === 'Day') {
      setDate({ ...dateState, step, startDate: startOfDay(dateState.date), endDate: endOfDay(dateState.date) });
    }
  };

  const filterRows = (data) => {
    return data
      .filter((count) => {
        if (tabValue === 0) {
          return isCountCompleted(count);
        } else if (tabValue === 1) {
          return isCountInProgress(count);
        } else if (tabValue === 2) {
          return isCountScheduled(count);
        } else if (tabValue === 3) {
          return count.archived;
        } else {
          return true;
        }
      })
      .filter((count) => {
        const countDate = new Date(count.date);
        return dateState.startDate && dateState.endDate
          ? countDate >= dateState.startDate && countDate <= dateState.endDate
          : count;
      })
      .filter((item) => {
        return (
          (nameFilter === 'All' || item.users.includes(nameFilter)) &&
          (locationFilter === 'All' || item.location.id === locationFilter)
        );
      })
      .filter((count) => count.id.toLowerCase().includes(searchValue.toLowerCase()));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const tabValueHandleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleDateRangeChange = (date) => {
    if (dateState.step === 'Week') {
      setDate({ ...dateState, date, startDate: startOfWeek(date), endDate: endOfWeek(date) });
    } else if (dateState.step === 'Month') {
      setDate({ ...dateState, date, startDate: startOfMonth(date), endDate: endOfMonth(date) });
    } else if (dateState.step === 'Year') {
      setDate({ ...dateState, date, startDate: startOfYear(date), endDate: endOfYear(date) });
    } else if (dateState.step === 'All') {
      setDate({ ...dateState, date, startDate: null, endDate: null });
    } else if (dateState.step === 'Day') {
      setDate({ ...dateState, date, startDate: startOfDay(date), endDate: endOfDay(date) });
    }
  };

  const onCountsTableRefresh = () => {
    setIsTableRefreshLoading(true);
    getCounts(tenantData.id).then(() => {
      setIsTableRefreshLoading(false);
    });
  };

  const removeScheduledCount = async (row) => {
    try {
      deleteCount(row.id);
      setErrorText(`${row.location.name}/${row.id} was successfully removed`);
      setError(true);
    } catch {
      setErrorText('Something went wrong');
      setErrorType('error');
      setError(true);
    }
  };

  return (
    <Fragment>
      <div className="manager-container">
        <div className="manager-head">
          <div className="page-title-container w-30 flex-start flex-1">
            <h3 className="page-title">Counts</h3>
            <div className="date-step-pick">
              <Select
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                  },
                }}
                classes={{ root: classes.inputText, icon: classes.inputText }}
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                fullWidth={true}
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                value={dateState.step}
                onChange={handleChange}
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="Day">Day</MenuItem>
                <MenuItem value="Week">Week</MenuItem>
                <MenuItem value="Month">Month</MenuItem>
                <MenuItem value="Year">Year</MenuItem>
              </Select>
            </div>
          </div>
          <DateRangePicker step={dateState.step} date={dateState.date} onDateChange={handleDateRangeChange} />
          <div className="d-flex flex-end flex-1">
            <StyledButton onClick={handleModalOpen} text="New Count" />
          </div>
        </div>
        <div className="table-switch">
          <Paper className={classes.tabPaper} elevation={0}>
            <Tabs
              value={tabValue}
              indicatorColor="primary"
              textColor="primary"
              className={classes.tab}
              onChange={tabValueHandleChange}
            >
              <Tab className={classes.tabItem} classes={{ wrapper: classes.tabItem }} label="Completed" />
              <Tab className={classes.tabItem} classes={{ wrapper: classes.tabItem }} label="In Progress" />
              <Tab className={classes.tabItem} classes={{ wrapper: classes.tabItem }} label="Scheduled" />
              <Tab className={classes.tabItem} classes={{ wrapper: classes.tabItem }} label="Archived" />
            </Tabs>
            <div className="d-flex align-center">
              <div className="search-container-sub">
                <div className="search-input-sub d-flex align-start flex-center">
                  {searchVisible ? (
                    <div className="search-input-container-sub">
                      <FormControl size="small" variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Search...</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          value={searchValue}
                          type="text"
                          placeholder="Count ID"
                          onChange={(e) => {
                            setSearchValue(e.target.value);
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <div
                                className="h-100 d-flex align-center cursor-pointer"
                                onClick={() => {
                                  setSearchValue('');
                                  setSearchVisible(false);
                                }}
                              >
                                <SearchIcon />
                              </div>
                            </InputAdornment>
                          }
                          labelWidth={70}
                        />
                      </FormControl>
                    </div>
                  ) : (
                    <IconButton
                      onClick={() => {
                        setSearchVisible(true);
                      }}
                    >
                      <SearchIcon />
                    </IconButton>
                  )}
                </div>
              </div>

              <RefreshIcon
                className="m-l-md"
                cursor="pointer"
                onClick={onCountsTableRefresh}
                color={isTableRefreshLoading ? 'secondary' : 'primary'}
              />
            </div>
          </Paper>
        </div>
        <div className={`table-filters ${tabValue === 1 ? 'd-none' : ''}`}>
          {/*<div className="filter-container">*/}
          {/*    <p className="tab-text m-0 d-flex flex-end align-center filter-name">User: </p>*/}
          {/*    <Select*/}
          {/*        MenuProps={{*/}
          {/*            getContentAnchorEl: null,*/}
          {/*            anchorOrigin: {*/}
          {/*                vertical: "bottom",*/}
          {/*                horizontal: "right",*/}
          {/*            }*/}
          {/*        }}*/}
          {/*        classes={{root: classes.inputFilterText, icon: classes.inputFilterIcon}}*/}
          {/*        labelId="demo-controlled-open-select-label"*/}
          {/*        id="demo-controlled-open-select"*/}
          {/*        open={nameOpen}*/}
          {/*        onClose={handleNameClose}*/}
          {/*        onOpen={handleNameOpen}*/}
          {/*        value={nameFilter}*/}
          {/*        onChange={handleNameChange}*/}
          {/*    >*/}
          {/*        <MenuItem value="All">All</MenuItem>*/}
          {/*        {*/}
          {/*            usersCollection.map(user => (*/}
          {/*                <MenuItem value={user.id}>*/}
          {/*                    {displayFullName(user.fullName)}*/}
          {/*                </MenuItem>*/}
          {/*            ))*/}
          {/*        }*/}
          {/*    </Select>*/}
          {/*</div>*/}
          <div className="filter-container">
            <p className="tab-text d-flex flex-end align-center filter-name">Location: </p>
            <Select
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
              }}
              classes={{ root: classes.inputFilterText, icon: classes.inputFilterIcon }}
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              open={locationOpen}
              onClose={handleLocationClose}
              onOpen={handleLocationOpen}
              value={locationFilter}
              onChange={handleLocationChange}
            >
              <MenuItem value="All">All</MenuItem>
              {locationsData.map((location) => (
                <MenuItem key={location.id} value={location.id}>
                  {location.name}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
        <div className="table flex-1">
          <CountsTable
            rows={filterRows(countsData)}
            openDrawer={openDrawer}
            setDetailCount={setDetailCount}
            locationsData={locationsData}
            schedules={schedulesData}
            tabValue={tabValue}
            removeScheduledCount={removeScheduledCount}
          />
        </div>
      </div>
      <CountsModal
        drawerIsOpen={drawerIsOpen}
        closeDrawer={closeDrawer}
        detailCount={detailCount}
        schedules={schedulesData}
        locationsData={locationsData}
        usersCollection={usersCollection}
        teamsData={teamsData}
      />
      <NewCountModal open={modalIsOpen} handleOpen={handleModalOpen} handleClose={handleModalClose} />
      {isTableRefreshLoading ? <LoadScreen /> : null}
      <Alert variant={errorType} message={errorText} open={error} onClose={() => setError(false)} />
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      removeLocation,
      deleteSubLocation,
      getCounts,
      setDate,
      deleteCount,
    },
    dispatch,
  );

const mapStateToProps = (state) => {
  return {
    subLocationsData: state.subLocations,
    usersCollection: state.usersCollection,
    teamsData: state.teams,
    userData: state.user,
    locationsData: state.locations,
    schedulesData: state.schedules,
    countsData: state.counts.list,
    tenantData: state.tenant,
    dateState: state.date,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CountsManager);
