import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';
import { getSingleCount, updateCount } from '../../../../actions/countsActions';
import CountTable from './count-table/CountTable';
import LoadScreen from '../../../load-screen';
import 'date-fns';
import StyledButton from '../../../shared/Button';
import '../../manager.scss';
import './count-manager.scss';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  makeStyles,
  OutlinedInput,
  TextField,
} from '@material-ui/core';
import BackIcon from '@material-ui/icons/KeyboardBackspace';
import RefreshIcon from '@material-ui/icons/Refresh';
import { displayFullName, downloadURI } from '../../../../util/helpers';
import urls from '../../../../constants/urls';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SearchIcon from '@material-ui/icons/Search';
import Alert from '../../../shared/Alert';

const CountManager = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const _id = id.replace(':', '');

  const { history, usersList, getSingleCount } = props;

  const [count, setCount] = useState(null);
  const [tab, setTab] = useState(0);
  const [tabValue, setTabValue] = useState(0);
  const [notes, setNotes] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [searchVisible, setSearchVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  const useStyles = makeStyles({
    searchStyle: {
      height: 35,
    },
    searchInput: {
      margin: 0,
      height: 30,
    },
    tab: {
      height: 50,
      marginBottom: 10,
      borderBottom: '1px solid #E9E9E9',
      padding: 0,
      flex: 1,
    },
    tabItem: {
      width: 'max-content !important',
      display: 'flex',
      padding: '0 !important',
      justifyContent: 'flex-start !important',
    },
  });

  const isCompleted = useCallback(() => {
    if (!count.scans || !Object.keys(count.scans).length) {
      return false;
    }
    return Object.keys(count.scans).every((key) => count.scans[key].submitted);
  }, [id, count]);

  const classes = useStyles();

  useEffect(() => {
    getSingleCount(_id)
      .then((res) => setCount(res))
      .catch((e) => console.log(e));
  }, [id]);

  useEffect(() => {
    setNotes(count && count.adminNotes ? count.adminNotes : '');
  }, [count]);

  const onRefresh = () => {
    setCount(null);
    getSingleCount(_id)
      .then((res) => setCount(res))
      .catch((e) => console.log(e));
  };

  const getTableRows = useCallback(() => {
    if (!count || !count.scans) {
      return [];
    }

    const { subLocations, scans } = count;
    const entries = Object.entries(scans);
    const resultArray = [];

    entries.forEach((scan) => {
      let subLocation = subLocations.find((_subLocation) => _subLocation.id === scan[0]);

      const _scan = scan[1];

      const counter = displayFullName(usersList.find((_user) => _user.id === _scan.userId)?.fullName);
      const products = _scan.products;

      products.forEach((product) =>
        resultArray.push({
          subLocation: subLocation?.name || count.location.name,
          ref: product.ref,
          lot: product.lot,
          qty: product.quantity,
          date: count.date,
          counter,
          ...product,
        }),
      );
    });

    return resultArray
      .filter((item) => item.subLocation.toLowerCase().includes(searchValue.toLowerCase()))
      .filter((item) => filterByTab(item, tab));
  }, [count, tab, searchValue]);

  const getCountNotes = useCallback(() => {
    if (!count || !count.scans) {
      return '';
    }

    const { subLocations, scans } = count;
    const entries = Object.entries(scans);
    let result = '';

    entries.forEach((scan) => {
      const subLocation = subLocations.find((_subLocation) => _subLocation.id === scan[0]);
      const noteTitle = subLocation?.name || count.location.name;
      const { notes } = scan[1];

      if (noteTitle && notes) {
        result = `${result}\n${noteTitle}:  ${notes}`.trimStart();
      }
    });

    return result;
  }, [count]);

  const filterByTab = (item, tab) => {
    if (tab === 0) {
      return item.type === 'IMPLANTS' || !item.type;
    }

    return item.type === 'INSTRUMENTS';
  };

  const tabValueHandleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleNotesChange = (event) => {
    setNotes(event.target.value);
  };

  const exportFile = () => {
    downloadURI(`${urls.exportFile}?countId=${count.id}`);
  };

  return count ? (
    <Fragment>
      <div className="manager-container">
        <div className="d-flex p-b-md">
          <div className="flex-1">
            <div className="id-location-name m-b-md d-flex align-center">
              <IconButton
                onClick={() => history.push('/counts')}
                size="small"
                color="primary"
                style={{ marginRight: '8px', marginLeft: '-6px' }}
              >
                <BackIcon />
              </IconButton>
              <div className="d-flex" style={{ marginRight: '100px' }}>
                <div className="label-text m-r-md">Count ID</div>
                <div className="table-black-text">{count.id}</div>
              </div>
              <div className="d-flex">
                <div className="label-text m-r-md">Location</div>
                <div className="table-black-text">{count.location.name}</div>
              </div>
            </div>
            <div className="notes">
              <Tabs
                value={tabValue}
                indicatorColor="primary"
                textColor="primary"
                className={classes.tab}
                onChange={tabValueHandleChange}
              >
                <Tab className={classes.tabItem} classes={{ wrapper: classes.tabItem }} label="Count Notes" />
                <Tab className={classes.tabItem} classes={{ wrapper: classes.tabItem }} label="Admin Notes" />
              </Tabs>
              <div className="m-t-lg">
                {tabValue === 0 ? (
                  <TextField
                    fullWidth
                    multiline
                    placeholder="Notes"
                    rows={4}
                    value={getCountNotes()}
                    variant="outlined"
                    disabled
                  />
                ) : (
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    placeholder="Notes"
                    value={notes}
                    variant="outlined"
                    onChange={handleNotesChange}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="d-flex direction-column space-between">
            <div>
              <StyledButton text="Export" onClick={exportFile} />
              <div className="m-t-md">
                {isCompleted() && (
                  <StyledButton
                    disabled={!!count.archived}
                    text="Archive"
                    onClick={() => {
                      dispatch(updateCount(count.id, { archived: true })).then(() => {
                        setCount({
                          ...count,
                          archived: true,
                        });
                        setSuccessMessage('Count has been successfully archived');
                      });
                    }}
                  />
                )}
              </div>
            </div>
            {tabValue === 1 && (
              <div>
                <StyledButton
                  text="Save"
                  onClick={() => {
                    dispatch(updateCount(count.id, { adminNotes: notes })).then(() =>
                      setSuccessMessage('Admin notes have been successfully saved'),
                    );
                  }}
                />
              </div>
            )}
          </div>
        </div>

        <div className="table flex-1 d-flex direction-column">
          <div className="d-flex">
            <Tabs
              value={tab}
              indicatorColor="primary"
              textColor="primary"
              className={classes.tab}
              onChange={(_, value) => setTab(value)}
            >
              <Tab className={classes.tabItem} classes={{ wrapper: classes.tabItem }} label="Implants" />
              <Tab className={classes.tabItem} classes={{ wrapper: classes.tabItem }} label="Instruments" />
            </Tabs>
            <div className="d-flex align-center">
              <div className="search-container-sub">
                <div className="search-input-sub d-flex align-start flex-center">
                  {searchVisible ? (
                    <div
                      className={
                        props.location.pathname === '/locations'
                          ? 'search-input-container'
                          : 'search-input-container-sub'
                      }
                    >
                      <FormControl size="small" variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Search...</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          value={searchValue}
                          type="text"
                          placeholder="Sub-Location"
                          onChange={(e) => {
                            setSearchValue(e.target.value);
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <div
                                className="h-100 d-flex align-center cursor-pointer"
                                onClick={() => {
                                  setSearchValue('');
                                  setSearchVisible(false);
                                }}
                              >
                                <SearchIcon />
                              </div>
                            </InputAdornment>
                          }
                          labelWidth={70}
                        />
                      </FormControl>
                    </div>
                  ) : (
                    <IconButton
                      onClick={() => {
                        setSearchVisible(true);
                      }}
                    >
                      <SearchIcon />
                    </IconButton>
                  )}
                </div>
              </div>

              <div className="cursor-pointer m-l-md m-t-sm" onClick={onRefresh}>
                <RefreshIcon color="primary" />
              </div>
            </div>
          </div>
          <CountTable hideCheckbox rows={getTableRows()} />
        </div>
      </div>
      <Alert variant="success" message={successMessage} open={successMessage} onClose={() => setSuccessMessage(null)} />
    </Fragment>
  ) : (
    <LoadScreen />
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSingleCount,
    },
    dispatch,
  );

const mapStateToProps = (state) => {
  return {
    usersList: state.usersCollection,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CountManager));
