const headers = {
  'Content-Type': 'application/json',
};

export const get = (url) => fetch(url).then((res) => res.json());

export const post = (url, data) => {
  return fetch(url, { method: 'POST', headers, body: JSON.stringify(data) }).then((res) => res.json());
};

export const remove = (url, id) =>
  fetch(url, { method: 'DELETE', body: JSON.stringify({ id }) }).then((res) => res.json());

export const put = (url, data) => {
  return fetch(url, { method: 'PUT', headers, body: JSON.stringify(data) }).then((res) => res.json());
};
