import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import LastCountsTable from './last-counts-table/LastCountsTable';
import CountsMonthChart from './counts-month-chart/CountsMonthChart';
import UsersChart from './users-chart/UsersChart';
import { isCountCompleted } from '../../../util/counts';

import '../manager.scss';
import './dashboard-manager.scss';

const DashboardManager = (props) => {
  const { countsData, locationsData, schedulesData, tenantData, changeActiveCount } = props;

  const getLastCounts = () => {
    const counts = countsData.filter(isCountCompleted);

    if (counts.length > 3) {
      counts.sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
      });

      return [counts[0], counts[1], counts[2]];
    } else {
      return counts;
    }
  };

  const nFormatter = (num) => {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(2).replace(/\.0$/, '') + 'M';
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(2).replace(/\.0$/, '') + 'K';
    }
    return num;
  };

  const getAllItemsQuantity = () => {
    const allProducts = [];

    countsData.forEach((count) => {
      if (count.scans) {
        for (let key in count.scans) {
          if (count.scans[key].products) {
            count.scans[key].products.forEach((product) => {
              allProducts.push(product);
            });
          }
        }
      }
    });

    return allProducts.reduce((total, product) => {
      return total + product.quantity;
    }, 0);
  };

  const getUsersChartData = () => {
    const { usersCollection } = props;
    const value = usersCollection.length;

    // const unSubmittedScans = [];
    // const submittedScans = [];
    //
    // countsData.forEach(count => {
    //   if(count.scans) {
    //     for(let key in count.scans) {
    //       if(count.scans[key].submitted === true) {
    //         submittedScans.push(count.scans[key])
    //       }
    //       else {
    //         unSubmittedScans.push(count.scans[key])
    //       }
    //     }
    //   }
    // });

    return [
      { name: 'User Remaining', value: 10 - value },
      { name: 'User Allowance', value },
    ];
  };

  const getSubmittedPercents = () => {
    const allScans = getUsersChartData();

    const allScansLength = allScans[0].value + allScans[1].value;

    return (allScans[1].value * 100) / allScansLength;
  };

  const getCountsPerMonth = (month, year) => {
    return countsData.filter((count) => {
      const date = count.date;
      return date.getFullYear() === year && date.getMonth() === month;
    });
  };

  const generateCountPerMonthData = () => {
    const date = new Date();
    const currentYear = date.getFullYear();

    return [
      { name: 'J', fullName: 'January', counts: getCountsPerMonth(0, currentYear).length },
      { name: 'F', fullName: 'February', counts: getCountsPerMonth(1, currentYear).length },
      { name: 'M', fullName: 'March', counts: getCountsPerMonth(2, currentYear).length },
      { name: 'A', fullName: 'April', counts: getCountsPerMonth(3, currentYear).length },
      { name: 'M', fullName: 'May', counts: getCountsPerMonth(4, currentYear).length },
      { name: 'J', fullName: 'June', counts: getCountsPerMonth(5, currentYear).length },
      { name: 'J', fullName: 'July', counts: getCountsPerMonth(6, currentYear).length },
      { name: 'A', fullName: 'August', counts: getCountsPerMonth(7, currentYear).length },
      { name: 'S', fullName: 'September', counts: getCountsPerMonth(8, currentYear).length },
      { name: 'O', fullName: 'October', counts: getCountsPerMonth(9, currentYear).length },
      { name: 'N', fullName: 'November', counts: getCountsPerMonth(10, currentYear).length },
      { name: 'D', fullName: 'December', counts: getCountsPerMonth(11, currentYear).length },
    ];
  };

  return (
    <Fragment>
      <div className="manager-container">
        <div className="manager-head">
          <div className="page-title-container w-30">
            <h3 className="page-title">Dashboard</h3>
          </div>
        </div>
        <div className="dashboard-charts">
          <div className="users-chart">
            <div className="text-grey-simple">Users</div>
            <div className="m-t-md">
              <div className="text-grey-little">Your users</div>
              <div className="text-grey-simple">{`${Math.round(getSubmittedPercents())}% of User Allowance`}</div>
            </div>
            <div className="users-chart-container">
              <UsersChart mainColor={tenantData.mainColor} data={getUsersChartData()} />
            </div>
          </div>
          <div className="counts-chart">
            <div className="text-grey-simple">Counts</div>
            <div className="m-t-md">
              <div className="text-grey-little">Total Counts</div>
              <div className="text-grey-simple">{`Monthly Counts ${new Date().getFullYear()}`}</div>
            </div>
            <div className="bar-chart-container m-t-lg">
              <CountsMonthChart mainColor={tenantData.mainColor} data={generateCountPerMonthData()} />
            </div>
          </div>
          <div className="items-statistic">
            <div className="text-grey-simple d-flex direction-column">Items</div>
            <div className="m-t-md">
              <div className="text-grey-little">Total Items</div>
              <div className="text-grey-simple">All Items counted to date</div>
            </div>
            <div className="flex-1 d-flex flex-center direction-column h-50">
              <div
                style={{
                  fontWeight: 'bold',
                  width: 'max-content',
                  fontSize: 70,
                  color: tenantData.mainColor,
                }}
              >
                {nFormatter(getAllItemsQuantity())}
                <div className="text-grey-big d-flex w-100 flex-center">Total Items</div>
              </div>
            </div>
          </div>
        </div>
        <div className="last-counts-table">
          <div className="text-grey-simple m-t-lg m-b-md">Latest Counts</div>
          <LastCountsTable
            changeActiveCount={changeActiveCount}
            rows={getLastCounts()}
            locationsData={locationsData}
            schedules={schedulesData}
          />
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    subLocationsData: state.subLocations,
    usersCollection: state.usersCollection,
    teamsData: state.teams,
    userData: state.user,
    locationsData: state.locations,
    schedulesData: state.schedules,
    countsData: state.counts.list,
    tenantData: state.tenant,
  };
};

export default connect(mapStateToProps)(DashboardManager);
