import * as React from 'react';
import { connect } from 'react-redux';

const LoadIcon = (props) => {
  const { fill } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 171 187" fill={fill}>
      <path
        className="prefix__st0"
        d="M166.194 77.977c8.576 14.845 3.46 33.852-11.384 42.428L47.236 182.491a31.249 31.249 0 01-15.547 4.163c-17.152 0-31.043-13.892-31.043-31.043V31.437A31.241 31.241 0 014.809 15.89C13.385 1.045 32.392-4.07 47.236 4.506L154.81 66.643c4.714 2.708 8.676 6.62 11.384 11.334zm-21.715 24.524c4.965-2.859 6.62-9.178 3.811-14.143a10.163 10.163 0 00-3.811-3.761L36.905 22.46a10.347 10.347 0 00-14.143 3.811 10.369 10.369 0 00-1.404 5.166V155.61a10.317 10.317 0 0010.331 10.331c1.805 0 3.611-.451 5.166-1.404l107.624-62.036z"
      />
    </svg>
  );
};

const mapStateToProps = (state) => {
  return {
    fill: state.tenant.mainColor,
  };
};

export default connect(mapStateToProps)(LoadIcon);
