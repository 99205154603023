import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';

import './schedules-modal.scss';
import { Autocomplete } from '@material-ui/lab';
import Icon from '@material-ui/core/Icon';
import remove from '../../../../../assets/icons/delete.svg';
import { connect } from 'react-redux';
import { displayFullName } from '../../../../../util/helpers';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

const ScheduleTeams = (props) => {
  const { users, teams, usersData, teamsData, addTeam, addUser, removeTeam, removeUser, listText } = props;

  const [userName, setUserName] = useState('');
  const [teamName, setTeamName] = useState('');

  const getNames = (collection) => {
    return collection.map((item) => item.name);
  };

  const getFullNames = (collection) => {
    return collection.map((item) => displayFullName(item.fullName));
  };

  const getItemByName = (collection, name) => {
    return collection.find((item) => item.name === name);
  };

  const getItemByFullName = (collection, fullName) => {
    return collection.find((item) => displayFullName(item.fullName) === displayFullName(fullName));
  };

  const getItemById = (collection, id) => {
    return collection.find((item) => item.id === id);
  };

  return (
    <div className="d-flex flex-1 direction-column">
      <div className="assign-container">
        <div className="locations h-100 d-flex direction-column">
          <div className="d-flex align-end">
            <div className="flex-1">
              <Autocomplete
                options={getFullNames(usersData.filter((item) => item.active === true))}
                onChange={(e, v) => {
                  setUserName(v);
                }}
                value={userName}
                id="teams"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Users"
                    placeholder="E.g John Doe"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </div>
            <div className="p-l-sm">
              <IconButton
                onClick={(e) => {
                  if (userName) {
                    addUser(getItemByFullName(usersData, userName));
                    setUserName('');
                  }
                  e.preventDefault();
                }}
                aria-label=""
                color={userName ? 'primary' : 'secondary'}
                size="small"
              >
                <Icon color={userName ? 'primary' : 'secondary'}>add_circle_outline</Icon>
              </IconButton>
            </div>
          </div>
          <div className="input-hint">You can choose more than one user</div>
          <div className="teams-list">
            {users &&
              users.map((user) => {
                return (
                  <div key={user} className="team-list-item">
                    <p style={listText}>{displayFullName(getItemById(usersData, user).fullName)}</p>
                    <IconButton
                      onClick={(e) => {
                        removeUser(user);
                      }}
                      aria-label=""
                      color="secondary"
                      size="small"
                    >
                      <DeleteOutlineIcon style={{ color: '#d32f2f' }} />
                    </IconButton>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="locations h-100 d-flex direction-column">
          <div className="d-flex align-end">
            <div className="flex-1">
              <Autocomplete
                options={getNames(teamsData.filter((item) => item.active === true))}
                onChange={(e, v) => {
                  setTeamName(v);
                }}
                value={teamName}
                id="teams"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Teams"
                    placeholder="E.g Team"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </div>
            <div className="p-l-sm">
              <IconButton
                onClick={(e) => {
                  if (teamName) {
                    addTeam(getItemByName(teamsData, teamName));
                    setTeamName('');
                  }
                  e.preventDefault();
                }}
                aria-label=""
                color={teamName ? 'primary' : 'secondary'}
                size="small"
              >
                <Icon color={teamName ? 'primary' : 'secondary'}>add_circle_outline</Icon>
              </IconButton>
            </div>
          </div>
          <div className="input-hint">You can choose more than one team</div>
          <div className="teams-list">
            {teams &&
              teams.map((team) => {
                return (
                  <div key={team} className="team-list-item">
                    <p style={listText}>{getItemById(teamsData, team).name}</p>
                    <IconButton
                      onClick={(e) => {
                        removeTeam(team);
                      }}
                      aria-label=""
                      color="secondary"
                      size="small"
                    >
                      <DeleteOutlineIcon style={{ color: '#d32f2f' }} />
                    </IconButton>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    tenantData: state.tenant,
  };
};

export default connect(mapStateToProps)(ScheduleTeams);
