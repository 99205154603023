import { apiUrl } from '../config';

const urls = {
  signUp: `${apiUrl}/auth/sign-up`,
  signIn: `${apiUrl}/auth/sign-in`,
  usersCollection: `${apiUrl}/auth/get-users`,
  userCreate: `${apiUrl}/manage/create-user`,
  userUpdateDB: `${apiUrl}/manage/update-user-db`,
  userUpdateAuth: `${apiUrl}/manage/update-user-auth`,
  userDelete: `${apiUrl}/manage/delete-user`,
  teamCreate: `${apiUrl}/manage/create-team`,
  teamUpdate: `${apiUrl}/manage/update-team`,
  teamDelete: `${apiUrl}/manage/delete-team`,
  teamsCollection: `${apiUrl}/manage/get-teams`,
  locationCreate: `${apiUrl}/manage/create-location`,
  locationsCollection: `${apiUrl}/manage/get-locations`,
  locationDelete: `${apiUrl}/manage/delete-location`,
  locationUpdate: `${apiUrl}/manage/update-location`,
  subLocationCreate: `${apiUrl}/manage/create-sub-location`,
  subLocationDelete: `${apiUrl}/manage/delete-sub-location`,
  subLocationUpdate: `${apiUrl}/manage/update-sub-location`,
  subLocationsCollection: `${apiUrl}/manage/get-sub-locations`,
  scheduleCreate: `${apiUrl}/manage/create-schedule`,
  scheduleDelete: `${apiUrl}/manage/delete-schedule`,
  scheduleUpdate: `${apiUrl}/manage/update-schedule`,
  schedulesCollection: `${apiUrl}/manage/get-schedules`,
  countCreate: `${apiUrl}/manage/create-count`,
  countsCollection: `${apiUrl}/manage/get-counts`,
  tenantUpdate: `${apiUrl}/manage/update-tenant`,
  exportFile: `${apiUrl}/manage/export`,
  exportCountedItems: `${apiUrl}/manage/export-counted-items`,
  exportCompletedCounts: `${apiUrl}/manage/export-completed-counts`,
  exportOverdueCounts: `${apiUrl}/manage/export-overdue-counts`,
};

export default urls;
