import React from 'react';

import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';

const headCells = [
  { id: 'location', numeric: false, disablePadding: true, label: 'Location' },
  { id: 'id', numeric: false, disablePadding: false, label: 'Count ID' },
  { id: 'date', numeric: false, disablePadding: false, label: 'Count Date' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'counter', numeric: false, disablePadding: false, label: 'Counter Name' },
  { id: 'total', numeric: false, disablePadding: false, label: 'Total Items' },
];

export const CompletedTableHead = (props) => {
  const { classes, order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
