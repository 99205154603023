import React from 'react';
import { connect } from 'react-redux';

const Frequency = (props) => {
  const { tenant, text } = props;

  return (
    <div
      style={{
        width: 'max-content',
        minWidth: 100,
        height: 20,
        border: `1px solid ${tenant.mainColor}`,
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
      }}
    >
      <p
        style={{
          color: tenant.mainColor,
          fontSize: 14,
          padding: 0,
          margin: 0,
        }}
      >
        {text}
      </p>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    tenant: state.tenant,
  };
};

export default connect(mapStateToProps)(Frequency);
