import React from 'react';
import { IconButton } from '@material-ui/core';
import { Edit, Delete } from '@material-ui/icons';

const FieldView = ({ title, section, onEdit, deleteField, index, viewOnly }) => {
  const onDelete = () => {
    deleteField(index);
  };
  return (
    <div className="w-100 d-flex m-t-sm">
      <div className="flex-1 table-black-text">{title}</div>
      <div className="flex-1 table-black-text">{section}</div>
      {viewOnly ? null : (
        <div className="flex-1 d-flex">
          <>
            <IconButton onClick={onEdit}>
              <Edit />
            </IconButton>
            <IconButton onClick={onDelete}>
              <Delete htmlColor="red" />
            </IconButton>
          </>
        </div>
      )}
    </div>
  );
};

export default FieldView;
