import {
  SUB_LOCATIONS_COLLECTION_ADD,
  SUB_LOCATIONS_COLLECTION_CLEAR,
  SUB_LOCATIONS_COLLECTION_DELETE,
  SUB_LOCATIONS_COLLECTION_GET,
  SUB_LOCATIONS_COLLECTION_UPDATE,
} from '../actions/actionTypes';

const initialState = [];

const subLocationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUB_LOCATIONS_COLLECTION_ADD:
      return [...state, action.subLocation];
    case SUB_LOCATIONS_COLLECTION_UPDATE:
      return state.map((item) => {
        if (item.id === action.subLocation.id) {
          return action.subLocation;
        }
        return item;
      });
    case SUB_LOCATIONS_COLLECTION_DELETE:
      return state.filter((item) => item.id !== action.value);
    case SUB_LOCATIONS_COLLECTION_GET:
      return action.value;
    case SUB_LOCATIONS_COLLECTION_CLEAR:
      return initialState;
    default:
      return state;
  }
};

export default subLocationsReducer;
