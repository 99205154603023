import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import Icon from '@material-ui/core/Icon';

import check from '../../../assets/icons/check_circle.svg';
import logo from '../../../assets/icons/opologic_mono_logo.svg';

import './business-aside.scss';

const BusinessAside = (props) => {
  const { loginData } = props;

  return (
    <div className="login-aside aside-container">
      <div className="container align-center business-aside-container">
        <div className="info-list d-flex direction-column flex-start">
          <div className="d-flex w-100">
            <h1 className="scan-text">Get started</h1>
          </div>
          <div className="opologic-information-list">
            <div className="user-info-list-item owner-info d-flex align-center">
              <div className="check-circle-icon-container">
                <img src={check} style={{ width: 24, height: 24 }} alt="check" />
              </div>
              <p className="owner-info-text">Owner info</p>
            </div>
            <div className="user-info-list-item full-name d-flex direction-column flex-center">
              <div className="full-name-text-info">
                <p className="full-name-label">Full name</p>
                <p className="full-user-name">{loginData.fullName}</p>
              </div>
            </div>
            <div className="user-info-list-item full-name d-flex direction-column flex-center">
              <div className="full-name-text-info">
                <p className="full-name-label">Your email</p>
                <p className="full-user-name">{loginData.email}</p>
              </div>
            </div>
            <div className="user-info-list-item owner-info d-flex align-center">
              <div className="check-circle-icon-container d-flex align-center">
                <Icon style={{ color: '#f9f9f9' }}>radio_button_unchecked</Icon>
              </div>
              <p className="owner-info-text">Business info</p>
            </div>
            <div className="line" />
          </div>
        </div>
        <div className="opologic-logo direction-column w-100 d-flex flex-end">
          <h4 className="powered-by-text">Powered by</h4>
          <img src={logo} alt="opologic_mono_logo" className="opologic-mono-logo" />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loginData: state.auth,
  };
};

BusinessAside.propTypes = {
  loginData: PropTypes.object,
};

export default connect(mapStateToProps)(BusinessAside);
