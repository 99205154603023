export const getCountProductsTotal = (count) => {
  const products = [];
  if (count.scans) {
    for (let key in count.scans) {
      count.scans[key].products.forEach((product) => {
        products.push(product);
      });
    }
    return products.reduce((total, item) => {
      return total + item.quantity;
    }, 0);
  } else {
    return 0;
  }
};
