import React, { useState } from 'react';
import { connect } from 'react-redux';

const AddText = (props) => {
  const { tenant, text } = props;

  const [isHovered, setIsHovered] = useState(false);

  const simpleStyle = {
    color: '#5B667D',
    fontWeight: 'bold',
    fontSize: 16,
    cursor: 'pointer',
    margin: 0,
  };

  const hoveredStyle = {
    color: tenant.mainColor,
    fontWeight: 'bold',
    fontSize: 16,
    cursor: 'pointer',
    margin: 0,
  };

  return (
    <p
      onMouseOver={(e) => {
        setIsHovered(true);
      }}
      onMouseOut={(e) => {
        setIsHovered(false);
      }}
      style={isHovered ? hoveredStyle : simpleStyle}
    >
      {text}
    </p>
  );
};

const mapStateToProps = (state) => {
  return {
    tenant: state.tenant,
  };
};

export default connect(mapStateToProps)(AddText);
