import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, Redirect, withRouter } from 'react-router-dom';

import SettingsAside from '../../../components/app/settings/aside/SettingsAside';
import SettingsManager from '../../../components/app/settings/SettingsManager';
import GtinManager from '../../../components/app/settings/gtin-manager';

const Settings = () => (
  <Router>
    <div className="page-container">
      <div className="main-container">
        <div className="page-aside-container">
          <SettingsAside />
        </div>
        <div className="page-main-container">
          <Switch>
            <Route exact path="/settings" component={withRouter(SettingsManager)} />
            <Route path="/settings/gtin-reference" component={withRouter(GtinManager)} />
            <Redirect to="/users" />
          </Switch>
        </div>
      </div>
    </div>
  </Router>
);

const mapStateToProps = (state) => {
  return {
    loginData: state.auth,
    userData: state.user,
    tenantData: state.tenant,
  };
};

export default connect(mapStateToProps)(Settings);
