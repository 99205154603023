import {
  USERS_COLLECTION_GET,
  USERS_COLLECTION_ADD,
  USERS_COLLECTION_CLEAR,
  USERS_COLLECTION_DELETE,
  USERS_COLLECTION_UPDATE,
} from '../actions/actionTypes';

const initialState = [];

const usersCollectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case USERS_COLLECTION_ADD:
      return [...state, action.user];
    case USERS_COLLECTION_UPDATE:
      return state.map((item) => {
        if (item.id === action.user.id) {
          return action.user;
        }
        return item;
      });
    case USERS_COLLECTION_DELETE:
      return state.filter((item) => item.docId !== action.value);
    case USERS_COLLECTION_GET:
      return action.value;
    case USERS_COLLECTION_CLEAR:
      return initialState;
    default:
      return state;
  }
};

export default usersCollectionReducer;
