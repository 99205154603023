import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';

import { makeStyles, Table, TableContainer, TablePagination, Paper } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

import { LocationsTableToolbar } from './LocationsTableToolbar';
import { LocationsTableHead } from './LocationsTableHead';
import { LocationsTableBody } from './LocationsTableBody';

import Alert from '../../../../shared/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  paper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    cursor: 'pointer',
  },
  container: {
    height: '100%',
    maxHeight: window.innerHeight - 260,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tabPaginationPaper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    border: 'none',
    boxShadow: 'none',
    paddingRight: 20,
    minWidth: 200,
  },
  paginationPaper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItem: 'center',
  },
  tabPagination: {
    display: 'flex',
    alignItems: 'flex-end',
    padding: 10,
  },
  checked: {
    color: theme.palette.primary.main,
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
}));

const LocationsTable = (props) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [error, setError] = useState(false);
  const [errorType, setErrorType] = useState('success');
  const [errorText, setErrorText] = useState('');

  const {
    rows,
    setModalEditMode,
    setModalEditLocation,
    getLocationData,
    openDrawer,
    subLocationsData,
    tenantData,
    setIsLoading,
  } = props;

  const editLocation = (id) => {
    setModalEditMode(true);
    setModalEditLocation(getLocationData(id));
    openDrawer();
  };

  const removeLocation = async (row) => {
    setIsLoading(true);
    try {
      props.removeLocation({
        ...row,
        subLocations: subLocationsData.filter((subLocation) => subLocation.location === row.id),
      });
      setErrorText(`${row.name} was successfully removed`);
      setError(true);
    } catch {
      setErrorText('Something went wrong');
      setErrorType('error');
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const removeSelectedLocations = async () => {
    let generatedResponse = [];

    await Promise.all(
      rows.map(async (row) => {
        setIsLoading(true);
        try {
          if (selected.includes(row.id)) {
            let insertResponse = await props.removeLocation({
              ...row,
              subLocations: subLocationsData.filter((subLocation) => subLocation.location === row.id),
            });
            generatedResponse.push(insertResponse);
          }
          setErrorText(`${selected.length} location(s) was successfully removed`);
          setError(true);
        } catch (error) {
          setErrorText('Something went wrong');
          setErrorType('error');
          setError(true);
        } finally {
          setSelected([]);
        }
      }),
    );
    setIsLoading(false);
    return generatedResponse;
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (event, row) => {
    event.stopPropagation();
    editLocation(row.id);
  };

  const handleRowSelect = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePageTab = (event, newPage) => {
    setPage(newPage - 1);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <Fragment>
      <div id="locations-table" className={classes.root}>
        <Paper className={classes.paper}>
          {selected.length > 0 && (
            <LocationsTableToolbar numSelected={selected.length} removeSelectedLocations={removeSelectedLocations} />
          )}
          <TableContainer className={classes.container}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <LocationsTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <LocationsTableBody
                classes={classes}
                rows={rows}
                page={page}
                order={order}
                orderBy={orderBy}
                rowsPerPage={rowsPerPage}
                isSelected={isSelected}
                handleRowClick={handleRowClick}
                handleRowSelect={handleRowSelect}
                removeLocation={removeLocation}
              />
            </Table>
          </TableContainer>
          <Paper className={classes.paginationPaper}>
            <TablePagination
              rowsPerPageOptions={[25, 50, 75, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage="Results"
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <Paper className={classes.tabPaginationPaper}>
              <div
                style={{
                  fontWeight: 'bold',
                  color: page === 0 ? '#dcdfe4' : tenantData.mainColor,
                  cursor: 'pointer',
                }}
                onClick={(e) => {
                  if (page !== 0) {
                    setPage(page - 1);
                  }
                }}
              >
                Prev
              </div>
              <Pagination
                className={classes.tabPagination}
                count={Math.ceil(rows.length / rowsPerPage)}
                page={page + 1}
                hideNextButton
                hidePrevButton
                onChange={handleChangePageTab}
                defaultPage={1}
                color="primary"
              />
              <div
                style={{
                  fontWeight: 'bold',
                  color: page < Math.ceil(rows.length / rowsPerPage) - 1 ? tenantData.mainColor : '#dcdfe4',
                  cursor: 'pointer',
                }}
                onClick={(e) => {
                  if (page < Math.ceil(rows.length / rowsPerPage) - 1) {
                    setPage(page + 1);
                  }
                }}
              >
                Next
              </div>
            </Paper>
          </Paper>
        </Paper>
      </div>
      <Alert variant={errorType} message={errorText} open={error} onClose={() => setError(false)} />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    tenantData: state.tenant,
  };
};

export default connect(mapStateToProps)(LocationsTable);
