import React, { memo } from 'react';

import './login-aside.scss';
import '../../../styles/_base.scss';

import logo from '../../../assets/icons/opologic_mono_logo.svg';
import browser from '../../../assets/icons/cog-browser.svg';
import check from '../../../assets/icons/check-square.svg';
import time from '../../../assets/icons/time_circle.svg';

const LoginAside = () => {
  return (
    <div className="aside-container login-aside">
      <div className="container d-flex flex-end direction-column opology-info-container">
        <div className="info-list d-flex direction-column flex-start">
          <div className="d-flex w-100">
            <h1 className="scan-text">Scan</h1>
          </div>
          <div className="opology-information-list">
            <div className="opology-information-list-item d-flex">
              <img src={browser} alt="browser" className="item-icon" />
              <div className="item-text">Manage users, counts and schedules anywhere</div>
            </div>
            <div className="opology-information-list-item d-flex">
              <img src={time} alt="browser" className="item-icon" />
              <div className="item-text">Deploy instantly to your team</div>
            </div>
            <div className="opology-information-list-item d-flex">
              <img src={check} alt="browser" className="item-icon" />
              <div className="item-text">Patented OLR system reads all label formats</div>
            </div>
          </div>
        </div>
        <div className="opology-logo direction-column w-100 d-flex flex-end">
          <h4 className="powered-by-text">Powered by</h4>
          <div className="mono-logo-container">
            <img src={logo} alt="opologic_mono_logo" className="opologic-mono-logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(LoginAside);
