import { TENANT_SIGN_IN, TENANT_SIGN_OUT, TENANT_UPDATE, TENANT_UPDATE_SCAN } from './actionTypes';
import { TENANT_DATA_COLLECTION } from '../firebase/collections';
import firebase from '../firebase/Firebase';

export const setCurrentTenant = (tenant) => ({ type: TENANT_SIGN_IN, tenant });

export const removeCurrentTenant = () => ({ type: TENANT_SIGN_OUT });

export const updateTenant = (data) => (dispatch) => {
  const storageRef = firebase.storage.ref();
  return new Promise((resolve, reject) => {
    if (data.logoFile.size) {
      storageRef
        .child(`${data.businessName.replace(' ', '')}/${data.logoFile.name}`)
        .put(data.logoFile)
        .then((file) => {
          firebase.storage
            .ref(data.businessName.replace(' ', ''))
            .child(data.logoFile.name)
            .getDownloadURL()
            .then((url) => {
              firebase.db
                .collection(TENANT_DATA_COLLECTION)
                .where('id', '==', data.id)
                .get()
                .then((res) => {
                  const docId = res.docs[0].id;
                  firebase.db
                    .collection(TENANT_DATA_COLLECTION)
                    .doc(docId)
                    .update({
                      ...data,
                      logoFile: { url, name: data.logoFile.name },
                    })
                    .then(() => {
                      dispatch({
                        type: TENANT_UPDATE,
                        tenant: {
                          ...data,
                          logoFile: { url, name: data.logoFile.name },
                        },
                      });
                      resolve();
                    });
                });
            });
        });
    } else {
      firebase.db
        .collection(TENANT_DATA_COLLECTION)
        .where('id', '==', data.id)
        .get()
        .then((res) => {
          const docId = res.docs[0].id;
          firebase.db
            .collection(TENANT_DATA_COLLECTION)
            .doc(docId)
            .update(data)
            .then(() => {
              dispatch({ type: TENANT_UPDATE, tenant: data });
              resolve();
            });
        });
    }
  });
};

export const updateScanKeys = (data, id) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    const tenantSnapshot = await firebase.db
      .collection(TENANT_DATA_COLLECTION)
      .where('id', '==', id)
      .get()
      .catch(reject);
    const tenantDocId = tenantSnapshot.docs[0].id;
    const res = await firebase.db
      .collection(TENANT_DATA_COLLECTION)
      .doc(tenantDocId)
      .update({ barCodeScan: { ...data } })
      .catch(reject);
    dispatch({ type: TENANT_UPDATE_SCAN, value: data });
    resolve(res);
  });
};
