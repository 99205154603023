import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, LabelList } from 'recharts';

import '../dashboard-manager.scss';

const CountsMonthChart = (props) => {
  const { mainColor, data } = props;

  const renderCustomizedLabel = (props) => {
    const { x, y } = props;
    const radius = 5;

    return (
      <g>
        <circle cx={x + 2} cy={y - 4} r={radius} fill="none" stroke={mainColor} strokeWidth={2}></circle>;
      </g>
    );
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active) {
      return (
        <div className="custom-tooltip">
          <span className="font-bold m-r-sm">{`${payload[0].payload.fullName}:`}</span>
          {`${payload[0].value} count(s)`}
        </div>
      );
    }

    return null;
  };

  return (
    <BarChart
      width={500}
      height={230}
      data={data}
      margin={{
        top: 0,
        right: 50,
        left: 0,
        bottom: 0,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis padding={{ top: 15 }} />
      <Tooltip content={<CustomTooltip />} />
      <Bar dataKey="counts" barSize={5} background={true} fill={mainColor}>
        <LabelList dataKey="name" content={renderCustomizedLabel} />
      </Bar>
    </BarChart>
  );
};

export default CountsMonthChart;
