import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import Avatar from 'react-avatar';

import { signOut } from '../../../actions/userActions';

import { displayFullName } from '../../../util/helpers';

import { makeStyles } from '@material-ui/core/styles';
import { LinkContainer } from 'react-router-bootstrap';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HelpIcon from '@material-ui/icons/HelpOutline';

import LoadScreen from '../../load-screen/LoadScreen';

import logo from '../../../assets/icons/opologic_logo_icon_white.svg';
import notification from '../../../assets/icons/notifications_bell_grey.svg';
import accountLogo from '../../../assets/icons/account_circle_grey.svg';

import './header.scss';

const Header = (props) => {
  const { userData, tenantData, signOut } = props;
  const [menuOpen, setMenuOpen] = useState(null);

  const useStyles = makeStyles((theme) => ({
    grow: {
      flexGrow: 1,
    },
    tab: {
      minWidth: '100px !important',
      textTransform: 'none',
      '&:hover': {
        color: tenantData.mainColor,
      },
      '&selected': {
        color: `${tenantData.mainColor} !important`,
      },
      '&:focus': {
        color: `${tenantData.mainColor} !important`,
      },
    },
    circleSvg: {
      width: 30,
      height: 30,
    },
    headerBody: {
      backgroundColor: '#fff',
      color: '#000',
      display: 'flex',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
      color: '#000',
      fontSize: '18px',
      fontWeight: 'bold',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    liIcons: {
      display: 'flex',
      justifyContent: 'center',
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  }));

  const classes = useStyles();

  const path = window.location.pathname;
  const setInitialTab = (path) => {
    if (path.includes('counts')) {
      return 1;
    }

    switch (path) {
      case '/dashboard':
        return 0;
      case '/counts':
        return 1;
      case '/reports':
        return 2;
      case '/schedule':
        return 3;
      case '/users':
        return 4;
      case '/users/teams':
        return 4;
      case '/locations':
        return 5;
      case '/locations/schedules':
        return 5;
      case '/settings':
        return 6;
      default:
        return 0;
    }
  };

  useEffect(() => {
    setValue(setInitialTab(path));
  }, [path]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState(setInitialTab(path));
  const [isLoading, setIsLoading] = React.useState(false);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleOpenMenu = (e) => {
    setMenuOpen(e.currentTarget);
  };

  const handleCloseMenu = (e) => {
    setMenuOpen(null);
  };

  const signOutHandleClick = () => {
    setIsLoading(true);
    signOut().then(() => {
      setIsLoading(false);
      props.history.push('/');
    });
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleCloseMenu}
    >
      <MenuItem onClick={handleMenuClose}>{displayFullName(userData.fullName)}</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <LinkContainer to="/dashboard">
          <Tab label="Dashboard" className={`${classes.tab} tab`} selected={path.includes('dashboard')} />
        </LinkContainer>
      </MenuItem>
      <MenuItem>
        <LinkContainer to="/counts">
          <Tab label="Counts" className={`${classes.tab} tab`} />
        </LinkContainer>
      </MenuItem>
      <MenuItem>
        <LinkContainer to="/reports">
          <Tab label="Reports" className={`${classes.tab} tab`} />
        </LinkContainer>
      </MenuItem>
      <MenuItem>
        <LinkContainer to="/schedule">
          <Tab label="Schedule" className={`${classes.tab} tab`} />
        </LinkContainer>
      </MenuItem>
      <MenuItem>
        <LinkContainer to="/users">
          <Tab label="Users" className={`${classes.tab} tab`} selected={path.includes('users')} />
        </LinkContainer>
      </MenuItem>
      <MenuItem>
        <LinkContainer to="/locations">
          <Tab label="Locations" className={`${classes.tab} tab`} />
        </LinkContainer>
      </MenuItem>
      <MenuItem>
        <LinkContainer to="/settings">
          <Tab label="Settings" className={`${classes.tab} tab`} />
        </LinkContainer>
      </MenuItem>
      <MenuItem className={classes.liIcons}>
        <IconButton
          aria-label=""
          color="inherit"
          size="small"
          style={{ height: '55px', width: '55px' }}
          onClick={() => window.open('https://help.opologic.com/en/', '_blank')}
        >
          <HelpIcon color="inherit" style={{ height: '36px', width: '36px', color: '#8a95a6' }} />
        </IconButton>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen} className={classes.liIcons}>
        <IconButton aria-label="" color="inherit">
          <Badge color="secondary">
            {userData.photo ? (
              <Avatar src={userData.photo} size={35} round />
            ) : (
              <img src={accountLogo} style={{ width: 30, height: 30, color: '#707070' }} alt="account" />
            )}
          </Badge>
        </IconButton>
      </MenuItem>
    </Menu>
  );

  return (
    <Fragment>
      <Fragment>
        <div className={classes.grow}>
          <AppBar position="static" className={classes.headerBody}>
            <Toolbar className="header-body">
              <div className="tenant-logo-container">
                <div className="header-logo" style={{ backgroundColor: tenantData.mainColor }}>
                  <img src={tenantData.logoFile ? tenantData.logoFile.url : logo} className="logo" alt="logo" />
                </div>

                <Typography className={classes.title} noWrap>
                  {tenantData.businessName}
                </Typography>

                <div className="horizontal-line" />
              </div>

              <div className={classes.sectionDesktop}>
                <Paper square className="tabs-control">
                  <Tabs value={value} indicatorColor="primary" onChange={handleChange} textColor="primary">
                    <LinkContainer to="/dashboard">
                      <Tab label="Dashboard" className={`${classes.tab} tab`} />
                    </LinkContainer>
                    <LinkContainer to="/counts">
                      <Tab label="Counts" className={`${classes.tab} tab`} selected={path.includes('counts')} />
                    </LinkContainer>
                    <LinkContainer to="/reports">
                      <Tab label="Reports" className={`${classes.tab} tab`} selected={path.includes('reports')} />
                    </LinkContainer>
                    <LinkContainer to="/schedule">
                      <Tab label="Schedule" className={`${classes.tab} tab`} selected={path.includes('schedule')} />
                    </LinkContainer>
                    <LinkContainer to="/users">
                      <Tab label="Users" className={`${classes.tab} tab`} selected={path.includes('users')} />
                    </LinkContainer>
                    <LinkContainer to="/locations">
                      <Tab label="Locations" className={`${classes.tab} tab`} />
                    </LinkContainer>
                    <LinkContainer to="/settings">
                      <Tab label="Settings" className={`${classes.tab} tab`} />
                    </LinkContainer>
                  </Tabs>
                </Paper>
              </div>
              <div className={classes.sectionDesktop}>
                <IconButton
                  aria-label=""
                  color="inherit"
                  size="small"
                  style={{ height: '55px', width: '55px' }}
                  onClick={() => window.open('https://help.opologic.com/en/', '_blank')}
                >
                  <HelpIcon color="inherit" style={{ height: '36px', width: '36px', color: '#8a95a6' }} />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setValue(5);
                    props.history.push('/account');
                  }}
                  aria-label=""
                  color="inherit"
                >
                  <Badge color="secondary">
                    {userData.photo ? (
                      <Avatar src={userData.photo} size={35} round />
                    ) : (
                      <img src={accountLogo} style={{ width: 30, height: 30, color: '#707070' }} alt="account" />
                    )}
                  </Badge>
                </IconButton>
                <IconButton
                  aria-label=""
                  color="inherit"
                  disableRipple={true}
                  disableFocusRipple={true}
                  disabled={true}
                >
                  <Typography className="user">{displayFullName(userData.fullName)}</Typography>
                </IconButton>
                <IconButton onClick={handleOpenMenu} aria-label="" color="inherit" size="medium">
                  <ExpandMoreIcon fontSize="default" className={classes.circleSvg} color="disabled" />
                </IconButton>
                <Menu id="simple-menu" anchorEl={menuOpen} open={Boolean(menuOpen)} onClose={handleCloseMenu}>
                  <MenuItem onClick={signOutHandleClick}>
                    <div className="w-100 d-flex align-center">
                      <ExitToAppIcon fontSize="default" className={classes.circleSvg} color="disabled" />
                      <p className="m-0 table-text">Logout</p>
                    </div>
                  </MenuItem>
                </Menu>
              </div>

              <div className={classes.sectionMobile}>
                <IconButton
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
        </div>
        {renderMobileMenu}
        {renderMenu}
      </Fragment>
      {isLoading ? <LoadScreen /> : null}
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      signOut,
    },
    dispatch,
  );

const mapStateToProps = (state) => {
  return {
    userData: state.user,
    tenantData: state.tenant,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
