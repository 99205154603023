import { SCHEDULES_COLLECTION_ADD, SCHEDULES_COLLECTION_GET, SCHEDULES_COLLECTION_UPDATE } from './actionTypes';

import firebase from '../firebase/Firebase';

import { SCHEDULE_DATA_COLLECTION } from '../firebase/collections';

export const createSchedule = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    firebase
      .addToCollection(SCHEDULE_DATA_COLLECTION, data)
      .then((res) => {
        dispatch({ type: SCHEDULES_COLLECTION_ADD, schedule: { ...data, active: true, id: res } });
        resolve(res);
      })
      .catch(reject);
  });
};

export const updateSchedule = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    firebase
      .updateItem(SCHEDULE_DATA_COLLECTION, data)
      .then(() => {
        dispatch({ type: SCHEDULES_COLLECTION_UPDATE, schedule: { ...data, active: true } });
        resolve();
      })
      .catch(reject);
  });
};

export const getSchedules = (tenantId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    firebase
      .getCollection(SCHEDULE_DATA_COLLECTION, tenantId)
      .then((res) => {
        dispatch({ type: SCHEDULES_COLLECTION_GET, value: res.map((item) => ({ ...item, date: item.date.toDate() })) });
        resolve();
      })
      .catch(reject);
  });
};

export const deleteSchedule = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    firebase
      .disableItem(SCHEDULE_DATA_COLLECTION, data)
      .then((res) => {
        dispatch({ type: SCHEDULES_COLLECTION_UPDATE, schedule: { ...data, active: false } });
        resolve();
      })
      .catch(reject);
  });
};
