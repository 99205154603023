import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import { Link } from 'react-router-dom';

import './sign-up-button.scss';

const useStyles = makeStyles({
  button: {
    borderRadius: 30,
    borderColor: '#00aeef',
    color: '#00aeef',
    fontSize: 16,
    textAlign: 'center',
    marginLeft: 12,
    '&:hover': {
      backgroundColor: '#00aeef',
      borderColor: '#00aeef',
      color: '#ffffff',
    },
  },
});

const SignUpButton = (props) => {
  const classes = useStyles();
  const { buttonText, buttonRoleInfo, url } = props;

  return (
    <div className="d-flex w-100 flex-end sign-button-container align-center">
      <p className="button-role-text">{buttonRoleInfo}</p>
      <Link to={url} style={{ textDecoration: 'none' }}>
        <Button className={classes.button} color="primary" variant="outlined">
          {buttonText}
        </Button>
      </Link>
    </div>
  );
};

SignUpButton.propTypes = {
  buttonText: PropTypes.string,
  buttonRoleInfo: PropTypes.string,
  url: PropTypes.string,
};

export default SignUpButton;
