export const displayFullName = (fullName) => {
  if (!fullName) {
    return '';
  }
  return fullName.replace('_', ' ');
};

export const downloadURI = (uri) => {
  const link = document.createElement('a');
  link.setAttribute('download', null);
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const FULL_NAME_REGEX = /[_, ]/;

export const sortAlphaNum = (a, b) => a.localeCompare(b, 'en', { numeric: true });

const descendingComparator = (a, b, orderBy) => {
  if (typeof a[orderBy] === 'string') {
    if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
      return -1;
    }
    if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
      return 1;
    }
    return 0;
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
};

export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const toUpperFirstLetter = (str) => str.replace(/(^|\s)\S/g, (string) => string.toUpperCase());

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
