import React, { Fragment, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CountDetails from './CountDetails';
import CountTeams from './CountTeams';
import CountItems from './CountItems';
import CountNotes from './CountNotes';

import { updateLocation } from '../../../../../actions/locationsCollectionActions';
import { createLocation } from '../../../../../actions/locationsCollectionActions';
import { getSingleCount } from '../../../../../actions/countsActions';

import place from '../../../../../assets/icons/place.svg';

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import LinearProgress from '@material-ui/core/LinearProgress';

import './counts-modal.scss';
import Frequency from './Frequency';
import StyledButton from '../../../../shared/Button';
import { formatDate } from '../../../../shared/sharedFunctions';
import { displayFullName, downloadURI } from '../../../../../util/helpers';
import urls from '../../../../../constants/urls';

const useStyles = makeStyles({
  drawer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  drawerPaper: {
    boxShadow: '-4px 1px 13px 1px  rgba(87,73,87,1)',
    width: '50%',
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 30,
    paddingRight: 30,
    maxWidth: 1000,
  },
  progressBar: {
    height: 8,
    borderRadius: 4,
  },
  tabs: {
    borderBottom: '1px solid #E9E9E9',
  },
  tab: {
    minWidth: '100px !important',
    display: 'flex',
    padding: '0 !important',
    justifyContent: 'flex-start !important',
  },
});

const CountsModal = (props) => {
  const classes = useStyles();
  const {
    drawerIsOpen,
    closeDrawer,
    detailCount,
    schedules,
    teamsData,
    usersCollection,
    subLocationsData,
    tenantData,
    users,
    getSingleCount,
  } = props;
  const [count, setCount] = useState(null);

  useEffect(() => {
    if (detailCount && drawerIsOpen) {
      (async function () {
        const _count = await getSingleCount(detailCount.id);
        if (_count) setCount(_count);
        else setCount(null);
      })();
    }
  }, [drawerIsOpen]);

  const [tabValue, setTabValue] = useState(0);
  const getItemById = (collection, id) => {
    return collection.find((item) => item.id === id);
  };

  const tabValueHandleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getAllProducts = () => {
    const products = [];

    if (detailCount.scans) {
      for (let key in detailCount.scans) {
        detailCount.scans[key].products.forEach((product) => {
          products.push(product);
        });
      }
      return products;
    } else {
      return [];
    }
  };

  const getRows = () => {
    if (detailCount.scans) {
      const items = [];

      for (let key in detailCount.scans) {
        items.push({
          ...detailCount.scans[key],
          subLocation: key,
          subTotal: detailCount.scans[key].products.reduce((sum, item) => {
            return sum + item.quantity;
          }, 0),
        });
      }
      return items;
    } else {
      return [];
    }
  };

  const rows = getRows();

  const headers = [
    { label: 'User', key: 'user' },
    { label: 'Date', key: 'date' },
    { label: 'Sub-Location', key: 'subLocation' },
    { label: 'SubTotal', key: 'subTotal' },
  ];

  const csvArray = rows.map((item) => {
    return {
      user: displayFullName(getItemById(users, item.userId).fullName),
      date: formatDate(tenantData.dateFormat, detailCount.date),
      subLocation: getItemById(subLocationsData, item.subLocation).name,
      subTotal: item.subTotal,
    };
  });

  const getProgressPercents = () => {
    if (detailCount.scans && Object.keys(detailCount.scans).length > 0) {
      const completeLocations = detailCount.subLocations.filter((subLocation) => {
        return detailCount.scans[subLocation] && detailCount.scans[subLocation].submitted;
      });

      return (completeLocations.length * 100) / detailCount.subLocations.length;
    } else {
      return 0;
    }
  };

  const tabComponentSwitch = () => {
    switch (tabValue) {
      case 0:
        return (
          <CountDetails
            users={usersCollection}
            subLocations={subLocationsData}
            scans={detailCount.scans}
            date={new Date(detailCount.date)}
          />
        );
      case 1:
        return (
          <CountTeams
            tenant={tenantData}
            teams={detailCount.teams.map((team) => {
              return teamsData.find((item) => team === item.id);
            })}
            users={detailCount.users.map((user) => {
              return usersCollection.find((item) => item.id === user);
            })}
          />
        );
      case 2:
        return (
          <CountItems
            tenant={tenantData}
            scans={detailCount.scans}
            products={getAllProducts()}
            countSubLocations={detailCount.subLocations}
            subLocations={subLocationsData}
          />
        );
      case 3:
        return <CountNotes note={detailCount.notes} />;
      default:
        return <CountDetails />;
    }
  };

  const displayName = (count) => {
    if (count.schedule) {
      return getItemById(schedules, count.schedule).name;
    } else if (count.name) {
      return count.name;
    } else {
      return 'Single Count';
    }
  };

  const exportFile = () => {
    downloadURI(`${urls.exportFile}?countId=${count.id}`);
  };

  return (
    <Fragment>
      <Drawer
        className={classes.drawer}
        anchor={'right'}
        open={drawerIsOpen}
        classes={{ paperAnchorRight: classes.drawerPaper }}
        ModalProps={{ onBackdropClick: closeDrawer }}
      >
        <div className="main-details-container">
          <div className="drawer-title-container">
            <div className="drawer-title">Count ID</div>
            <div className="drawer-title-black">{detailCount.id}</div>
          </div>
          <div className="location-details-container">
            <div className="location-name-container">
              <div className="location-name">
                <img src={place} alt="address" />
                <div className="drawer-title-black m-l-lg">{detailCount.location ? detailCount.location.name : ''}</div>
              </div>
              <div className="schedule-frequency-icon">
                {detailCount.schedule ? (
                  <Frequency text={getItemById(schedules, detailCount.schedule).frequency} />
                ) : (
                  <Frequency text="Single" />
                )}
              </div>
            </div>
            <div className="schedule-location-name m-t-lg">{displayName(detailCount)}</div>
            <div className="w-100 d-flex flex-end m-t-lg">
              <p className="progress-percents m-0">{`${
                getProgressPercents() === 100 ? 'Completed' : 'In progress'
              } ${Math.round(getProgressPercents())}%`}</p>
            </div>
            <div className="progress-line-container w-100 m-t-md">
              <LinearProgress
                variant="determinate"
                color="primary"
                className={classes.progressBar}
                value={getProgressPercents()}
              />
            </div>
          </div>
          <div className="w-100 m-t-lg">
            <Paper elevation={0}>
              <Tabs
                value={tabValue}
                indicatorColor="primary"
                textColor="primary"
                className={classes.tabs}
                onChange={tabValueHandleChange}
              >
                <Tab className={classes.tab} label="Count Details" />
                <Tab className={classes.tab} label="Teams" />
                <Tab className={classes.tab} label="Items list" />
                <Tab className={classes.tab} label="Notes" />
              </Tabs>
            </Paper>
          </div>
        </div>
        <div className="sub-details-container">{tabComponentSwitch()}</div>
        <div className="submit-modal-footer">{count ? <StyledButton text="Export" onClick={exportFile} /> : null}</div>
      </Drawer>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateLocation,
      createLocation,
      getSingleCount,
    },
    dispatch,
  );

const mapStateToProps = (state) => {
  return {
    users: state.usersCollection,
    tenantData: state.tenant,
    subLocationsData: state.subLocations,
    locationsData: state.locations,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CountsModal);
