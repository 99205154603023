import React, { useState } from 'react';

import { Autocomplete } from '@material-ui/lab';

import Avatar from 'react-avatar';
import TextField from '@material-ui/core/TextField';
import Icon from '@material-ui/core/Icon';

import remove from '../../../../../assets/icons/delete.svg';

import './team-modal.scss';
import { connect } from 'react-redux';
import { displayFullName } from '../../../../../util/helpers';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

const Members = (props) => {
  const [memberName, setMemberName] = useState('');

  const { addMember, removeMember, tenantData } = props;

  const options = props.usersCollection
    .filter((user) => user.active === true)
    .map((user) => displayFullName(user.fullName));

  const getMembersData = (name) => {
    return props.usersCollection.find((member) => displayFullName(member.fullName) === displayFullName(name));
  };

  return (
    <div className="d-flex flex-1 direction-column">
      <div className="drawer-title">Members</div>
      <div className="members-select">
        <div className="w-50 d-flex align-end">
          <div className="flex-1">
            <Autocomplete
              options={options}
              onChange={(e, v) => {
                setMemberName(v);
              }}
              value={memberName}
              id="members"
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Assign User(s)"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </div>
          <div className="p-l-sm">
            <IconButton
              onClick={(e) => {
                addMember(getMembersData(memberName));
                setMemberName('');
              }}
              color={memberName ? 'primary' : 'secondary'}
              aria-label=""
              size="small"
            >
              <Icon color={memberName ? 'primary' : 'secondary'}>add_circle_outline</Icon>
            </IconButton>
          </div>
        </div>
      </div>
      <div className="d-flex direction-column flex-1 w-100 members-list">
        {props.members.map((member) => {
          return (
            <div key={member.id} className="d-flex w-100 space-between align-center">
              <div className="user-info">
                <div className="user-info-item avatar">
                  <Avatar
                    name={displayFullName(member.fullName)}
                    size={26}
                    round
                    textSizeRatio={2}
                    textMarginRatio={0.22}
                  />
                </div>
                <div
                  className="user-info-item"
                  style={{
                    width: '60%',
                    fontSize: 18,
                    fontWeight: 'bold',
                    color: tenantData.mainColor,
                  }}
                >
                  {displayFullName(member.fullName)}
                </div>
                <div className="user-info-item user-email">{member.email}</div>
              </div>
              <div className="m-t-md">
                <IconButton onClick={(e) => removeMember(member)} aria-label="" color="secondary" size="small">
                  <DeleteOutlineIcon style={{ color: '#d32f2f' }} />
                </IconButton>
              </div>
            </div>
          );
        })}
      </div>
      <div className="members-list"></div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    tenantData: state.tenant,
  };
};

export default connect(mapStateToProps)(Members);
