import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import theme from './styles/staticTheme';
import { MuiThemeProvider } from '@material-ui/core';

import App from './App';
import reducers from './reducers';
import * as serviceWorker from './serviceWorker';
import './index.scss';

const combinedReducers = combineReducers(reducers);
const store = createStore(combinedReducers, composeWithDevTools(applyMiddleware(thunk)));

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <BrowserRouter>
        <Route component={App} />
      </BrowserRouter>
    </Provider>
  </MuiThemeProvider>,
  document.getElementById('root'),
);

serviceWorker.register();
