import React, { Fragment, useEffect } from 'react';

import { BrowserRouter as Router, Route, Switch, Redirect, withRouter } from 'react-router-dom';
import Header from '../navigation-header/Header';
import Dashboard from '../../../pages/app/dashboard/Dashboard';
import Counts from '../../../pages/app/counts/Counts';
import Count from '../../../pages/app/counts/Count';
import Locations from '../../../pages/app/locations/Locations';
import Schedule from '../../../pages/app/schedule/Schedule';
import Users from '../../../pages/app/users/Users';
import Settings from '../../../pages/app/settings/Settings';
import Account from '../../../pages/app/account/Account';
import Reports from '../../../pages/app/reports/Reports';

import './app-navigation.scss';

const AppNavigation = ({ history }) => {
  useEffect(() => {
    return history.listen((location) => {
      console.log(`You changed the page to: ${location.pathname}`);
    });
  }, [history]);

  return (
    <Router>
      <Fragment>
        <div className="page">
          <div className="header">
            <Header />
          </div>
          <Switch>
            <Route path="/" exact component={Dashboard} />
            <Route exact path="/counts" component={Counts} />
            <Route exact path="/counts/:id" component={Count} />
            <Route path="/locations" component={Locations} />
            <Route path="/users" component={Users} />
            <Route path="/settings" component={Settings} />
            <Route path="/account" component={Account} />
            <Route path="/schedule" component={Schedule} />
            <Route path="/reports" component={Reports} />
            <Redirect to="/" />
          </Switch>
        </div>
      </Fragment>
    </Router>
  );
};

export default withRouter(AppNavigation);
