import React from 'react';
import { BrowserRouter as Router, Switch, Route, withRouter } from 'react-router-dom';

import ReportsAside from '../../../components/app/reports/reports-aside/ReportsAside';
import ReportsManager from '../../../components/app/reports/ReportsManager';

const Reports = () => (
  <Router>
    <div className="page-container">
      <div className="main-container">
        <div className="page-aside-container">
          <ReportsAside />
        </div>
        <div className="page-main-container">
          <Switch>
            <Route exact path="/reports" component={withRouter(ReportsManager)} />
            <Route path="/reports/completed-counts" component={withRouter(ReportsManager)} />
            <Route path="/reports/items-counted" component={withRouter(ReportsManager)} />
          </Switch>
        </div>
      </div>
    </div>
  </Router>
);

export default Reports;
