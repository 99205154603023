import React, { memo } from 'react';

import BusinessForm from '../../../components/auth/business-form/BusinessForm';
import CancelButton from '../../../components/auth/cancel-button/CancelButton';
import SignUpTitle from '../../../components/auth/sign-up-title/SignUpTitle';

import { Hidden } from '@material-ui/core';

import { withRouter } from 'react-router';

const Business = (props) => {
  return (
    <div className="main-login-container d-flex direction-column">
      <Hidden smDown>
        <CancelButton url="/sign-up" buttonText="Sign Up" buttonRoleInfo="Don`t yet have an account?" />
      </Hidden>
      <Hidden smDown>
        <SignUpTitle height="10%" titleText="Business info" />
      </Hidden>
      <BusinessForm history={props.history} />
    </div>
  );
};

export default withRouter(memo(Business));
