import React, { Fragment, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { updateTenant } from '../../../../actions/tenantActions';

import StyledButton from '../../../shared/Button';

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import BusinessFields from './BusinessFields';
import CustomizeFields from './CustomizeFields';

import LoadScreen from '../../../load-screen/LoadScreen';
import Alert from '../../../shared/Alert';
import CancelButton from '../../../shared/CancelButton';

const useStyles = makeStyles({
  drawer: {
    width: '100%',
  },
  drawerPaper: {
    boxShadow: '-4px 1px 13px 1px  rgba(87,73,87,1)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '50%',
  },
  nameInput: {
    width: '40%',
  },
  positionInput: {
    width: '100%',
  },
  tab: {
    marginTop: 20,
    marginBottom: 20,
    borderBottom: '1px solid #E9E9E9',
    padding: 0,
  },
  tabItem: {
    display: 'flex',
    padding: '0 !important',
    justifyContent: 'flex-start !important',
  },
  radioGroup: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  startTextWrapper: {
    alignItems: 'flex-start !important',
  },
});

const useScanConfig = (scanConfig) => {
  const fields = scanConfig && scanConfig.qrScanConfig ? [...scanConfig.qrScanConfig] : [];
  const scanType = scanConfig && scanConfig.scanType ? scanConfig.scanType : 'QR';

  return { fields, scanType };
};

const regex = /^[a-zA-Z0-9]{2,30}$/;

const SettingsModal = (props) => {
  const classes = useStyles();
  const { drawerIsOpen, closeDrawer, tenantData, updateTenant } = props;
  const initialScanConfig = useScanConfig(tenantData.scanConfig);
  const [businessName, setBusinessName] = useState(tenantData.businessName);
  const [TAXnumber, setTAXnumber] = useState(tenantData.TAXnumber);
  const [address, setAddress] = useState(tenantData.address);
  const [phone, setPhone] = useState(tenantData.phone);
  const [color, setColor] = useState(tenantData.mainColor);
  const [dateFormat, setDateFormat] = useState(tenantData.dateFormat);
  const [logoFile, setLogoFile] = useState(tenantData.logoFile);
  const [email, setEmail] = useState(tenantData.email);
  const [dataEmail, setDataEmail] = useState(tenantData.dataEmail);
  const [dataEmail2, setDataEmail2] = useState(tenantData.dataEmail2);
  const [scanType, setScanType] = useState(initialScanConfig.scanType);
  const [fields, setFields] = useState(initialScanConfig.fields.map((item) => ({ ...item, edit: false })));
  const [error, setError] = useState(false);
  const [errorType, setErrorType] = useState('error');
  const [errorText, setErrorText] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [drawerTabValue, setDrawerTabValue] = useState(0);

  const drawerTabValueHandleChange = (event, newValue) => {
    setDrawerTabValue(newValue);
  };

  const changeScanType = (e) => setScanType(e.target.value);

  const onEditField = (index) => {
    setFields(fields.map((item, _index) => (index === _index ? { ...item, edit: true } : { ...item, edit: false })));
  };

  const addField = (index, newField) => {
    if (!regex.test(newField.title)) {
      setErrorText('Wrong title format');
      setErrorType('error');
      setError(true);
      return;
    }
    if (!newField || !newField.title || !newField.section) {
      setErrorText('Input all required fields');
      setErrorType('error');
      setError(true);
      return;
    }
    const isDuplicate = fields.some((item) => item.title === newField.title || +item.section === +newField.section);
    if (isDuplicate) {
      setErrorText('You already have a field with this name or section number');
      setErrorType('error');
      setError(true);
    } else {
      setFields([...fields, { ...newField, edit: false }]);
    }
  };

  const saveField = (index, field) => {
    if (!regex.test(field.title)) {
      setErrorText('Wrong title format');
      setErrorType('error');
      setError(true);
      return;
    }
    if (!field || !field.title || !field.section) {
      setErrorText('Input all required fields');
      setErrorType('error');
      setError(true);
      return;
    }

    const isDuplicate = fields.some(
      (item, _index) => _index !== index && (item.title === field.title || +item.section === +field.section),
    );
    if (isDuplicate) {
      setErrorText('You already have a field with this name or section number');
      setErrorType('error');
      setError(true);
      return;
    }

    setFields(
      fields.map((item, _index) => (index === _index ? { ...item, ...field, edit: false } : { ...item, edit: false })),
    );
  };

  const deleteField = (index) => {
    setFields(fields.filter((item, _index) => _index !== index));
  };

  const collectData = () => {
    return {
      ...tenantData,
      businessName,
      TAXnumber,
      address,
      phone,
      mainColor: color,
      dateFormat,
      logoFile,
      email,
      dataEmail: dataEmail || '',
      dataEmail2: dataEmail2 || '',
      scanConfig: {
        scanType,
        qrScanConfig: fields.map((field) => ({ title: field.title, section: +field.section })),
      },
    };
  };

  const onSubmit = () => {
    setIsLoading(true);
    if (fields.some((item) => item.edit)) {
      setIsLoading(false);
      setErrorType('error');
      setErrorText('Permission denied');
      setError(true);
      return;
    }
    if (businessName && color && dateFormat) {
      updateTenant(collectData())
        .then(() => {
          setIsLoading(false);
          closeDrawer();
        })
        .catch(() => {
          setIsLoading(false);
          closeDrawer();
          setErrorType('error');
          setErrorText('Permission denied');
          setError(true);
        });
    } else {
      setErrorText('Input all required fields');
      setError(true);
      setIsLoading(false);
    }
  };

  const tabValueSwitch = () => {
    return drawerTabValue === 0 ? (
      <BusinessFields
        businessName={businessName}
        setBusinessName={setBusinessName}
        TAXnumber={TAXnumber}
        setTAXnumber={setTAXnumber}
        address={address}
        setAddress={setAddress}
        phone={phone}
        setPhone={setPhone}
        email={email}
        setEmail={setEmail}
        dataEmail={dataEmail}
        setDataEmail={setDataEmail}
        dataEmail2={dataEmail2}
        setDataEmail2={setDataEmail2}
      />
    ) : (
      <CustomizeFields
        color={color}
        setColor={setColor}
        dateFormat={dateFormat}
        setDateFormat={setDateFormat}
        logoFile={logoFile}
        setLogoFile={setLogoFile}
        changeScanType={changeScanType}
        scanType={scanType}
        fields={fields}
        saveField={saveField}
        addField={addField}
        onEditField={onEditField}
        deleteField={deleteField}
      />
    );
  };

  return (
    <Fragment>
      <Drawer
        className={classes.drawer}
        anchor={'right'}
        open={drawerIsOpen}
        classes={{ paperAnchorRight: classes.drawerPaper }}
        ModalProps={{ onBackdropClick: closeDrawer }}
      >
        <div className="drawer-container">
          <div className="drawer-title">Edit Business</div>
          <Paper elevation={0}>
            <Tabs
              value={drawerTabValue}
              indicatorColor="primary"
              textColor="primary"
              className={classes.tab}
              onChange={drawerTabValueHandleChange}
            >
              <Tab className={classes.tabItem} classes={{ wrapper: classes.startTextWrapper }} label="Business" />
              <Tab className={classes.tabItem} classes={{ wrapper: classes.startTextWrapper }} label="Customize" />
            </Tabs>
          </Paper>
          <div className="flex-1">{tabValueSwitch()}</div>
        </div>
        <div className="submit-modal-footer">
          <CancelButton onClick={closeDrawer} />
          <StyledButton text="Save" onClick={onSubmit} />
        </div>
      </Drawer>
      {isLoading ? <LoadScreen /> : null}
      <Alert variant={errorType} message={errorText} open={error} onClose={() => setError(false)} />
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateTenant,
    },
    dispatch,
  );

const mapStateToProps = (state) => {
  return {
    tenantData: state.tenant,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsModal);
