export const UNIQUE_TENANT = 'UNIQUE_TENANT';

export const message = (error) => {
  switch (error) {
    case UNIQUE_TENANT:
      return 'Business name is already in use';
    default:
      return error;
  }
};
