import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';

import './cancel-button.scss';

const CancelButton = (props) => {
  const { url } = props;

  return (
    <div className="d-flex w-100 flex-end sign-button-container align-center">
      <Link to={url} style={{ textDecoration: 'none' }}>
        <div className="d-flex align-center flex-center">
          <Icon style={{ color: '#8b95a8' }}>close</Icon>
          <p className="cancel-text">Cancel</p>
        </div>
      </Link>
    </div>
  );
};

CancelButton.propTypes = {
  url: PropTypes.string,
};

export default CancelButton;
