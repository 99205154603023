import { USER_SIGN_IN, USER_SIGN_OUT, USER_PHOTO_UPDATE } from '../actions/actionTypes';
import { displayFullName } from '../util/helpers';

const initialState = {
  id: null,
  email: '',
  fullName: '',
  role: '',
  position: '',
  locations: '',
  teams: '',
  tenantId: '',
  phone: '',
  description: '',
  photo: '',
  emailVerified: false,
  isLoaded: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_SIGN_IN:
      const { user } = action;

      return {
        id: user.id,
        email: user.email,
        fullName: displayFullName(user.fullName),
        role: user.role,
        position: user.position,
        locations: user.locations,
        teams: user.teams,
        tenantId: user.tenantId,
        emailVerified: user.emailVerified,
        phone: user.phone,
        description: user.description,
        photo: user.photo ? user.photo : '',
        isLoaded: true,
      };
    case USER_SIGN_OUT:
      return initialState;
    case USER_PHOTO_UPDATE:
      return { ...state, photo: action.link };
    default:
      return state;
  }
};

export default userReducer;
