import firebase from '../firebase/Firebase';
import { api } from '../util';
import {
  USERS_COLLECTION_UPDATE,
  TEAM_COLLECTION_GET,
  TEAM_COLLECTION_ADD,
  TEAM_COLLECTION_UPDATE,
} from './actionTypes';

import { TEAM_DATA_COLLECTION } from '../firebase/collections';
import urls from '../constants/urls';

export const createTeam = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    firebase
      .addToCollection(TEAM_DATA_COLLECTION, {
        name: data.name,
        description: data.description,
        tenantId: data.tenantId,
      })
      .then((res) => {
        const promises = [];
        data.members.forEach((member) => {
          const updatedUser = { ...member, teams: [...member.teams, res] };

          promises.push(
            api.post(urls.userUpdateDB, updatedUser).then(() => {
              dispatch({
                type: USERS_COLLECTION_UPDATE,
                user: updatedUser,
              });
            }),
          );
        });

        Promise.all(promises).then(() => {
          dispatch({
            type: TEAM_COLLECTION_ADD,
            team: { id: res, name: data.name, description: data.description, active: true },
          });
          resolve();
        });
      })
      .catch(reject);
  });
};

export const updateTeam = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    firebase
      .updateItem(TEAM_DATA_COLLECTION, {
        id: data.id,
        description: data.description,
        name: data.name,
      })
      .then(() => {
        const initialMembersPromises = [];
        const resultMembersPromises = [];

        data.initialMembersList.forEach((member) => {
          const updatedMember = { ...member, teams: member.teams.filter((team) => team !== data.id) };
          initialMembersPromises.push(
            api.post(urls.userUpdateDB, updatedMember).then(() => {
              dispatch({
                type: USERS_COLLECTION_UPDATE,
                user: updatedMember,
              });
            }),
          );
        });

        data.members.forEach((member) => {
          const updatedMember = { ...member, teams: [...member.teams, data.id] };
          resultMembersPromises.push(
            api.post(urls.userUpdateDB, updatedMember).then(() => {
              dispatch({
                type: USERS_COLLECTION_UPDATE,
                user: updatedMember,
              });
            }),
          );
        });

        dispatch({
          type: TEAM_COLLECTION_UPDATE,
          team: { id: data.id, name: data.name, description: data.description, active: data.active },
        });

        Promise.all(initialMembersPromises).then(() => {
          Promise.all(resultMembersPromises).then(resolve);
        });
      })
      .catch(reject);
  });
};

export const getTeams = (tenantId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    firebase
      .getCollection(TEAM_DATA_COLLECTION, tenantId)
      .then((res) => {
        dispatch({ type: TEAM_COLLECTION_GET, value: res });
        resolve();
      })
      .catch(reject);
  });
};

export const removeTeam = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    firebase
      .disableItem(TEAM_DATA_COLLECTION, {
        id: data.id,
        name: data.name,
        description: data.description,
        tenantId: data.tenantId,
      })
      .then((res) => {
        const promises = [];

        data.members.forEach((member) => {
          const updatedUser = { ...member, teams: member.teams.filter((team) => team !== data.id) };

          promises.push(
            api.post(urls.userUpdateDB, updatedUser).then(() => {
              dispatch({
                type: USERS_COLLECTION_UPDATE,
                user: updatedUser,
              });
            }),
          );
        });
        dispatch({
          type: TEAM_COLLECTION_UPDATE,
          team: { id: data.id, name: data.name, tenantId: data.tenantId, active: false },
        });

        Promise.all(promises).then(resolve);
      })
      .catch(reject);
  });
};
