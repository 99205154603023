export const formatDate = (format, date) => {
  if (format === 'DD/MM/YYYY') {
    return date.toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' });
  } else if (format === 'MM/DD/YYYY') {
    return date.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
  } else if (format === 'YYYY.MM.DD') {
    return date.toLocaleDateString('ko-KR', { year: 'numeric', month: '2-digit', day: '2-digit' });
  } else if (format === 'YYYY/MM/DD') {
    return date.toLocaleDateString('zh-Hans-CN', { year: 'numeric', month: '2-digit', day: '2-digit' });
  }
};

export const searchFilter = (collection, value) => {
  if (value) {
    return collection.filter((item) => {
      const tempArr = [];
      for (let key in item) {
        if (typeof item[key] === 'string') {
          tempArr.push(item[key]);
        }
      }
      return tempArr.some((item) => item.toLowerCase().includes(value.toLowerCase()));
    });
  } else {
    return collection;
  }
};

export const getItemById = (collection, id) => {
  return collection.find((item) => item.id === id);
};
