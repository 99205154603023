import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { nullifyState } from '../../../actions/userActions';

import LoginForm from '../../../components/auth/login-form/LoginForm';
import SignUpButton from '../../../components/auth/sign-up-button/SignUpButton';
import SignUpTitle from '../../../components/auth/sign-up-title/SignUpTitle';
import TermsAccept from '../../../components/auth/terms-accept/TermsAccept';

const SignIn = (props) => {
  useEffect(() => {
    const { nullifyState } = props;

    nullifyState();
  }, [props]);

  return (
    <div className="main-login-container d-flex direction-column">
      <SignUpButton url="/sign-up" buttonText="Sign Up" buttonRoleInfo="Don't yet have an account?" />
      <SignUpTitle height="30%" titleText="Sign In to Scan Admin" />
      <LoginForm history={props.history} />
      <TermsAccept submitText="SignIn" />
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      nullifyState,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(SignIn);
