import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';

import visibility from '../../../assets/icons/visibility.svg';
import visibilityOff from '../../../assets/icons/visibility_off.svg';

import { authLogin } from '../../../actions/authActions';
import { userSignIn } from '../../../actions/userActions';

import './login-form.scss';
import LoadScreen from '../../load-screen/LoadScreen';
import Alert from '../../shared/Alert';

const useStyles = makeStyles({
  input: {
    marginTop: 20,
    width: '100%',
    '&hover': {
      borderColor: '#00aeef',
    },
  },
  button: {
    backgroundColor: '#00aeef',
    borderColor: '#00aeef',
    float: 'center',
    color: '#ffffff',
    borderRadius: 30,
    paddingLeft: 40,
    paddingRight: 40,
    fontSize: 14,
    textAlign: 'center',
    marginLeft: 12,
    '&:hover': {
      borderColor: '#00aeef',
      color: '#00aeef',
    },
    '&:disabled': {
      color: 'gray',
      backgroundColor: '#f9f9f9',
    },
  },
  visibilityIcon: {
    cursor: 'pointer',
  },
});

const LoginForm = (props) => {
  const classes = useStyles();

  const [visiblePassword, setVisiblePassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');

  const changeEmail = (e) => {
    if (e.target.value !== ' ') {
      setEmail(e.target.value);
    } else {
      setEmail('');
      e.preventDefault();
    }
  };

  const changePassword = (e) => {
    if (e.target.value !== ' ') {
      setPassword(e.target.value);
    } else {
      setPassword('');
      e.preventDefault();
    }
  };

  const onSubmit = () => {
    setIsLoading(true);
    const { userSignIn } = props;

    if (!email || !password) {
      setIsLoading(false);
      return;
    }

    userSignIn({ email, password })
      .then((res) => {
        // props.history.push('/');
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setErrorText('Login error. Please, try again.');
        setError(true);
      });
  };

  const checkVisiblePassword = () => {
    return visiblePassword ? (
      <img
        className={classes.visibilityIcon}
        src={visibilityOff}
        alt="visible"
        onClick={() => {
          setVisiblePassword(false);
        }}
      />
    ) : (
      <img
        className={classes.visibilityIcon}
        src={visibility}
        alt="visible"
        onClick={() => {
          setVisiblePassword(true);
        }}
      />
    );
  };

  return (
    <Fragment>
      <div className="w-100 d-flex flex-center align-center">
        <form className="d-flex direction-column form-container" noValidate autoComplete={false}>
          <TextField
            value={email}
            className={classes.input}
            id="email"
            type="email"
            label="Email"
            placeholder="E.g email@myemail.com"
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                onSubmit();
              }
            }}
            onChange={changeEmail}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            value={password}
            className={classes.input}
            id="password"
            type={visiblePassword ? 'text' : 'password'}
            label="Password"
            placeholder="Your password here"
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                onSubmit();
              }
            }}
            onChange={changePassword}
            fullWidth
            InputProps={{
              endAdornment: checkVisiblePassword(),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <div className="d-flex w-100 align-center space-between submit-container">
            <div className="flex-1">
              <Link to="/password-reset" className="forgot-password-text">
                Forgot password?
              </Link>
            </div>
            <div className="flex-1 login-submit-button">
              <Button
                onClick={onSubmit}
                disabled={email === '' || password === ''}
                className={classes.button}
                color="primary"
                variant="outlined"
              >
                Login
              </Button>
            </div>
            <Hidden smDown>
              <div className="flex-1" />
            </Hidden>
          </div>
        </form>
      </div>
      <Alert variant="error" message={errorText} open={error} onClose={() => setError(false)} />
      {isLoading ? <LoadScreen /> : null}
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      authLogin,
      userSignIn,
    },
    dispatch,
  );

const mapStateToProps = (state) => {
  return {
    loginData: state.auth,
    userData: state.user,
    tenantData: state.tenant,
  };
};

LoginForm.propTypes = {
  authLogin: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
