import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/messaging';

import firebaseConfig from '../config/firebaseConfig';

class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);

    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();
    this.messaging = app.messaging();
  }

  doSignInWithEmailAndPassword = (email, password) => {
    return this.auth.signInWithEmailAndPassword(email, password);
  };

  doResetPassword = (email) => {
    return this.auth.sendPasswordResetEmail(email);
  };

  checkUnique = (collection, field, value) => {
    return this.db
      .collection(collection)
      .where(field, '==', value)
      .get()
      .then((res) => {
        return !res.docs[0];
      })
      .catch((err) => {
        return err;
      });
  };

  doSendEmailVerification = () => this.auth.currentUser.sendEmailVerification();

  doGetCurrentUser = () => {
    return this.auth;
  };

  doSignOut = () => {
    return this.auth.signOut();
  };

  addToCollection = (collection, item) => {
    return this.db
      .collection(collection)
      .add({ ...item, active: true })
      .then((res) => {
        return res.id;
      });
  };

  addToCollectionWithCustomId = (collection, item, id) => {
    return this.db
      .collection(collection)
      .doc(id)
      .set({ ...item, active: true });
  };

  getItemFromCollection = (collection, id) => {
    return this.db
      .collection(collection)
      .doc(id)
      .get()
      .then((res) => {
        return { ...res.data(), id };
      });
  };

  updateItem = (collection, item) => {
    return this.db
      .collection(collection)
      .doc(item.id)
      .update(item)
      .then((res) => {
        return res;
      });
  };

  setItem = (collection, id, item) => {
    return this.db
      .collection(collection)
      .doc(id)
      .set(item, { merge: true })
      .then((res) => {
        return res;
      });
  };

  disableItem = (collection, item) => {
    return this.db
      .collection(collection)
      .doc(item.id)
      .update({ ...item, active: false })
      .then((res) => {
        return res;
      });
  };

  getCollection = (collection, tenantId) => {
    return this.db
      .collection(collection)
      .where('tenantId', '==', tenantId)
      .get()
      .then((res) => {
        return res.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
      });
  };

  getDownloadUrl = async (path) => await this.storage.ref(path).getDownloadURL();

  subscribeToNotifications = async () => {
    const permission = await Notification.requestPermission();

    if (permission !== 'granted') {
      return;
    }
    try {
      const currentToken = await this.messaging.getToken();
      if (currentToken) {
        this.sendTokenToServer(currentToken);
      } else {
        this.setTokenSentToServer(false);
      }
    } catch (err) {
      this.setTokenSentToServer(false);
    }
  };

  sendTokenToServer = async (currentToken) => {
    if (this.isTokenSentToServer(currentToken)) {
      return;
    }
    await this.db.collection('userData').doc(this.auth.currentUser.uid).update({
      webNotificationToken: currentToken,
    });
    this.setTokenSentToServer(currentToken);
  };

  isTokenSentToServer = (currentToken) => {
    return window.localStorage.getItem('sentFirebaseMessagingToken') === currentToken;
  };

  setTokenSentToServer = (currentToken) => {
    window.localStorage.setItem('sentFirebaseMessagingToken', currentToken ? currentToken : '');
  };
}

const instance = new Firebase();

export default instance;
