import React from 'react';
import { connect } from 'react-redux';

import { TextField } from '@material-ui/core';
import MuiPhoneNumber from 'material-ui-phone-number';

const BusinessFields = (props) => {
  const {
    businessName,
    setBusinessName,
    TAXnumber,
    setTAXnumber,
    address,
    setAddress,
    phone,
    setPhone,
    email,
    setEmail,
    dataEmail,
    setDataEmail,
    dataEmail2,
    setDataEmail2,
  } = props;

  return (
    <div className="w-100 h-100 d-flex direction-column">
      <div className="w-100">
        <div className="drawer-title">Business Info</div>
        <div className="m-t-lg w-100">
          <TextField
            fullWidth
            label="Business Name"
            value={businessName}
            onChange={(e) => {
              setBusinessName(e.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="m-t-lg w-100">
          <TextField
            fullWidth
            label="TAX Number"
            value={TAXnumber}
            onChange={(e) => {
              setTAXnumber(e.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </div>
      <div className="w-100">
        <div className="drawer-title m-t-lg">Address</div>
        <div className="m-t-lg w-100">
          <TextField
            fullWidth
            label="Address Line"
            value={address}
            onChange={(e) => {
              setAddress(e.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </div>
      <div className="w-100">
        <div className="drawer-title m-t-lg">Contact</div>
        <div className="d-flex space-between w-100 m-t-lg">
          <div className="w-40">
            <TextField
              fullWidth
              label="Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className="w-40">
            <MuiPhoneNumber
              value={phone}
              onChange={(value) => setPhone(value)}
              fullWidth
              label="Phone number"
              placeholder="Your phone number"
              defaultCountry={'au'}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </div>
        <div className="w-40 m-t-lg">
          <TextField
            fullWidth
            label="Count Data Email"
            value={dataEmail}
            onChange={(e) => {
              setDataEmail(e.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="w-40 m-t-lg">
          <TextField
            fullWidth
            label="Count Data Email"
            value={dataEmail2}
            onChange={(e) => {
              setDataEmail2(e.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="w-100 m-t-md">
          <div
            style={{
              fontWeight: 'bold',
              fontSize: 12,
              color: '#a8afbe',
            }}
          >
            These are email addresses that all count data emails will be sent to
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    tenantData: state.tenant,
  };
};

export default connect(mapStateToProps)(BusinessFields);
