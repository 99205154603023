import React from 'react';
import TextField from '@material-ui/core/TextField';

import './locations-modal.scss';

const LocationDetails = (props) => {
  const {
    name,
    address,
    description,
    note,
    setNote,
    setName,
    setDescription,
    setAddress,
    postcode,
    region,
    setPostCode,
    setRegion,
  } = props;

  return (
    <div className="d-flex flex-1 direction-column">
      <div className="drawer-title">Location Details</div>
      <div className="location-details m-b-md">
        <div className="m-t-lg">
          <TextField
            value={name}
            onChange={(e) => setName(e.target.value)}
            id="team-name"
            fullWidth
            label="Location Name"
            placeholder="E.g Sydney Main Warehouse"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="m-t-lg">
          <TextField
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            id="description"
            fullWidth
            label="Description"
            placeholder="Enter description"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </div>
      <div className="drawer-title m-t-lg">Address</div>
      <div className="address-input m-t-lg m-b-md">
        <TextField
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          id="address"
          fullWidth
          label="Address"
          placeholder="Enter address"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <div className="region-postcode m-t-lg">
          <div className="w-40">
            <TextField
              value={region}
              onChange={(e) => setRegion(e.target.value)}
              fullWidth
              label="Region/State"
              placeholder="Enter address"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className="w-40">
            <TextField
              value={postcode}
              onChange={(e) => setPostCode(e.target.value)}
              fullWidth
              label="Zip/Postcode"
              placeholder="Enter address"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </div>
      </div>
      <div className="drawer-title m-t-lg">Location Note</div>
      <div className="location-note m-t-lg">
        <TextField
          value={note}
          onChange={(e) => setNote(e.target.value)}
          id="note"
          fullWidth
          label="Note"
          placeholder="Add note"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
    </div>
  );
};

export default LocationDetails;
