import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import '../../aside.scss';

const SettingsAside = (props) => {
  const { tenantData } = props;

  return (
    <div className="container d-flex">
      <div className="tabs-list">
        <div className="tab-container">
          <Link to={'/settings'} style={{ textDecoration: 'none' }}>
            <p
              style={
                props.location.pathname === '/settings'
                  ? {
                      color: tenantData.mainColor,
                      fontSize: 16,
                      fontWeight: 'bold',
                    }
                  : {
                      color: '#5b667d',
                      fontSize: 16,
                      fontWeight: 'bold',
                    }
              }
            >
              Settings
            </p>
          </Link>
        </div>
        <div className="tab-container">
          <Link to={'/settings/gtin-reference'} style={{ textDecoration: 'none' }}>
            <p
              style={
                props.location.pathname === '/settings/gtin-reference'
                  ? {
                      color: tenantData.mainColor,
                      fontSize: 16,
                      fontWeight: 'bold',
                    }
                  : {
                      color: '#5b667d',
                      fontSize: 16,
                      fontWeight: 'bold',
                    }
              }
            >
              GTIN Reference
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    tenantData: state.tenant,
  };
};

export default withRouter(connect(mapStateToProps)(SettingsAside));
