import React, { useState } from 'react';
import FieldEdit from './FieldEdit';
import FieldView from './FieldView';
import { IconButton, Radio } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const CustomBarcodeEdit = ({
  scanType,
  handleChange,
  fields,
  onEditField,
  addField,
  saveField,
  deleteField,
  index,
}) => {
  const [addNew, setAddNew] = useState(false);
  const cancelAddNew = () => setAddNew(false);

  return (
    <>
      <div className="drawer-title m-t-lg">Customize decoding (Scanning)</div>
      <div className="w-50 d-flex space-between">
        <div className="d-flex">
          <Radio
            checked={scanType === 'QR'}
            onChange={handleChange}
            color="primary"
            value="QR"
            name="radio-button-demo"
            inputProps={{ 'aria-label': 'QR' }}
          />
          <div className="table-text d-flex align-center">QR Code</div>
        </div>
        <div className="d-flex">
          <Radio
            checked={scanType === 'GTIN'}
            onChange={handleChange}
            value="GTIN"
            color="primary"
            name="radio-button-demo"
            inputProps={{ 'aria-label': 'GTIN' }}
          />
          <div className="table-text d-flex align-center">Data Matrix (GTIN)</div>
        </div>
      </div>

      {scanType === 'QR' ? (
        <>
          <div className="w-100 d-flex m-t-lg">
            <div className="flex-1 table-text">Field Title</div>
            <div className="flex-1 table-text">Section №</div>
            <div className="flex-1 table-text" />
          </div>
          <div className="w-100 m-t-md">
            {fields.map((item, index) =>
              item.edit ? (
                <FieldEdit
                  key={`${item}_${index}`}
                  deleteField={deleteField}
                  index={index}
                  saveField={saveField}
                  addField={addField}
                  {...item}
                />
              ) : (
                <FieldView
                  key={`${item}_${index}`}
                  deleteField={deleteField}
                  index={index}
                  onEdit={() => onEditField(index)}
                  {...item}
                />
              ),
            )}
            {addNew ? (
              <FieldEdit
                saveField={(index, data) => {
                  setAddNew(false);
                  addField(index, data);
                }}
                deleteField={cancelAddNew}
              />
            ) : (
              <IconButton onClick={() => setAddNew(true)}>
                <AddCircleOutlineIcon color="primary" />
              </IconButton>
            )}
          </div>
        </>
      ) : null}
    </>
  );
};

export default CustomBarcodeEdit;
