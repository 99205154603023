import React, { useState, useEffect } from 'react';
import { IconButton, TextField } from '@material-ui/core';
import { Check, Delete } from '@material-ui/icons';

const FieldEdit = (props) => {
  const { viewOnly, index, saveField, addField, deleteField } = props;
  const [title, setTitle] = useState('');
  const [section, setSection] = useState(null);

  useEffect(() => {
    if (props.section || props.section === 0) {
      setSection(props.section);
    }

    if (props.title) {
      setTitle(props.title);
    }
  }, [props.title, props.section]);

  const changeSection = (e) => setSection(e.target.value);
  const changeTitle = (e) => setTitle(e.target.value);
  const onSave = () => {
    const field = { title, section };
    saveField(index, field);
  };

  const onDelete = () => {
    deleteField(index);
  };

  return (
    <div className="w-100 d-flex m-t-sm">
      <div className="flex-1 table-black-text">
        <div className="w-50">
          <TextField onChange={changeTitle} value={title} />
        </div>
      </div>
      <div className="flex-1 table-black-text">
        <div className="w-50">
          <TextField onChange={changeSection} value={section} type="number" />
        </div>
      </div>
      {viewOnly ? null : (
        <div className="flex-1 d-flex">
          <IconButton onClick={onSave}>
            <Check color="primary" />
          </IconButton>
          <IconButton onClick={onDelete}>
            <Delete style={{ color: '#d32f2f' }} />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default FieldEdit;
