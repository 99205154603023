import firebase from '../firebase/Firebase';
import { GET_COUNTRIES_DATA } from './actionTypes';
import { COUNTRIES_DATA_COLLECTION } from '../firebase/collections';

export const getCountries = () => async (dispatch) => {
  return firebase.db
    .collection(COUNTRIES_DATA_COLLECTION)
    .get()
    .then((res) => {
      dispatch({
        type: GET_COUNTRIES_DATA,
        value: res.docs.map((country) => country.data()),
      });
    });
};
