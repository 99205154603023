import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { removeTeam } from '../../../../actions/teamsCollectionActions';

import TeamModal from './team-modal/TeamModal';

import {
  makeStyles,
  Select,
  MenuItem,
  IconButton,
  InputAdornment,
  OutlinedInput,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import StyledButton from '../../../shared/Button';
import TeamsTable from './teams-table/TeamsTable';

import '../../manager.scss';
import { searchFilter } from '../../../shared/sharedFunctions';
import SearchIcon from '@material-ui/icons/Search';
import { displayFullName } from '../../../../util/helpers';

const TeamsManager = (props) => {
  const { userData, usersCollection, tenantData, teamsData } = props;

  const useStyles = makeStyles({
    searchInput: {
      margin: 0,
    },
    drawer: {
      width: '100%',
    },
    tab: {
      marginTop: 20,
      marginBottom: 20,
      borderBottom: '1px solid #E9E9E9',
      padding: 0,
    },
    tabItem: {
      width: 'max-content !important',
      display: 'flex',
      padding: '0 !important',
      justifyContent: 'flex-start !important',
    },
    inputFilterText: {
      fontSize: 16,
      fontWeight: 'bold',
      color: tenantData.mainColor,
      textAlign: 'left',
      border: 'none',
      width: 150,
    },
    inputFilterIcon: {
      fontWeight: 'bold',
      color: tenantData.mainColor,
      border: 'none',
    },
  });

  const classes = useStyles();

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [modalEditMode, setModalEditMode] = useState(false);
  const [modalEditTeam, setModalEditTeam] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [searchVisible, setSearchVisible] = useState(false);

  const [openName, setOpenName] = useState(false);
  const [descriptionOpen, setDescriptionOpen] = useState(false);

  const [nameFilter, setNameFilter] = useState('All');
  const [descriptionFilter, setDescriptionFilter] = useState('All');

  const nameFilterHandleChange = (e) => {
    setNameFilter(e.target.value);
  };

  const nameFilterHandleOpen = () => {
    setOpenName(true);
  };

  const nameFilterHandleClose = () => {
    setOpenName(false);
  };

  const descriptionFilterHandleChange = (e) => {
    setDescriptionFilter(e.target.value);
  };

  const descriptionFilterHandleOpen = () => {
    setDescriptionOpen(true);
  };

  const descriptionFilterHandleClose = () => {
    setDescriptionOpen(false);
  };

  const tabValueHandleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const openDrawer = () => {
    setDrawerIsOpen(true);
  };

  const closeDrawer = () => {
    setDrawerIsOpen(false);
    setModalEditMode(false);
  };

  const getTeamData = (id) => {
    return teamsData.find((team) => id === team.id);
  };

  const getNameOptions = (collection) => {
    const result = [];

    collection.forEach((item) => {
      if (!result.includes(item.name)) {
        result.push(item.name);
      }
    });
    return result;
  };

  const getDescriptionOptions = (collection) => {
    const result = [];

    collection.forEach((item) => {
      if (!result.includes(item.description)) {
        result.push(item.description);
      }
    });
    return result;
  };

  const getFilteredCollection = (collection) => {
    if (nameFilter === 'All' && descriptionFilter === 'All') {
      return collection;
    } else if (nameFilter === 'All' && descriptionFilter !== 'All') {
      return collection.filter((item) => item.description === descriptionFilter);
    } else if (nameFilter !== 'All' && descriptionFilter === 'All') {
      return collection.filter((item) => item.name === nameFilter);
    } else if (nameFilter !== 'All' && descriptionFilter !== 'All') {
      return collection.filter((item) => item.description === descriptionFilter && item.name === nameFilter);
    }
  };

  return (
    <Fragment>
      <div className="manager-container">
        <div className="manager-head">
          <div className="page-title-container">
            <h3 className="page-title">Teams</h3>
            <p className="count">{`(${teamsData.filter((item) => item.active === true).length})`}</p>
          </div>
          <div className="search-container">
            <div className="search-input d-flex align-start flex-center">
              {searchVisible ? (
                <div className="search-input-container">
                  <FormControl size="small" variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Search...</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      value={searchValue}
                      type="text"
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <div
                            className="h-100 d-flex align-center cursor-pointer"
                            onClick={() => {
                              setSearchValue('');
                              setSearchVisible(false);
                            }}
                          >
                            <SearchIcon />
                          </div>
                        </InputAdornment>
                      }
                      labelWidth={70}
                    />
                  </FormControl>
                </div>
              ) : (
                <IconButton
                  onClick={() => {
                    setSearchVisible(true);
                  }}
                >
                  <SearchIcon />
                </IconButton>
              )}
            </div>
          </div>
          <div>
            <StyledButton onClick={openDrawer} text={'Add Team'} />
          </div>
        </div>
        <div className="table-switch">
          <Paper elevation={0}>
            <Tabs
              value={tabValue}
              indicatorColor="primary"
              textColor="primary"
              className={classes.tab}
              onChange={tabValueHandleChange}
            >
              <Tab classes={{ wrapper: classes.tabItem }} className={classes.tabItem} label="Teams" />
            </Tabs>
          </Paper>
        </div>
        <div className="table-filters">
          <div className="filter-container">
            <p className="tab-text m-0 d-flex flex-end align-center filter-name">Name: </p>
            <Select
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
              }}
              classes={{ root: classes.inputFilterText, icon: classes.inputFilterIcon }}
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              open={openName}
              onClose={nameFilterHandleClose}
              onOpen={nameFilterHandleOpen}
              value={nameFilter}
              onChange={nameFilterHandleChange}
            >
              <MenuItem value="All">All</MenuItem>
              {getNameOptions(props.teamsData.filter((item) => item.active === true)).map((item, index) => (
                <MenuItem key={`${item}-${index}`} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="filter-container">
            <p className="tab-text d-flex flex-end align-center filter-name">Description: </p>
            <Select
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
              }}
              classes={{ root: classes.inputFilterText, icon: classes.inputFilterIcon }}
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              open={descriptionOpen}
              onClose={descriptionFilterHandleClose}
              onOpen={descriptionFilterHandleOpen}
              value={descriptionFilter}
              onChange={descriptionFilterHandleChange}
            >
              <MenuItem value="All">All</MenuItem>
              {getDescriptionOptions(props.teamsData.filter((item) => item.active === true)).map((item, index) => (
                <MenuItem key={`${item}-${index}`} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
        <div className="table flex-1">
          <TeamsTable
            teamsData={teamsData}
            usersCollection={usersCollection}
            removeTeam={props.removeTeam}
            rows={getFilteredCollection(
              searchFilter(
                props.teamsData.filter((item) => item.active === true),
                searchValue,
              ),
            )}
            getTeamData={getTeamData}
            setModalEditMode={setModalEditMode}
            setModalEditTeam={setModalEditTeam}
            openDrawer={openDrawer}
            tenantId={userData.tenantId}
          />
        </div>
      </div>
      <TeamModal
        editMode={modalEditMode}
        editTeam={modalEditTeam}
        teamsData={teamsData}
        usersCollection={usersCollection}
        drawerIsOpen={drawerIsOpen}
        closeDrawer={closeDrawer}
        openDrawer={openDrawer}
        userName={displayFullName(userData.fullName)}
      />
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      removeTeam,
    },
    dispatch,
  );

const mapStateToProps = (state) => {
  return {
    usersCollection: state.usersCollection,
    teamsData: state.teams,
    tenantData: state.tenant,
    userData: state.user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamsManager);
