import user from './user';
import auth from './auth';
import tenant from './tenant';
import countries from './countries';
import usersCollection from './usersCollection';
import teams from './teams';
import locations from './locations';
import subLocations from './subLocations';
import schedules from './schedules';
import counts from './counts';
import date from './date';
import gtin from './gtin';

export default {
  user,
  auth,
  tenant,
  countries,
  usersCollection,
  teams,
  locations,
  subLocations,
  schedules,
  counts,
  date,
  gtin,
};
