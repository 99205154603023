export const USER_DATA_COLLECTION = 'userData';
export const COUNTRIES_DATA_COLLECTION = 'countriesData';
export const TENANT_DATA_COLLECTION = 'tenantData';
export const TEAM_DATA_COLLECTION = 'teamData';
export const LOCATION_DATA_COLLECTION = 'locationData';
export const COUNTS_COLLECTION = 'counts';
export const SCHEDULE_DATA_COLLECTION = 'scheduleData';
export const SUB_LOCATION_DATA_COLLECTION = 'subLocationData';

export const GTIN_REFERANCE_COLLECTION = 'gtinReference';
