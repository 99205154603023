import { makeStyles, Toolbar, Tooltip, Typography, IconButton } from '@material-ui/core';
import remove from '../../../../../assets/icons/delete.svg';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    minHeight: 50,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(3),
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.main,
  },
  title: {
    flex: '1 1 100%',
  },
}));

export const GtinToolbarTable = ({ numSelected, removeSelectedReference }) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar className={classes.root}>
      {numSelected > 0 && (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      )}
      {numSelected > 0 && (
        <Tooltip title="Delete selected">
          <IconButton aria-label="delete" onClick={removeSelectedReference}>
            <img src={remove} alt="delete" />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};
