import React from 'react';

import { connect } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';

import './schedules-modal.scss';

const Scheduling = (props) => {
  const { frequency, duration, singleCountOnly, setFrequency, setDuration, setSingleCountOnly, tenantData } = props;

  const useStyles = makeStyles({
    label: {
      fontSize: 14,
      fontWeight: 'bold',
      color: '#8b95a8',
    },
    activeLabel: {
      fontSize: 14,
      fontWeight: 'bold',
      color: tenantData.mainColor,
    },
    radioButton: {
      width: '48%',
      height: '40%',
    },
    radioGroup: {
      flexDirection: 'row',
      paddingTop: 15,
      justifyContent: 'space-between',
    },
    radioLabel: {
      color: tenantData.mainColor,
    },
  });

  const frequencyHandleChange = (e) => {
    setFrequency(e.target.value);
  };

  const durationHandleChange = (e) => {
    setDuration(e.target.value);
  };

  const singleCountHandleChange = (e) => {
    setSingleCountOnly(e.target.checked);
    if (e.target.checked === true) {
      setDuration('');
      setFrequency('');
    }
  };

  const BlueRadio = withStyles({
    root: {
      color: '#8b95a8',
      '&$checked': {
        color: tenantData.mainColor,
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

  const classes = useStyles();

  return (
    <div className="d-flex flex-1 direction-column">
      <div className="d-flex space-between align-center label">
        <div className="drawer-title">Frequency</div>
        <FormControlLabel
          value="start"
          classes={{ label: classes.label }}
          control={<Switch onChange={singleCountHandleChange} checked={singleCountOnly} color="primary" />}
          label="Single count only (don`t schedule)"
          labelPlacement="start"
        />
      </div>
      <FormControl component="fieldset">
        <RadioGroup
          className={classes.radioGroup}
          aria-label="frequency"
          name="frequency"
          value={frequency}
          onChange={frequencyHandleChange}
        >
          <FormControlLabel
            classes={{ label: frequency === 'Monthly' ? classes.activeLabel : classes.label }}
            className={classes.radioButton}
            color="primary"
            value="Monthly"
            disabled={singleCountOnly}
            control={<BlueRadio />}
            label="Monthly"
          />
          <FormControlLabel
            classes={{ label: frequency === 'Half Yearly' ? classes.activeLabel : classes.label }}
            className={classes.radioButton}
            color="primary"
            value="Half Yearly"
            disabled={singleCountOnly}
            control={<BlueRadio />}
            label="Half Yearly"
          />
          <FormControlLabel
            classes={{ label: frequency === 'Quarterly' ? classes.activeLabel : classes.label }}
            className={classes.radioButton}
            color="primary"
            value="Quarterly"
            disabled={singleCountOnly}
            control={<BlueRadio />}
            label="Quarterly"
          />
          <FormControlLabel
            classes={{ label: frequency === 'Annually' ? classes.activeLabel : classes.label }}
            className={classes.radioButton}
            color="primary"
            value="Annually"
            disabled={singleCountOnly}
            control={<BlueRadio />}
            label="Annually"
          />
        </RadioGroup>
      </FormControl>
      <div className="d-flex align-center label">
        <div className="drawer-title">Duration</div>
      </div>
      <FormControl component="duration">
        <RadioGroup
          classes={{ label: classes.label }}
          className={classes.radioGroup}
          aria-label="duration"
          name="duration"
          value={duration}
          onChange={durationHandleChange}
        >
          <FormControlLabel
            classes={{ label: duration === 'One Year' ? classes.activeLabel : classes.label }}
            color="primary"
            value="One Year"
            disabled={singleCountOnly}
            control={<BlueRadio />}
            label="One Year"
          />
          <FormControlLabel
            classes={{ label: duration === 'Two Years' ? classes.activeLabel : classes.label }}
            color="primary"
            value="Two Years"
            disabled={singleCountOnly}
            control={<BlueRadio />}
            label="Two Years"
          />
          <FormControlLabel
            classes={{ label: duration === 'Three Years' ? classes.activeLabel : classes.label }}
            color="primary"
            value="Three Years"
            disabled={singleCountOnly}
            control={<BlueRadio />}
            label="Three Years"
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    tenantData: state.tenant,
  };
};

export default connect(mapStateToProps)(Scheduling);
