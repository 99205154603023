import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AuthNavigation from './components/navigation/auth-navigation/Auth';
import AppNavigation from './components/navigation/app-navigation/AppNavigation';
import { getUser } from './actions/userActions';
import { getCountries } from './actions/countriesActions';
import { getUsersCollection } from './actions/usersCollectionActions';
import { getTeams } from './actions/teamsCollectionActions';
import { getLocations } from './actions/locationsCollectionActions';
import { getSubLocations } from './actions/subLocationsCollectionActions';
import { getSchedules } from './actions/schedulesActions';
import { getCounts, createCountsScript } from './actions/countsActions';
import { getGtinReference } from './actions/gtinActions';
import theme from './styles/theme';
import { ThemeProvider } from '@material-ui/core/styles';
import staticTheme from './styles/staticTheme';
import LoadScreen from './components/load-screen/LoadScreen';

const App = (props) => {
  const {
    isLoadedUser,
    user,
    tenant,
    getUser,
    getCountries,
    getUsersCollection,
    getTeams,
    getLocations,
    getSubLocations,
    getSchedules,
    getCounts,
    getGtinReference,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [loadedData, setLoadedData] = useState([]);
  const [animationFade, setAnimationFade] = useState(true);

  const dataGetFunctions = [getCounts, getUsersCollection, getTeams, getLocations, getSubLocations, getSchedules];

  useEffect(() => {
    startLoading();
    getCountries().then(async () => {
      await getUser().then(async () => {
        if (user.id) {
          // createCountsScript()
          const promises = dataGetFunctions.map(
            async (func, index) =>
              await func(props.user.tenantId).then((res) => {
                addStep(res ? res : 'get');
              }),
          );
          promises.push(getGtinReference(tenant.docId));
          await Promise.all(promises).finally(stopLoading);
        } else if (!user.id && isLoadedUser) stopLoading();
      });
    });
  }, [user.id, isLoadedUser, tenant.docId]);

  const addStep = (index) => {
    const oldStateCopy = [...loadedData];

    oldStateCopy.push(index);
    setLoadedData(oldStateCopy);
  };

  const stopLoading = () => {
    setAnimationFade(false);
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  };
  const startLoading = () => {
    setIsLoading(true);
  };

  const routes = (id) => (id ? <AppNavigation /> : <AuthNavigation />);
  const handleLoading = useCallback(() => (isLoading ? <LoadScreen /> : routes(user.id)), [isLoading]);

  return (
    <ThemeProvider theme={tenant.mainColor ? theme(tenant.mainColor) : staticTheme}>{handleLoading()}</ThemeProvider>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUser,
      getCountries,
      getUsersCollection,
      getTeams,
      getLocations,
      getSubLocations,
      getSchedules,
      getCounts,
      createCountsScript,
      getGtinReference,
    },
    dispatch,
  );

const mapStateToProps = (state) => {
  return {
    counts: state.user.isLoaded.list,
    user: state.user,
    tenant: state.tenant,
    isLoadedUser: state.user.isLoaded,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
