import React, { Fragment, useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSchedule } from '../../../../../actions/schedulesActions';
import { createCount } from '../../../../../actions/countsActions';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import CountLocations from './count-locations/CountLocations';
import CountInfo from './count-info/CountInfo';
import CountSchedule from './count-schedule/CountSchedule';
import CountTeams from './count-teams/CountTeams';

import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import CustomButton from '../../../../shared/CustomButton';
import LoadScreen from '../../../../load-screen/LoadScreen';
import CancelButton from '../../../../shared/CancelButton';
import Alert from '../../../../shared/Alert';

import './new-count-modal.scss';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    background: '#f9f9f9',
    width: 800,
    height: 700,
    borderRadius: 12,
  },
}));

const NewCountModal = (props) => {
  const classes = useStyles();
  const { open, handleClose, tenantData, locationsData, createSchedule, createCount, user } = props;

  const [stepTabValue, setStepTabValue] = useState(0);
  const [name, setName] = useState('');
  const [date, setDate] = useState(new Date());
  const [location, setLocation] = useState('');
  const [subLocations, setSubLocations] = useState([]);
  const [teams, setTeams] = useState([]);
  const [users, setUsers] = useState([]);
  const [frequency, setFrequency] = useState('');
  const [duration, setDuration] = useState('');
  const [singleCountOnly, setSingleCountOnly] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorType, setErrorType] = useState('error');
  const [errorText, setErrorText] = useState('');

  const addUser = (user) => {
    if (!user || users.includes(user.id)) {
      return;
    }
    setUsers([...users, user.id]);
  };

  const addTeam = (team) => {
    if (!team || teams.includes(team.id)) {
      return;
    }
    setTeams([...teams, team.id]);
  };

  const removeUser = (id) => {
    setUsers(users.filter((item) => item !== id));
  };

  const removeTeam = (id) => {
    setTeams(teams.filter((item) => item !== id));
  };

  const addSubLocation = (subLocation) => {
    const subLocationsId = subLocation.filter((item) => !subLocations.includes(item.id)).map((item) => item.id);

    setSubLocations([...subLocations, ...subLocationsId]);
  };

  const removeSubLocation = (id) => {
    setSubLocations(subLocations.filter((item) => item !== id));
  };

  const generateCustomCountId = () => {
    return `${location.slice(0, 2)}${Date.now()}`.toUpperCase();
  };

  const resetSubLocations = () => {
    setSubLocations([]);
  };

  const handleNextDisable = () => {
    if (stepTabValue === 0 && name && date) {
      return false;
    } else if (stepTabValue === 1 && location) {
      return false;
    } else if (stepTabValue === 2 && (!!users.length || !!teams.length)) {
      return false;
    } else if (stepTabValue === 3) {
      return true;
    } else {
      return true;
    }
  };

  const onSubmit = () => {
    if (user.role === 'Operative') {
      setErrorText('Permission Denied');
      handleClose();
      setErrorType('error');
      setError(true);
      return;
    }

    setIsLoading(true);
    if (singleCountOnly) {
      createCount(collectCountData(), generateCustomCountId()).then(() => {
        handleClose();
        setIsLoading(false);
        nullifyState();
      });
    } else {
      createSchedule(collectData()).then((schedule) => {
        createCount({ ...collectCountData(), schedule }, generateCustomCountId()).then(() => {
          handleClose();
          setIsLoading(false);
          nullifyState();
        });
      });
    }
  };

  const collectData = () => {
    return {
      name,
      date: new Date(date),
      duration,
      frequency,
      singleCountOnly,
      teams,
      location: locationsData.find((item) => location === item.name).id,
      users,
      subLocations,
      lastCreatedCount: date,
      tenantId: tenantData.id,
    };
  };

  const collectCountData = () => {
    return {
      name,
      date,
      location: locationsData.find((item) => location === item.name).id,
      users,
      teams,
      subLocations,
      tenantId: tenantData.id,
      schedule: null,
    };
  };

  const nullifyState = () => {
    setName('');
    setDate(new Date());
    setLocation('');
    setSubLocations([]);
    setUsers([]);
    setTeams([]);
    setDuration('');
    setFrequency('');
    setSingleCountOnly(false);
    setStepTabValue(0);
  };

  const componentHandler = () => {
    switch (stepTabValue) {
      case 0:
        return <CountInfo name={name} date={date} setName={setName} setDate={setDate} />;
      case 1:
        return (
          <CountLocations
            location={location}
            setLocation={setLocation}
            subLocations={subLocations}
            addSubLocation={addSubLocation}
            removeSubLocation={removeSubLocation}
            resetSubLocations={resetSubLocations}
          />
        );
      case 2:
        return (
          <CountTeams
            users={users}
            teams={teams}
            addUser={addUser}
            addTeam={addTeam}
            removeUser={removeUser}
            removeTeam={removeTeam}
          />
        );
      case 3:
        return (
          <CountSchedule
            frequency={frequency}
            duration={duration}
            setFrequency={setFrequency}
            setDuration={setDuration}
            singleCountOnly={singleCountOnly}
            setSingleCountOnly={setSingleCountOnly}
          />
        );
      default:
        return <CountInfo name={name} date={date} setName={setName} setDate={setDate} />;
    }
  };

  return (
    <Fragment>
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <div className="modal-header" style={{ background: tenantData.mainColor }}>
                <div className="header-title">
                  <div />
                  <div className="new-count">New Count</div>
                  <div
                    className="close cursor-pointer"
                    onClick={(e) => {
                      handleClose();
                      nullifyState();
                    }}
                  >
                    <CloseIcon />
                  </div>
                </div>
                <div className="create-steps-tabs">
                  <div className="step-tab-item">
                    <div className="step-item">
                      {name && date ? (
                        <div className="step-circle">
                          <CheckCircleIcon color="secondary" fontSize="small" />
                        </div>
                      ) : (
                        <div className={stepTabValue === 0 ? 'step-circle-active' : 'step-circle'} />
                      )}
                    </div>
                    <div className="step-name">Count Info</div>
                  </div>
                  <div className="step-tab-item">
                    <div className="step-tab-item">
                      <div className="step-item">
                        {location ? (
                          <div className="step-circle">
                            <CheckCircleIcon color="secondary" fontSize="small" />
                          </div>
                        ) : (
                          <div className={stepTabValue === 1 ? 'step-circle-active' : 'step-circle'} />
                        )}
                      </div>
                      <div className="step-name">Locations</div>
                    </div>
                  </div>
                  <div className="step-tab-item">
                    <div className="step-tab-item">
                      <div className="step-item">
                        {!!users.length || !!teams.length ? (
                          <div className="step-circle">
                            <CheckCircleIcon color="secondary" fontSize="small" />
                          </div>
                        ) : (
                          <div className={stepTabValue === 2 ? 'step-circle-active' : 'step-circle'} />
                        )}
                      </div>
                      <div className="step-name">Users & Teams</div>
                    </div>
                  </div>
                  <div className="step-tab-item">
                    <div className="step-tab-item">
                      <div className="step-item">
                        {singleCountOnly || (duration && frequency) ? (
                          <div className="step-circle">
                            <CheckCircleIcon color="secondary" fontSize="small" />
                          </div>
                        ) : (
                          <div className={stepTabValue === 3 ? 'step-circle-active' : 'step-circle'} />
                        )}
                      </div>
                      <div className="step-name">Schedule</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <div className="hr1" />
                <div className="hr2" />
                <div className="hr3" />
                {componentHandler()}
              </div>
              <div className="modal-footer">
                <div className="cancel-button">
                  <CancelButton
                    onClick={() => {
                      handleClose();
                      nullifyState();
                    }}
                  />
                </div>
                <div className="previous-next">
                  <CustomButton
                    disabled={stepTabValue === 0}
                    onClick={(e) => {
                      setStepTabValue(stepTabValue - 1);
                    }}
                    color={'#f5f7fb'}
                    width={150}
                    textColor={'#8a94a7'}
                    text="Previous"
                  />
                  {location &&
                  name &&
                  date &&
                  (!!users.length || !!teams.length) &&
                  (singleCountOnly || (frequency && duration)) &&
                  stepTabValue === 3 ? (
                    <CustomButton
                      onClick={onSubmit}
                      color={tenantData.mainColor}
                      width={150}
                      textColor={'#ffffff'}
                      text="Submit"
                    />
                  ) : (
                    <CustomButton
                      disabled={handleNextDisable()}
                      onClick={(e) => {
                        setStepTabValue(stepTabValue + 1);
                      }}
                      color={tenantData.mainColor}
                      width={150}
                      textColor={'#ffffff'}
                      text="Next"
                    />
                  )}
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
      {isLoading ? <LoadScreen /> : null}
      <Alert variant={errorType} message={errorText} open={error} onClose={() => setError(false)} />
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ createSchedule, createCount }, dispatch);

const mapStateToProps = (state) => {
  return {
    locationsData: state.locations,
    tenantData: state.tenant,
    user: state.user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewCountModal);
