import React from 'react';

import Avatar from 'react-avatar';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import MuiPhoneNumber from 'material-ui-phone-number';

import './create-user-modal.scss';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  radioGroup: {
    width: '75%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  formControl: {
    width: '100%',
  },
});

const UserDetails = (props) => {
  const {
    firstName,
    lastName,
    position,
    email,
    phone,
    role,
    description,
    setFirstName,
    setLastName,
    setPosition,
    setEmail,
    setPhone,
    setRole,
    setDescription,
    photo,
  } = props;

  const classes = useStyles();

  return (
    <div className="user-details-container">
      <div className="d-flex flex-1 direction-column w-100 h-100">
        <div className="drawer-title">Personal</div>
        <div className="personal-data-container m-t-lg m-b-lg">
          <div className="profile-photo">
            <Avatar src={photo ? photo : ''} name={`${firstName} ${lastName}`} size={120} round />
          </div>
          <div className="personal-data-input">
            <div className="name-container">
              <div className="first-name-input">
                <TextField
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  id="first-name"
                  fullWidth
                  label="First name (required)"
                  placeholder="E.g John"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="last-name-input">
                <TextField
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                  id="last-name"
                  fullWidth
                  label="Last name (required)"
                  placeholder="E.g Doe"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </div>
            <div className="position-container">
              <TextField
                value={position}
                onChange={(e) => setPosition(e.target.value)}
                fullWidth
                id="position"
                label="Position"
                placeholder="E.g Manager"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>
        </div>
        <div className="drawer-title m-t-lg">Contact info</div>
        <div className="contact-data-container m-t-lg m-b-md">
          <div className="email-input">
            <TextField
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              id="position"
              fullWidth
              label="Email (required)"
              placeholder="E.g example@gmail.com"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className="phone-input">
            <MuiPhoneNumber
              value={phone}
              onChange={(value) => setPhone(value)}
              fullWidth
              label="Phone number"
              placeholder="Your phone number"
              defaultCountry={'au'}
            />
          </div>
        </div>
        <div className="drawer-title m-t-lg">Role</div>
        <div className="role m-b-md">
          <FormControl className={classes.formControl} component="fieldset">
            <RadioGroup
              className={classes.radioGroup}
              value={role}
              onChange={(e) => setRole(e.target.value)}
              aria-label="position"
              name="position"
            >
              <FormControlLabel value="Admin" control={<Radio color="primary" />} label="Admin" labelPlacement="end" />
              <FormControlLabel
                value="Manager"
                control={<Radio color="primary" />}
                label="Manager"
                labelPlacement="end"
              />
              <FormControlLabel
                value="Operative"
                control={<Radio color="primary" />}
                label="Operative"
                labelPlacement="end"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div className="description-input">
          <div className="drawer-title">User description</div>
          <TextField
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            id="position"
            fullWidth
            label="Description"
            placeholder="Enter description"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
