import React from 'react';
import PropTypes from 'prop-types';

import Hidden from '@material-ui/core/Hidden';

import './tems-accept.scss';

const TermsAccept = (props) => {
  return (
    <Hidden smDown>
      <div className="d-flex w-100 flex-center align-end flex-1">
        <div className="terms-text-container">
          <p className="terms-text">© 2020 Opologic Pty Ltd. All Rights reserved.</p>
          <p className="terms-text">Privacy Policy | Terms of Service</p>
        </div>
      </div>
    </Hidden>
  );
};

TermsAccept.propTypes = {
  submitText: PropTypes.string,
};

export default TermsAccept;
