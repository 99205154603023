import React from 'react';
import { TableBody, TableCell, TableRow, Checkbox } from '@material-ui/core';

import { getComparator, stableSort } from '../../../../util/helpers';

import { formatDate } from '../../../shared/sharedFunctions';

export const OverdueTableBody = (props) => {
  const { classes, rows, page, order, orderBy, rowsPerPage, isSelected, handleRowSelect } = props;

  return (
    <TableBody classes={{ root: classes.tableBody }}>
      {stableSort(rows, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, index) => {
          const isItemSelected = isSelected(row.id);
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <TableRow
              hover
              onClick={(event) => handleRowSelect(event, row.id)}
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={row.id}
              selected={isItemSelected}
            >
              <TableCell padding="checkbox">
                <Checkbox
                  className={classes.checked}
                  checked={isItemSelected}
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </TableCell>
              <TableCell component="th" id={labelId} scope="row">
                <p className="table-black-text m-0">{row.location.name}</p>
              </TableCell>
              <TableCell align="left">
                <p className="table-black-text m-0">{row.id}</p>
              </TableCell>
              <TableCell align="left">
                <p className="table-black-text m-0">{formatDate('DD/MM/YYYY', row.date)}</p>
              </TableCell>
              <TableCell align="left">
                <p className="table-black-text m-0">
                  {row.users
                    .map((user) => user.fullName)
                    .join(', ')
                    .replaceAll('_', ' ')}
                </p>
              </TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  );
};
