import React, { useState } from 'react';

import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import ref from '../../../../../assets/icons/ref_icon.svg';
import lot from '../../../../../assets/icons/lot_icon.svg';
import code from '../../../../../assets/icons/code_icon.svg';

import './counts-modal.scss';

const CountItems = (props) => {
  const [activeSubLocation, setActiveSubLocation] = useState('');

  const { products, subLocations, countSubLocations, scans, tenant } = props;

  const getTotalProductsQuantity = () => {
    return products.reduce((sum, item) => {
      return sum + item.quantity;
    }, 0);
  };

  const getItemByName = (collection, name) => {
    return collection.find((item) => item.name === name);
  };

  const getSubLocationItems = () => {
    if (getItemByName(subLocations, activeSubLocation)) {
      const subLocation = getItemByName(subLocations, activeSubLocation);

      for (let key in scans) {
        if (key === subLocation.id) {
          return scans[key].products;
        }
      }
    } else {
      const items = [];
      for (let key in scans) {
        scans[key].products.forEach((item) => {
          items.push(item);
        });
      }
      return items;
    }
  };

  return (
    <div className="w-100 h-100 p-t-lg">
      <div className="assign-items-container">
        <div className="total-count-items">
          <div className="d-flex space-between align-center p-md">
            <div className="d-flex space-between h-100 align-center">
              <div style={{ fontWeight: 'bold', fontSize: 16, color: tenant.mainColor }}>{products.length}</div>
              <div className="drawer-title-black m-l-md">Products</div>
            </div>
            <div className="d-flex space-between h-100 align-center">
              <div className="drawer-title-black">Total</div>
              <div
                style={{
                  fontWeight: 'bold',
                  fontSize: 16,
                  color: tenant.mainColor,
                  marginLeft: 5,
                }}
              >
                {getTotalProductsQuantity()}
              </div>
            </div>
          </div>
        </div>
        <div className="sub-location-select m-t-lg">
          <Autocomplete
            options={countSubLocations.map((item) => subLocations.find((subLocation) => item === subLocation.id).name)}
            onChange={(e, v) => {
              setActiveSubLocation(v);
            }}
            value={activeSubLocation}
            id="sub-locations"
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Sub-Location"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </div>
        <div className="items-list">
          {getSubLocationItems()
            ? getSubLocationItems().map((item) => {
                return (
                  <div className="scan-item align-center">
                    {item.barcode ? (
                      <div className="d-flex">
                        <img src={code} alt="ref" style={{ height: 24, width: 24 }} />
                        <div className="table-text m-l-lg">{item.barcode}</div>
                      </div>
                    ) : (
                      <div className="item-ref-lot">
                        <div className="ref">
                          <img src={ref} alt="ref" style={{ height: 24, width: 24 }} />
                          <div className="table-text m-l-lg">{item.ref}</div>
                        </div>
                        <div className="lot">
                          <img src={lot} alt="lot" style={{ height: 24, width: 24 }} />
                          <div className="table-text m-l-lg">{item.lot}</div>
                        </div>
                      </div>
                    )}
                    <div className="item-quantity">
                      <div
                        style={{
                          fontWeight: 'bold',
                          fontSize: 16,
                          color: tenant.mainColor,
                          textAlign: 'center',
                        }}
                      >
                        {item.quantity}
                      </div>
                      <div className="table-text align-text-center">QTY</div>
                    </div>
                  </div>
                );
              })
            : ''}
        </div>
      </div>
    </div>
  );
};

export default CountItems;
