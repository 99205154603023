import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '*': {
          'scrollbar-width': 'thin',
        },
        '*::-webkit-scrollbar': {
          width: '4px',
          height: '4px',
        },
      },
    },
    MuiTextField: {
      root: {},
    },
    MuiPickersTimePickerToolbar: {
      hourMinuteLabel: {
        alignItems: 'flex-start',
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: '#e9e9e9',
        borderRadius: 21,
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: '1px solid #E9E9E9',
        },
      },
    },
    MuiTab: {
      wrapper: {
        textTransform: 'none',
        fontWeight: 'bold',
        fontSize: '16px !important',
      },
    },
    MuiButtonBase: {
      label: {
        textTransform: 'none',
        fontWeight: 'bold',
        fontSize: 14,
      },
    },
    MuiButton: {
      root: {
        height: 35,
        width: 150,
      },
      label: {
        textTransform: 'none',
        fontWeight: 'bold',
        fontSize: 14,
      },
    },
  },
  palette: {
    primary: {
      main: '#00AAEF',
      light: '#00AAEF',
      dark: '#00AAEF',
      contrastText: '#ffffff',
      danger: '#d32f2f',
    },
    secondary: {
      main: '#e9e9e9',
      light: '#f2f2f2',
      dark: '#333333',
      contrastText: '#ffffff',
      danger: '#d32f2f',
    },
  },
  button: {
    paddingLeft: 20,
    paddingRight: 20,
  },
});

export default theme;
