import React from 'react';

import { Hidden } from '@material-ui/core';

import Login from '../../../pages/auth/sign-in/SignIn';
import SignUp from '../../../pages/auth/sign-up/SignUp';
import ResetPassword from '../../../pages/auth/reset-password/ResetPassword';
import Business from '../../../pages/auth/business/Business';

import BusinessAside from '../../auth/business-aside/BusinessAside';
import LoginAside from '../../auth/login-aside/LoginAside';

import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import './auth-main.scss';

const AuthNavigation = (props) => {
  return (
    <Router>
      <Hidden smDown>
        <div className="login-page">
          <div className="w-40">
            <Switch>
              <Route path="/" exact component={LoginAside} />
              <Route path="/password-reset" component={LoginAside} />
              <Route path="/sign-up" component={LoginAside} />
              <Route path="/business" component={BusinessAside} />
              <Redirect to={'/'} />
            </Switch>
          </div>
          <Switch>
            <Route path="/" exact component={Login} />
            <Route path="/password-reset" component={ResetPassword} />
            <Route path="/sign-up" component={SignUp} />
            <Route path="/business" component={Business} />
            <Redirect to={'/'} />
          </Switch>
        </div>
      </Hidden>
      <Hidden smUp>
        <div className="login-page">
          <Switch>
            <Route path="/" exact component={Login} />
            <Route path="/password-reset" component={ResetPassword} />
            <Route path="/sign-up" component={SignUp} />
            <Route path="/business" component={Business} />
            <Redirect to={'/'} />
          </Switch>
        </div>
        <div className="w-100 h-40">
          <Switch>
            <Route path="/" exact component={LoginAside} />
            <Route path="/password-reset" component={LoginAside} />
            <Route path="/sign-up" component={LoginAside} />
            <Route path="/business" component={BusinessAside} />
            <Redirect to={'/'} />
          </Switch>
        </div>
      </Hidden>
    </Router>
  );
};

export default AuthNavigation;
