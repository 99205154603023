import React from 'react';

import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import '../../aside.scss';

const UsersAside = (props) => {
  const { tenantData } = props;

  return (
    <div className="container d-flex">
      <div className="tabs-list">
        <div className="tab-container">
          <Link to={'/users'} style={{ textDecoration: 'none' }}>
            <p
              style={
                props.location.pathname === '/users'
                  ? {
                      color: tenantData.mainColor,
                      fontSize: 16,
                      fontWeight: 'bold',
                    }
                  : {
                      color: '#5b667d',
                      fontSize: 16,
                      fontWeight: 'bold',
                    }
              }
            >
              Users
            </p>
          </Link>
        </div>
        <div className="tab-container">
          <Link to={'/users/teams'} style={{ textDecoration: 'none' }}>
            <p
              style={
                props.location.pathname === '/users/teams'
                  ? {
                      color: tenantData.mainColor,
                      fontSize: 16,
                      fontWeight: 'bold',
                    }
                  : {
                      color: '#5b667d',
                      fontSize: 16,
                      fontWeight: 'bold',
                    }
              }
            >
              Teams
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    tenantData: state.tenant,
  };
};

export default withRouter(connect(mapStateToProps)(UsersAside));
