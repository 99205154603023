import React from 'react';

import './counts-modal.scss';
import { displayFullName } from '../../../../../util/helpers';

const CountTeams = (props) => {
  const { teams, users, tenant } = props;

  const styleObject = {
    fontWeight: 'bold',
    fontSize: 16,
    color: tenant.mainColor,
  };

  return (
    <div className="w-100 h-100 p-t-lg">
      <div className="assign-count-container">
        <div className="teams-count-container">
          <div className="drawer-title">Teams</div>
          <div className="small-text">Assigned Team(s)</div>
          <div className="teams-count-list">
            {teams.map((team) => {
              return (
                <div style={styleObject} className="m-t-lg">
                  {team.name}
                </div>
              );
            })}
          </div>
        </div>
        <div className="users-count-container">
          <div className="drawer-title">Users</div>
          <div className="small-text">Assigned User(s)</div>
          <div className="teams-count-list">
            {users.map((user) => {
              return (
                <div className="d-flex space-between w-100 m-t-md">
                  <div style={styleObject}>{displayFullName(user.fullName)}</div>
                  <div className="drawer-title w-50">{user.email}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountTeams;
