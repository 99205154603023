import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';

import { makeStyles, Table, TableContainer, TablePagination, Paper } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

import LoadScreen from '../../../../load-screen/LoadScreen';
import Alert from '../../../../shared/Alert';

import { SchedulesTableHead } from './SchedulesTableHead';
import { SchedulesTableBody } from './SchedulesTableBody';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  paper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    height: '100%',
    cursor: 'pointer',
  },
  container: {
    maxHeight: window.innerHeight - 270,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tabPaginationPaper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    border: 'none',
    boxShadow: 'none',
    paddingRight: 20,
    minWidth: 200,
  },
  paginationPaper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItem: 'center',
  },
  tabPagination: {
    display: 'flex',
    alignItems: 'flex-end',
    padding: 10,
  },
}));

const EnhancedTable = (props) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorType, setErrorType] = useState('success');
  const [errorText, setErrorText] = useState('');

  const { rows, setModalEditMode, openDrawer, setEditSchedule, tenantData, user, locations, deleteSchedule } = props;

  const editSchedule = (id) => {
    setModalEditMode(true);
    setEditSchedule(id);
    openDrawer();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleRowClick = (e, row) => {
    e.stopPropagation();
    editSchedule(row.id);
  };

  const handleChangePageTab = (event, newPage) => {
    setPage(newPage - 1);
  };

  const findLocation = (id) => locations.find((location) => location.id === id);

  return (
    <Fragment>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer className={classes.container}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <SchedulesTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <SchedulesTableBody
                classes={classes}
                user={user}
                rows={rows}
                order={order}
                orderBy={orderBy}
                page={page}
                rowsPerPage={rowsPerPage}
                isSelected={isSelected}
                handleRowClick={handleRowClick}
                deleteSchedule={deleteSchedule}
                findLocation={findLocation}
                editSchedule={editSchedule}
                setErrorText={setErrorText}
                setErrorType={setErrorType}
                setError={setError}
                setIsLoading={setIsLoading}
              />
            </Table>
          </TableContainer>
          <Paper className={classes.paginationPaper}>
            <TablePagination
              rowsPerPageOptions={[25, 50, 75, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage="Results"
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <Paper className={classes.tabPaginationPaper}>
              <div
                style={{
                  fontWeight: 'bold',
                  color: page === 0 ? '#dcdfe4' : tenantData.mainColor,
                  cursor: 'pointer',
                }}
                onClick={(e) => {
                  if (page !== 0) {
                    setPage(page - 1);
                  }
                }}
              >
                Prev
              </div>
              <Pagination
                className={classes.tabPagination}
                count={Math.ceil(rows.length / rowsPerPage)}
                page={page + 1}
                hideNextButton
                hidePrevButton
                onChange={handleChangePageTab}
                defaultPage={1}
                color="primary"
              />
              <div
                style={{
                  fontWeight: 'bold',
                  color: page < Math.ceil(rows.length / rowsPerPage) - 1 ? tenantData.mainColor : '#dcdfe4',
                  cursor: 'pointer',
                }}
                onClick={(e) => {
                  if (page < Math.ceil(rows.length / rowsPerPage) - 1) {
                    setPage(page + 1);
                  }
                }}
              >
                Next
              </div>
            </Paper>
          </Paper>
        </Paper>
      </div>
      {isLoading ? <LoadScreen /> : null}
      <Alert variant={errorType} message={errorText} open={error} onClose={() => setError(false)} />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    tenantData: state.tenant,
    user: state.user,
    locations: state.locations,
  };
};

export default connect(mapStateToProps)(EnhancedTable);
