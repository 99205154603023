import React from 'react';

import './sign-up-title.scss';

const SignUpTitle = (props) => {
  const { titleText } = props;

  return (
    <div className="d-flex w-100 flex-center sign-text-container align-end" style={{ height: props.height }}>
      <h2 className="title-text">{titleText}</h2>
    </div>
  );
};

export default SignUpTitle;
