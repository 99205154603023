import { TableBody, TableCell, TableRow } from '@material-ui/core';

import { stableSort, getComparator } from '../../../../../util/helpers';

import edit from '../../../../../assets/icons/edit.svg';
import remove from '../../../../../assets/icons/delete.svg';

export const SchedulesTableBody = (props) => {
  const {
    classes,
    user,
    rows,
    order,
    orderBy,
    page,
    rowsPerPage,
    isSelected,
    handleRowClick,
    deleteSchedule,
    findLocation,
    editSchedule,
    setErrorText,
    setErrorType,
    setError,
    setIsLoading,
  } = props;

  return (
    <TableBody classes={{ root: classes.tableBody }}>
      {stableSort(rows, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, index) => {
          const isItemSelected = isSelected(row.name);
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <TableRow
              hover
              onClick={(event) => handleRowClick(event, row)}
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={row.id}
              selected={isItemSelected}
            >
              <TableCell component="th" id={labelId} scope="row">
                <div className="table-black-text">{row.name}</div>
              </TableCell>
              <TableCell align="left">
                <div className="table-black-text">{findLocation(row.location).name}</div>
              </TableCell>
              <TableCell align="left">
                <div className="d-flex flex-end align-center p-r-lg">
                  <img
                    src={edit}
                    onClick={(e) => {
                      e.stopPropagation();
                      editSchedule(row.id);
                    }}
                    alt="edit"
                  />
                  <img
                    style={{ marginLeft: '24px' }}
                    onClick={(e) => {
                      e.stopPropagation();

                      if (user.role === 'Operative') {
                        setErrorText('Permission denied');
                        setErrorType('error');
                        setError(true);
                        return;
                      }

                      setIsLoading(true);
                      deleteSchedule(row).then(() => {
                        setIsLoading(false);
                        setErrorText(`${row.name} was successfully removed`);
                        setError(true);
                      });
                    }}
                    src={remove}
                    alt="delete"
                  />
                </div>
              </TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  );
};
