import React from 'react';
import ReactExport from 'react-data-export';
import { displayFullName } from '../../../../../util/helpers';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const borderStyleDefault = {
  style: 'thin',
  color: '#FFFFAA00',
};

const UserDownload = (props) => {
  const { component, user } = props;

  const collectInfo = () => {
    const style = {
      border: {
        top: borderStyleDefault,
        bottom: borderStyleDefault,
        left: borderStyleDefault,
        right: borderStyleDefault,
      },
    };
    if (user) {
      const data = [
        [
          { value: 'User ID', style, width: { wpx: 500 } },
          { value: user.id, style, width: { wpx: 500 } },
        ],
        [
          { value: 'Full Name', style, width: { wpx: 500 } },
          { value: displayFullName(user.fullName), style, width: { wpx: 500 } },
        ],
        [
          { value: 'Email', style, width: { wpx: 500 } },
          { value: user.email, style, width: { wpx: 500 } },
        ],
        [
          { value: 'Role', style, width: { wpx: 500 } },
          { value: user.role, style, width: { wpx: 500 } },
        ],
        [
          { value: 'Position', style, width: { wpx: 500 } },
          { value: user.position, style, width: { wpx: 500 } },
        ],
        [
          { value: 'Description', style, width: { wpx: 500 } },
          { value: user.description, style, width: { wpx: 500 } },
        ],
      ];

      return [
        {
          columns: [
            { title: '', width: { wpx: 200 } },
            { title: '', width: { wpx: 250 } },
          ],
          data,
        },
      ];
    }
  };

  return user ? (
    <ExcelFile element={component} filename={user.id}>
      <ExcelSheet dataSet={collectInfo()} name="User Info" />
    </ExcelFile>
  ) : null;
};

export default UserDownload;
