import React, { useState } from 'react';

import { Autocomplete } from '@material-ui/lab';

import TextField from '@material-ui/core/TextField';
import Icon from '@material-ui/core/Icon';

import remove from '../../../../../assets/icons/delete.svg';

import './locations-modal.scss';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

const SubLocations = (props) => {
  const [subLocationName, setSubLocationName] = useState('');

  const { addSubLocation, removeSubLocation, subLocationsData } = props;

  const options = props.subLocationsData.filter((item) => item.active === true).map((item) => item.name);

  const getSubLocationData = (name) => {
    return subLocationsData.find((item) => item.name === name);
  };

  return (
    <div className="d-flex flex-1 direction-column">
      <div className="drawer-title">Sub-Locations</div>
      <div className="sub-locations-select">
        <div className="w-50 d-flex align-end">
          <div className="flex-1">
            <Autocomplete
              options={options}
              onChange={(e, v) => {
                setSubLocationName(v);
              }}
              value={subLocationName}
              id="sub-locations"
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Add Sub-Location"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </div>
          <div className="p-l-sm">
            <IconButton
              onClick={(e) => {
                addSubLocation(getSubLocationData(subLocationName));
                setSubLocationName('');
              }}
              color={subLocationName ? 'primary' : 'secondary'}
              aria-label=""
              size="small"
            >
              <Icon color={subLocationName ? 'primary' : 'secondary'}>add_circle_outline</Icon>
            </IconButton>
          </div>
        </div>
      </div>
      <div className="d-flex direction-column flex-1 w-100 members-list m-t-md">
        {props.subLocations.map((item) => {
          return (
            <div key={item.id} className="d-flex w-50 space-between align-end m-t-sm">
              <p className="table-text m-0">{item.name}</p>
              <IconButton onClick={(e) => removeSubLocation(item.id)} aria-label="" color="secondary" size="small">
                <DeleteOutlineIcon style={{ color: '#d32f2f' }} />
              </IconButton>
            </div>
          );
        })}
      </div>
      <div className="members-list"></div>
    </div>
  );
};

export default SubLocations;
