import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { withRouter } from 'react-router-dom';
import XLSX from 'xlsx';

import { removeLocation, createLocationsFromCSV } from '../../../../actions/locationsCollectionActions';
import { deleteSubLocation } from '../../../../actions/subLocationsCollectionActions';

import LoadScreen from '../../../load-screen/LoadScreen';

import { FileUpload } from '../../../shared/FileUpload';
import { searchFilter } from '../../../shared/sharedFunctions';
import Alert from '../../../shared/Alert';
import StyledButton from '../../../shared/Button';

import LocationsModal from './locations-modal/LocationsModal';
import SubLocationsModal from './sub-locations-modal/SubLocationsModal';
import LocationsTable from './locations-table/LocationsTable';
import SubLocationsTable from './sub-locations-table/SubLocationsTable';

import {
  TextField,
  makeStyles,
  Select,
  MenuItem,
  IconButton,
  InputAdornment,
  OutlinedInput,
  FormControl,
  InputLabel,
} from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete } from '@material-ui/lab';

import '../../manager.scss';

const useStyles = makeStyles((theme) => ({
  searchStyle: {
    height: 35,
  },
  searchInput: {
    margin: 0,
    height: 30,
  },
  tab: {
    marginTop: 20,
    marginBottom: 20,
    borderBottom: '1px solid #E9E9E9',
    padding: 0,
  },
  tabItem: {
    width: 'max-content !important',
    display: 'flex',
    padding: '0 !important',
    justifyContent: 'flex-start !important',
  },
  drawer: {
    width: '100%',
  },
  inputFilterText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    textAlign: 'left',
    border: 'none',
    width: 150,
  },
  inputFilterIcon: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    border: 'none',
  },
}));

const LocationsManager = (props) => {
  const { usersCollection, teamsData, subLocationsData, locationsData, tenantData, createLocationsFromCSV } = props;

  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [modalEditMode, setModalEditMode] = useState(false);
  const [modalEditLocation, setModalEditLocation] = useState({});
  const [subLocationDrawerIsOpen, setSubLocationDrawerIsOpen] = useState(false);
  const [subLocationEditMode, setSubLocationEditMode] = useState(false);
  const [modalEditSubLocation, setModalEditSubLocation] = useState(false);
  const [locationFilterValue, setLocationFilterValue] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchVisible, setSearchVisible] = useState(false);
  const [errorType, setErrorType] = useState('error');
  const [errorText, setErrorText] = useState('');
  const [error, setError] = useState(false);

  const [openName, setOpenName] = useState(false);
  const [descriptionOpen, setDescriptionOpen] = useState(false);

  const [nameFilter, setNameFilter] = useState('All');
  const [descriptionFilter, setDescriptionFilter] = useState('All');

  const nameFilterHandleChange = (e) => {
    setNameFilter(e.target.value);
  };

  const nameFilterHandleOpen = () => {
    setOpenName(true);
  };

  const nameFilterHandleClose = () => {
    setOpenName(false);
  };

  const descriptionFilterHandleChange = (e) => {
    setDescriptionFilter(e.target.value);
  };

  const descriptionFilterHandleOpen = () => {
    setDescriptionOpen(true);
  };

  const descriptionFilterHandleClose = () => {
    setDescriptionOpen(false);
  };

  const getFilteredRows = () => {
    if (locationFilterValue) {
      return subLocationsData.filter((item) => item.location === locationFilterValue);
    }
    return subLocationsData;
  };

  const getLocationsNames = () => {
    return locationsData.map((item) => ({ label: item.name, id: item.id }));
  };

  const openDrawer = () => {
    if (props.location.pathname === '/locations') {
      setDrawerIsOpen(true);
    } else {
      setSubLocationDrawerIsOpen(true);
    }
  };

  const getNameOptions = (collection) => {
    const result = [];

    collection.forEach((item) => {
      if (!result.includes(item.name)) {
        result.push(item.name);
      }
    });
    return result;
  };

  const getDescriptionOptions = (collection) => {
    const result = [];

    collection.forEach((item) => {
      if (!result.includes(item.description)) {
        result.push(item.description);
      }
    });
    return result;
  };

  const closeDrawer = () => {
    setDrawerIsOpen(false);
    setModalEditMode(false);
  };

  const closeSubLocationDrawer = () => {
    setSubLocationDrawerIsOpen(false);
    setSubLocationEditMode(false);
  };

  const getLocationData = (id) => {
    return locationsData.find((team) => id === team.id);
  };

  const getSubLocationData = (id) => {
    return subLocationsData.find((item) => id === item.id);
  };

  const getFilteredCollection = (collection) => {
    if (nameFilter === 'All' && descriptionFilter === 'All') {
      return collection;
    } else if (nameFilter === 'All' && descriptionFilter !== 'All') {
      return collection.filter((item) => item.description === descriptionFilter);
    } else if (nameFilter !== 'All' && descriptionFilter === 'All') {
      return collection.filter((item) => item.name === nameFilter);
    } else if (nameFilter !== 'All' && descriptionFilter !== 'All') {
      return collection.filter((item) => item.description === descriptionFilter && item.name === nameFilter);
    }
  };

  const handleFileUpload = (file) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = async (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });

      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];

      /* Skip first ROW with headers */
      const range = XLSX.utils.decode_range(ws['!ref']);
      range.s.r = 1; // <-- zero-indexed, so setting to 1 will skip row 0
      ws['!ref'] = XLSX.utils.encode_range(range);

      /* Convert array of arrays */
      /* XLSX.utils.sheet_add_aoa(ws, heading) */

      const data = XLSX.utils.sheet_to_json(ws, {
        header: ['name', 'description', 'address', 'region', 'postcode', 'note'],
        skipHeader: true,
      });

      try {
        setIsLoading(true);
        await createLocationsFromCSV(tenantData.id, data);
      } catch (err) {
        setErrorText('Not valid data');
        setErrorType('error');
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };

    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  };

  return (
    <Fragment>
      <div className="manager-container">
        <div className="manager-head">
          <div className="page-title-container">
            <h3 className="page-title">{`${
              props.location.pathname === '/locations' ? 'Locations' : 'Sub-Locations'
            }`}</h3>
            <p className="count">{`${
              props.location.pathname === '/locations'
                ? `(${locationsData.filter((item) => item.active === true).length})`
                : `(${subLocationsData.filter((item) => item.active === true).length})`
            }`}</p>
          </div>
          <div
            className={
              props.location.pathname === '/locations' ? 'search-container m-r-md' : 'search-container-sub m-r-md'
            }
          >
            <div
              className={
                props.location.pathname === '/locations'
                  ? 'search-input d-flex align-start flex-center'
                  : 'search-input-sub d-flex align-start flex-center'
              }
            >
              {searchVisible ? (
                <div
                  className={
                    props.location.pathname === '/locations' ? 'search-input-container' : 'search-input-container-sub'
                  }
                >
                  <FormControl size="small" variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Search...</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      value={searchValue}
                      type="text"
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <div
                            className="h-100 d-flex align-center cursor-pointer"
                            onClick={() => {
                              setSearchValue('');
                              setSearchVisible(false);
                            }}
                          >
                            <SearchIcon />
                          </div>
                        </InputAdornment>
                      }
                      labelWidth={70}
                    />
                  </FormControl>
                </div>
              ) : (
                <IconButton
                  onClick={() => {
                    setSearchVisible(true);
                  }}
                >
                  <SearchIcon />
                </IconButton>
              )}
            </div>
          </div>
          <FileUpload handleFile={handleFileUpload} />
          <div className="d-flex">
            <StyledButton
              onClick={openDrawer}
              maxContent={props.location.pathname === '/locations/sub-locations'}
              text={`${props.location.pathname === '/locations' ? 'Add Location' : 'Add Sub-Location'}`}
            />
          </div>
        </div>
        <div className={`w-50 ${props.location.pathname === '/locations' ? 'd-none' : 'm-t-md m-b-sm'}`}>
          <Autocomplete
            options={getLocationsNames()}
            onChange={(e, v) => {
              setLocationFilterValue(v ? locationsData.find((item) => item.name === v.label).id : false);
            }}
            getOptionLabel={(option) => option.label}
            id="sub-locations"
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Choose Location"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </div>
        <div className={`table-filters ${props.location.pathname === '/locations' ? 'd-none' : ''}`}>
          <div className="filter-container">
            <p className="tab-text m-0 d-flex flex-end align-center filter-name">Name: </p>
            <Select
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
              }}
              classes={{ root: classes.inputFilterText, icon: classes.inputFilterIcon }}
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              open={openName}
              onClose={nameFilterHandleClose}
              onOpen={nameFilterHandleOpen}
              value={nameFilter}
              onChange={nameFilterHandleChange}
            >
              <MenuItem value="All">All</MenuItem>
              {props.location.pathname === '/locations'
                ? getNameOptions(locationsData.filter((item) => item.active === true)).map((item, index) => (
                    <MenuItem key={`${item}_${index}`} value={item}>
                      {item}
                    </MenuItem>
                  ))
                : getNameOptions(getFilteredRows().filter((item) => item.active === true)).map((item, index) => (
                    <MenuItem key={`${item}_${index}`} value={item}>
                      {item}
                    </MenuItem>
                  ))}
            </Select>
          </div>

          <div className="filter-container">
            <p className="tab-text d-flex flex-end align-center filter-name">Description: </p>
            <Select
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
              }}
              classes={{ root: classes.inputFilterText, icon: classes.inputFilterIcon }}
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              open={descriptionOpen}
              onClose={descriptionFilterHandleClose}
              onOpen={descriptionFilterHandleOpen}
              value={descriptionFilter}
              onChange={descriptionFilterHandleChange}
            >
              <MenuItem value="All">All</MenuItem>
              {props.location.pathname === '/locations'
                ? getDescriptionOptions(locationsData.filter((item) => item.active === true)).map((item, index) => (
                    <MenuItem key={`${item}_${index}`} value={item}>
                      {item}
                    </MenuItem>
                  ))
                : getDescriptionOptions(getFilteredRows().filter((item) => item.active === true)).map((item, index) => (
                    <MenuItem key={`${item}_${index}`} value={item}>
                      {item}
                    </MenuItem>
                  ))}
            </Select>
          </div>
        </div>
        <div className="table flex-1">
          {props.location.pathname === '/locations' ? (
            <LocationsTable
              locationsData={locationsData}
              usersCollection={usersCollection}
              removeLocation={props.removeLocation}
              subLocationsData={subLocationsData}
              rows={getFilteredCollection(
                searchFilter(
                  locationsData.filter((item) => item.active === true),
                  searchValue,
                ),
              )}
              getLocationData={getLocationData}
              setModalEditMode={setModalEditMode}
              setModalEditLocation={setModalEditLocation}
              openDrawer={openDrawer}
              setIsLoading={setIsLoading}
            />
          ) : (
            <SubLocationsTable
              locationsData={locationsData}
              usersCollection={usersCollection}
              removeLocation={props.deleteSubLocation}
              rows={getFilteredCollection(
                searchFilter(
                  getFilteredRows().filter((item) => item.active === true),
                  searchValue,
                ),
              )}
              getSubLocationData={getSubLocationData}
              setModalEditSubLocation={setModalEditSubLocation}
              setSubLocationEditMode={setSubLocationEditMode}
              openDrawer={openDrawer}
            />
          )}
        </div>
      </div>
      <LocationsModal
        editMode={modalEditMode}
        editLocation={modalEditLocation}
        teamsData={teamsData}
        usersCollection={usersCollection}
        drawerIsOpen={drawerIsOpen}
        closeDrawer={closeDrawer}
      />
      <SubLocationsModal
        editMode={subLocationEditMode}
        editSubLocation={modalEditSubLocation}
        teamsData={teamsData}
        usersCollection={usersCollection}
        drawerIsOpen={subLocationDrawerIsOpen}
        closeDrawer={closeSubLocationDrawer}
      />
      {isLoading ? <LoadScreen /> : null}
      <Alert variant={errorType} message={errorText} open={error} onClose={() => setError(false)} />
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      removeLocation,
      deleteSubLocation,
      createLocationsFromCSV,
    },
    dispatch,
  );

const mapStateToProps = (state) => {
  return {
    subLocationsData: state.subLocations,
    usersCollection: state.usersCollection,
    tenantData: state.tenant,
    teamsData: state.teams,
    userData: state.user,
    locationsData: state.locations,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LocationsManager));
