import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Switch, Route, withRouter, BrowserRouter as Router } from 'react-router-dom';

import firebase from '../../../firebase/Firebase';
import { COUNTS_COLLECTION } from '../../../firebase/collections';
import { getCounts, createCountsScript } from '../../../actions/countsActions';
import SchedulesManager from '../../../components/app/locations/schedules-manager/SchedulesManager';

import '../../page.scss';

const Schedule = (props) => {
  const { getCounts, userData } = props;

  useEffect(() => {
    if (userData && userData.isLoaded) {
      const unsubscribe = firebase.db
        .collection(COUNTS_COLLECTION)
        .where('tenantId', '==', userData.tenantId)
        .onSnapshot(
          (querySnapshot) => {
            getCounts(userData.tenantId);
          },
          (e) => console.log(e),
        );

      return () => unsubscribe();
    }
  }, []);

  return (
    <Router>
      <div className="page-container w-70">
        <Switch>
          <Route exact path="/schedule" component={withRouter(SchedulesManager)} />
        </Switch>
      </div>
    </Router>
  );
};

const mapStateToProps = (state) => {
  return {
    loginData: state.auth,
    userData: state.user,
    tenantData: state.tenant,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCounts,
      createCountsScript,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);
