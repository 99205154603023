import React, { createRef } from 'react';

import TextField from '@material-ui/core/TextField';
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';

import './count-info.scss';

const CountInfo = (props) => {
  const { name, date, setName, setDate } = props;

  const nameInputRef = createRef();

  return (
    <div className="count-info">
      <div className="count-info-inputs">
        <div className="name">
          <TextField
            value={name}
            inputRef={nameInputRef}
            autoFocus={true}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            label="Name"
            placeholder="E.g Quarterly Count Warehouse"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </div>
      <div className="count-date-pick">
        <div className="date-picker">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              autoOk
              orientation="portrait"
              disableToolbar={true}
              variant="static"
              openTo="date"
              value={date}
              onChange={setDate}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div className="time-picker">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <TimePicker
              autoOk={false}
              variant="static"
              orientation="landscape"
              openTo="hours"
              value={date}
              onChange={(date) => {
                setDate(date);
              }}
              views={['hours', 'minutes']}
              onHourChange={(date) => setDate(date)}
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>
    </div>
  );
};

export default CountInfo;
