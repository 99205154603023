import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import firebase from '../../../firebase/Firebase';
import { COUNTS_COLLECTION } from '../../../firebase/collections';
import { getCounts, createCountsScript } from '../../../actions/countsActions';

import CountsManager from '../../../components/app/counts/counts-manager/CountsManager';

import '../../page.scss';

const Counts = (props) => {
  const { getCounts, userData } = props;

  useEffect(() => {
    if (userData && userData.isLoaded) {
      const unsubscribe = firebase.db
        .collection(COUNTS_COLLECTION)
        .where('tenantId', '==', userData.tenantId)
        .onSnapshot(
          (querySnapshot) => {
            getCounts(userData.tenantId);
          },
          (e) => console.log(e),
        );

      return () => unsubscribe();
    }
  }, []);

  return (
    <div className="page-container w-70">
      <CountsManager />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loginData: state.auth,
    userData: state.user,
    tenantData: state.tenant,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCounts,
      createCountsScript,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Counts);
