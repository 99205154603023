import React, { Fragment, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import StyledButton from '../../../../shared/Button';

import { updateLocation } from '../../../../../actions/locationsCollectionActions';
import { createLocation } from '../../../../../actions/locationsCollectionActions';

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import LoadScreen from '../../../../load-screen/LoadScreen';
import Alert from '../../../../shared/Alert';

import './locations-modal.scss';
import LocationDetails from './LocationDetails';
import SubLocations from './SubLocations';
import CancelButton from '../../../../shared/CancelButton';

const useStyles = makeStyles({
  drawer: {
    width: '100%',
  },
  drawerPaper: {
    boxShadow: '-4px 1px 13px 1px  rgba(87,73,87,1)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '50%',
  },
  nameInput: {
    width: '40%',
  },
  positionInput: {
    width: '100%',
  },
  tab: {
    marginTop: 20,
    marginBottom: 20,
    borderBottom: '1px solid #E9E9E9',
    padding: 0,
  },
  tabItem: {
    display: 'flex',
    padding: '0 !important',
    justifyContent: 'flex-start !important',
  },
  radioGroup: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  startTextWrapper: {
    alignItems: 'flex-start !important',
  },
});

const LocationsModal = (props) => {
  const classes = useStyles();
  const {
    drawerIsOpen,
    closeDrawer,
    tenantData,
    subLocationsData,
    editMode,
    editLocation,
    createLocation,
    updateLocation,
  } = props;

  useEffect(() => {
    const stateEditSet = (editLocation) => {
      if (editMode) {
        setName(editLocation.name);
        setDescription(editLocation.description);
        setNote(editLocation.note);
        setAddress(editLocation.address);
        setSubLocations(subLocationsData.filter((item) => item.location === editLocation.id));
        setRegion(editLocation.region ? editLocation.region : '');
        setPostCode(editLocation.postcode ? editLocation.postcode : '');
      } else {
        stateReset();
      }
    };

    stateEditSet(editLocation);
  }, [editMode, editLocation, subLocationsData]);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [address, setAddress] = useState('');
  const [note, setNote] = useState('');
  const [subLocations, setSubLocations] = useState([]);
  const [error, setError] = useState(false);
  const [errorType, setErrorType] = useState('error');
  const [errorText, setErrorText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [region, setRegion] = useState('');
  const [postcode, setPostCode] = useState('');

  const [drawerTabValue, setDrawerTabValue] = useState(0);

  const addSubLocation = (subLocation) => {
    if (!subLocation || subLocations.some((item) => item.id === subLocation.id)) {
      return;
    }
    setSubLocations([...subLocations, subLocation]);
  };

  const removeSubLocation = (id) => {
    setSubLocations(subLocations.filter((item) => item.id !== id));
  };

  const stateReset = () => {
    setName('');
    setRegion('');
    setPostCode('');
    setDescription('');
    setAddress('');
    setNote('');
    setSubLocations([]);
    setDrawerTabValue(0);
  };

  const getRemovedSubLocations = () => {
    const subLocationsArr = subLocationsData.filter((item) => item.location === editLocation.id);
    return subLocationsArr.filter((item) => {
      return !subLocations.some((i) => i.id === item.id);
    });
  };

  const collectData = () => {
    return {
      name,
      description,
      note,
      address,
      subLocations,
      tenantId: tenantData.id,
      region,
      postcode,
      coordinate: ' ',
    };
  };

  const drawerTabValueHandleChange = (event, newValue) => {
    setDrawerTabValue(newValue);
  };

  const onSubmit = (e) => {
    if (!name || !description || !address) {
      setErrorText('Input all required fields');
      setErrorType('error');
      setError(true);
      return;
    }

    setIsLoading(true);

    if (editMode) {
      updateLocation({
        ...collectData(),
        id: editLocation.id,
        removedSubLocations: getRemovedSubLocations(),
      })
        .then(() => {
          closeDrawer();
          setIsLoading(false);
        })
        .catch(() => {
          closeDrawer();
          setIsLoading(false);
          setErrorText('Permission denied');
          setErrorType('error');
          setError(true);
        });
    } else {
      createLocation(collectData())
        .then(() => {
          closeDrawer();
          setIsLoading(false);
        })
        .catch(() => {
          closeDrawer();
          setIsLoading(false);
          setErrorText('Permission denied');
          setErrorType('error');
          setError(true);
        });
      stateReset();
    }
  };

  return (
    <Fragment>
      <Drawer
        className={classes.drawer}
        anchor={'right'}
        open={drawerIsOpen}
        classes={{ paperAnchorRight: classes.drawerPaper }}
        ModalProps={{ onBackdropClick: closeDrawer }}
      >
        <div className="drawer-container">
          <div className="drawer-title">{editMode ? 'Edit Location' : 'Add Location'}</div>
          {editMode ? <div className="table-black-text">{editLocation.name}</div> : null}
          <Paper elevation={0}>
            <Tabs
              value={drawerTabValue}
              indicatorColor="primary"
              textColor="primary"
              className={classes.tab}
              onChange={drawerTabValueHandleChange}
            >
              <Tab className={classes.tabItem} classes={{ wrapper: classes.startTextWrapper }} label="Location" />
              <Tab className={classes.tabItem} classes={{ wrapper: classes.startTextWrapper }} label="Sub-Locations" />
            </Tabs>
          </Paper>
          {drawerTabValue === 0 ? (
            <LocationDetails
              name={name}
              description={description}
              note={note}
              address={address}
              setName={setName}
              setDescription={setDescription}
              setNote={setNote}
              setAddress={setAddress}
              region={region}
              setRegion={setRegion}
              postcode={postcode}
              setPostCode={setPostCode}
            />
          ) : (
            <SubLocations
              addSubLocation={addSubLocation}
              removeSubLocation={removeSubLocation}
              setSubLocations={setSubLocations}
              subLocations={subLocations}
              subLocationsData={subLocationsData}
            />
          )}
        </div>
        <div className="submit-modal-footer">
          <CancelButton onClick={closeDrawer} />
          <StyledButton text="Save" onClick={onSubmit} />
        </div>
      </Drawer>
      {isLoading ? <LoadScreen /> : null}
      <Alert variant={errorType} message={errorText} open={error} onClose={() => setError(false)} />
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateLocation,
      createLocation,
    },
    dispatch,
  );

const mapStateToProps = (state) => {
  return {
    tenantData: state.tenant,
    subLocationsData: state.subLocations,
    locationsData: state.locations,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationsModal);
