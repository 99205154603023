import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import XLSX from 'xlsx';

import { deleteUser, createUser } from '../../../../actions/usersCollectionActions';

import {
  makeStyles,
  Select,
  MenuItem,
  IconButton,
  InputAdornment,
  OutlinedInput,
  FormControl,
  InputLabel,
  Paper,
  Tabs,
  Tab,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import StyledButton from '../../../shared/Button';
import { FileUpload } from '../../../shared/FileUpload';
import { searchFilter } from '../../../shared/sharedFunctions';
import Alert from '../../../shared/Alert';
import LoadScreen from '../../../load-screen/LoadScreen';

import UsersTable from './users-table/UsersTable';
import CreateUserModal from './create-user-modal/CreateUserModal';
import ResultsModal from './results-modal/ResultsModal';

import { toUpperFirstLetter } from '../../../../util/helpers';

import '../../manager.scss';

const useStyles = makeStyles((theme) => ({
  searchInput: {
    margin: 0,
  },
  tab: {
    marginBottom: 10,
    borderBottom: '1px solid #E9E9E9',
    padding: 0,
  },
  tabItem: {
    width: 'max-content !important',
    display: 'flex',
    padding: '0 !important',
    justifyContent: 'flex-start !important',
  },
  drawer: {
    width: '100%',
  },
  inputFilterText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    textAlign: 'left',
    border: 'none',
    width: 150,
  },
  inputFilterIcon: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    border: 'none',
  },
}));

const UsersManager = (props) => {
  const { usersCollection, deleteUser, locationsData, teamsData, createUser, userData } = props;

  const classes = useStyles();

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [modalEditMode, setModalEditMode] = useState(false);
  const [modalEditUser, setModalEditUser] = useState({});
  const [searchValue, setSearchValue] = useState('');

  const [roleFilter, setRoleFilter] = useState('All');
  const [positionFilter, setPositionFilter] = useState('All');
  const [teamFilter, setTeamFilter] = useState('All');
  const [locationFilter, setLocationFilter] = useState('All');

  const [roleFilterOpen, setRoleFilterOpen] = useState(false);
  const [positionFilterOpen, setPositionFilterOpen] = useState(false);
  const [teamFilterOpen, setTeamFilterOpen] = useState(false);
  const [locationFilterOpen, setLocationFilterOpen] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const [uploadData, setUploadData] = useState([]);

  const [error, setError] = useState(false);
  const [errorType, setErrorType] = useState('error');
  const [errorText, setErrorText] = useState('');
  const [resultsModalOpen, setResultsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const openResultsModal = () => setResultsModalOpen(true);
  const closeResultsModal = () => {
    setResultsModalOpen(false);
    setUploadData([]);
  };

  const roleFilterHandleChange = (e) => {
    setRoleFilter(e.target.value);
  };

  const roleFilterHandleClose = () => {
    setRoleFilterOpen(false);
  };

  const roleFilterHandleOpen = () => {
    setRoleFilterOpen(true);
  };

  const positionFilterHandleChange = (e) => {
    setPositionFilter(e.target.value);
  };

  const positionFilterHandleClose = () => {
    setPositionFilterOpen(false);
  };

  const positionFilterHandleOpen = () => {
    setPositionFilterOpen(true);
  };

  const teamFilterHandleChange = (e) => {
    setTeamFilter(e.target.value);
  };

  const teamFilterHandleClose = () => {
    setTeamFilterOpen(false);
  };

  const teamFilterHandleOpen = () => {
    setTeamFilterOpen(true);
  };

  const locationFilterHandleChange = (e) => {
    setLocationFilter(e.target.value);
  };

  const locationFilterHandleClose = () => {
    setLocationFilterOpen(false);
  };

  const locationFilterHandleOpen = () => {
    setLocationFilterOpen(true);
  };

  const tabValueHandleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const openDrawer = () => {
    setDrawerIsOpen(true);
  };

  const closeDrawer = () => {
    setDrawerIsOpen(false);
    setModalEditMode(false);
  };

  const getUserData = (id) => {
    return usersCollection.find((user) => id === user.id);
  };

  const getPositionOptions = (collection) => {
    const result = [];

    collection.forEach((item) => {
      if (!result.includes(item.position) && item.position !== '') {
        result.push(item.position);
      }
    });
    return result;
  };
  const getFilteredCollection = (collection) => {
    const filters = [roleFilter, positionFilter, teamFilter, locationFilter];

    if (filters.every((item) => item === 'All')) {
      return collection;
    } else if (filters.every((item) => item !== 'All')) {
      return collection.filter(
        (item) =>
          item.role === filters[0] &&
          item.position === filters[1] &&
          item.teams.includes(filters[2]) &&
          item.locations.includes(filters[3]),
      );
    } else {
      return collection.filter((item) => {
        return (
          (roleFilter === 'All' || roleFilter === item.role) &&
          (positionFilter === 'All' || positionFilter === item.position) &&
          (teamFilter === 'All' || item.teams.includes(teamFilter)) &&
          (locationFilter === 'All' || item.locations.includes(locationFilter))
        );
      });
    }
  };

  const handleFileUpload = (file) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = async (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });

      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];

      /* Skip first ROW with headers */
      const range = XLSX.utils.decode_range(ws['!ref']);
      range.s.r = 1; // <-- zero-indexed, so setting to 1 will skip row 0
      ws['!ref'] = XLSX.utils.encode_range(range);

      /* Convert array of arrays */
      /* XLSX.utils.sheet_add_aoa(ws, heading) */

      const data = XLSX.utils.sheet_to_json(ws, {
        header: ['firstName', 'lastName', 'email', 'phone', 'role', 'position', 'description'],
        skipHeader: true,
      });

      const newUploadData = [...data];
      const roles = ['admin', 'manager', 'operative'];

      const promises = data.map(async (user, index) => {
        if (
          !user.firstName ||
          !user.lastName ||
          !user.email ||
          !user.role ||
          !roles.includes(user.role.toLowerCase())
        ) {
          newUploadData[index].status = 'error';
          setErrorText('Required First Name, Last Name, Email and Role');
          setErrorType('error');
          setError(true);
          return;
        }
        return new Promise(async (resolve, reject) => {
          setIsLoading(true);
          const _user = {
            ...user,
            tenantId: userData.tenantId,
            firstName: user.firstName ? user.firstName : '',
            lastName: user.lastName,
            phone: user.phone ? user.phone.toString() : '',
            role: toUpperFirstLetter(user.role),
            position: user.position ? toUpperFirstLetter(user.position) : '',
            description: user.description ? toUpperFirstLetter(user.description) : '',
            teams: [],
            locations: [],
          };
          delete _user.firstname;
          delete _user.lastname;

          await createUser(_user).then((res) => {
            if (res.status === 'success') {
              newUploadData[index].status = 'success';
              resolve();
            } else {
              newUploadData[index].status = 'error';
              newUploadData[index].error = res.error;
              resolve();
            }
          });
        }).finally(() => setIsLoading(false));
      });

      await Promise.all(promises);

      setUploadData([...newUploadData]);
      Promise.resolve().then(() => {
        openResultsModal();
      });
    };

    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  };

  const usersCount = usersCollection.filter((user) => user.active).length;

  return (
    <Fragment>
      <div className="manager-container">
        <div className="manager-head">
          <div className="page-title-container">
            <h3 className="page-title">Users</h3>
            <p className="count">{`(${usersCount})`}</p>
          </div>
          <div className="search-container m-r-md">
            <div className="search-input d-flex align-start flex-center">
              {searchVisible ? (
                <div className="search-input-container">
                  <FormControl size="small" variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Search...</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      value={searchValue}
                      type="text"
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <div
                            className="h-100 d-flex align-center cursor-pointer"
                            onClick={() => {
                              setSearchValue('');
                              setSearchVisible(false);
                            }}
                          >
                            <SearchIcon />
                          </div>
                        </InputAdornment>
                      }
                      labelWidth={70}
                    />
                  </FormControl>
                </div>
              ) : (
                <IconButton
                  onClick={() => {
                    setSearchVisible(true);
                  }}
                >
                  <SearchIcon />
                </IconButton>
              )}
            </div>
          </div>
          <FileUpload handleFile={handleFileUpload} />
          <div className="d-flex">
            <StyledButton onClick={openDrawer} text={'Add User'} />
          </div>
        </div>
        <div className="table-switch">
          <Paper elevation={0}>
            <Tabs
              value={tabValue}
              indicatorColor="primary"
              textColor="primary"
              className={classes.tab}
              onChange={tabValueHandleChange}
            >
              <Tab className={classes.tabItem} classes={{ wrapper: classes.tabItem }} label="Current" />
              <Tab className={classes.tabItem} classes={{ wrapper: classes.tabItem }} label="Archive" />
            </Tabs>
          </Paper>
        </div>
        <div className="table-filters">
          <div className="filter-container">
            <p className="tab-text m-0 d-flex flex-end align-center filter-name">Role: </p>
            <Select
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
              }}
              classes={{ root: classes.inputFilterText, icon: classes.inputFilterIcon }}
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              open={roleFilterOpen}
              onClose={roleFilterHandleClose}
              onOpen={roleFilterHandleOpen}
              value={roleFilter}
              onChange={roleFilterHandleChange}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Admin">Admin</MenuItem>
              <MenuItem value="Operative">Operative</MenuItem>
              <MenuItem value="Manager">Manager</MenuItem>
            </Select>
          </div>
          <div className="filter-container">
            <p className="tab-text d-flex flex-end align-center filter-name">Position: </p>
            <Select
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
              }}
              classes={{ root: classes.inputFilterText, icon: classes.inputFilterIcon }}
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              open={positionFilterOpen}
              onClose={positionFilterHandleClose}
              onOpen={positionFilterHandleOpen}
              value={positionFilter}
              onChange={positionFilterHandleChange}
            >
              <MenuItem value="All">All</MenuItem>
              {getPositionOptions(usersCollection).map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="filter-container">
            <p className="tab-text m-0 d-flex flex-end align-center filter-name">Team: </p>
            <Select
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
              }}
              classes={{ root: classes.inputFilterText, icon: classes.inputFilterIcon }}
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              open={teamFilterOpen}
              onClose={teamFilterHandleClose}
              onOpen={teamFilterHandleOpen}
              value={teamFilter}
              onChange={teamFilterHandleChange}
            >
              <MenuItem value={'All'}>All</MenuItem>
              {teamsData
                .filter((item) => item.active === true)
                .map((team) => (
                  <MenuItem key={team.id} value={team.id}>
                    {team.name}
                  </MenuItem>
                ))}
            </Select>
          </div>
          <div className="filter-container">
            <p className="tab-text m-0 d-flex flex-end align-center filter-name">Location: </p>
            <Select
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
              }}
              classes={{ root: classes.inputFilterText, icon: classes.inputFilterIcon }}
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              open={locationFilterOpen}
              onClose={locationFilterHandleClose}
              onOpen={locationFilterHandleOpen}
              value={locationFilter}
              onChange={locationFilterHandleChange}
            >
              <MenuItem value={'All'}>All</MenuItem>
              {locationsData
                .filter((item) => item.active === true)
                .map((location) => (
                  <MenuItem key={location.id} value={location.id}>
                    {location.name}
                  </MenuItem>
                ))}
            </Select>
          </div>
        </div>
        <div className="table flex-1">
          <UsersTable
            getUserData={getUserData}
            setModalEditMode={setModalEditMode}
            setModalEditUser={setModalEditUser}
            openDrawer={openDrawer}
            teamsData={props.teamsData}
            deleteUser={deleteUser}
            rows={
              tabValue === 0
                ? getFilteredCollection(
                    searchFilter(
                      props.usersCollection.filter((item) => item.active === true),
                      searchValue,
                    ),
                  )
                : getFilteredCollection(
                    searchFilter(
                      props.usersCollection.filter((item) => item.active === false),
                      searchValue,
                    ),
                  )
            }
          />
        </div>
      </div>
      <CreateUserModal
        editMode={modalEditMode}
        editUser={modalEditUser}
        teamsData={props.teamsData}
        drawerIsOpen={drawerIsOpen}
        closeDrawer={closeDrawer}
        openDrawer={openDrawer}
      />
      {isLoading ? <LoadScreen /> : null}
      <Alert variant={errorType} message={errorText} open={error} onClose={() => setError(false)} />
      <ResultsModal open={resultsModalOpen} data={uploadData} handleClose={closeResultsModal} />
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteUser,
      createUser,
    },
    dispatch,
  );

const mapStateToProps = (state) => {
  return {
    teamsData: state.teams,
    usersCollection: state.usersCollection,
    locationsData: state.locations,
    userData: state.user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersManager);
