import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from 'react-avatar';
import Pagination from '@material-ui/lab/Pagination';

import { displayFullName } from '../../../../../util/helpers';

import countDownload from '../../../../../assets/icons/count_download.svg';
import edit from '../../../../../assets/icons/edit.svg';
import remove from '../../../../../assets/icons/delete.svg';
import LoadScreen from '../../../../load-screen/LoadScreen';
import Alert from '../../../../shared/Alert';
import UserDownload from '../user-download/UserDownload';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'fullName', numeric: false, disablePadding: true, label: 'User Name' },
  { id: 'role', numeric: false, disablePadding: false, label: 'Role' },
  { id: 'position', numeric: false, disablePadding: false, label: 'Position' },
  { id: 'team', numeric: false, disablePadding: false, label: 'Team' },
  { id: 'location', numeric: false, disablePadding: false, label: 'Location' },
  { id: 'edit', numeric: false, disablePadding: false, label: ' ' },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            className={classes.checked}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  paper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    height: '100%',
    cursor: 'pointer',
  },
  tableRow: {
    height: 75,
  },
  tabPaginationPaper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    border: 'none',
    boxShadow: 'none',
    paddingRight: 20,
    minWidth: 200,
  },
  container: {
    maxHeight: window.innerHeight - 379,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  paginationPaper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItem: 'center',
    boxShadow: 'none',
  },
  tabPagination: {
    display: 'flex',
    alignItems: 'flex-end',
    padding: 10,
  },
  checked: {
    color: theme.palette.primary.main,
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
}));

const EnhancedTable = (props) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorType, setErrorType] = useState('success');
  const [errorText, setErrorText] = useState('');

  const { rows, deleteUser, setModalEditMode, setModalEditUser, openDrawer, getUserData, tenantData, user } = props;

  const editUser = (id) => {
    setModalEditMode(true);
    setModalEditUser(getUserData(id));
    openDrawer();
  };

  const getLocationsString = (user) => {
    const firstLocation = props.locationsData.find((item) => item.id === user.locations[0]);
    const secondLocation = props.locationsData.find((item) => item.id === user.locations[1]);

    if (user.locations.length === 0) {
      return '';
    } else if (user.locations.length === 1) {
      return firstLocation ? firstLocation.name : '...';
    } else if (user.locations.length === 2) {
      return `${firstLocation ? firstLocation.name : '...'}, ${secondLocation ? secondLocation.name : '...'}`;
    } else if (user.locations.length > 2) {
      return `${firstLocation ? firstLocation.name : '...'}, ${secondLocation ? secondLocation.name : '...'} (${
        user.locations.length - 2
      })`;
    }
  };

  const getTeamsString = (user) => {
    const firstTeam = props.teamsData.find((item) => item.id === user.teams[0]);
    const secondTeam = props.teamsData.find((item) => item.id === user.teams[1]);

    if (user.teams.length === 0) {
      return '';
    } else if (user.teams.length === 1) {
      return firstTeam ? firstTeam.name : '...';
    } else if (user.teams.length === 2) {
      return `${firstTeam ? firstTeam.name : '...'}, ${secondTeam ? secondTeam.name : '...'}`;
    } else if (user.teams.length > 2) {
      return `${firstTeam ? firstTeam.name : '...'}, ${secondTeam ? secondTeam.name : '...'} (${
        user.teams.length - 2
      })`;
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePageTab = (event, newPage) => {
    setPage(newPage - 1);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <Fragment>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer className={classes.container}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody classes={{ root: classes.tableBody }}>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        className={classes.tableRow}
                        hover
                        onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            className={classes.checked}
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row" padding="none">
                          <div className="d-flex align-center">
                            <Avatar
                              name={row.fullName}
                              src={row.photo ? row.photo : ''}
                              size={26}
                              round
                              textSizeRatio={2}
                              textMarginRatio={0.22}
                            />
                            <div className="table-column">
                              <div
                                style={{
                                  color: tenantData.mainColor,
                                  fontSize: 16,
                                  fontWeight: 'bold',
                                }}
                              >
                                {displayFullName(row.fullName)}
                              </div>
                              <p className="small-text m-0">{row.email}</p>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell align="left">
                          <p className="table-black-text m-0">{row.role}</p>
                        </TableCell>
                        <TableCell align="left">
                          <p className="table-black-text m-0">{row.position}</p>
                        </TableCell>
                        <TableCell align="left">
                          <p className="table-black-text m-0">
                            {row.teams.length !== 0 && row.teams && props.locationsData ? getTeamsString(row) : ''}
                          </p>
                        </TableCell>
                        <TableCell align="left">
                          <p className="table-black-text m-0">
                            {row.locations.length !== 0 && row.locations && props.locationsData
                              ? getLocationsString(row)
                              : ''}
                          </p>
                        </TableCell>
                        <TableCell align="left">
                          <div className="d-flex flex-end align-center p-r-lg">
                            <UserDownload user={row} component={<img src={countDownload} alt="export" />} />
                            <img
                              src={edit}
                              alt="edit"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (user.role === 'Operative') {
                                  setErrorText('Permission denied');
                                  setErrorType('warning');
                                  setError(true);
                                  return;
                                }
                                editUser(row.id);
                              }}
                              style={{ marginLeft: '24px' }}
                            />
                            <img
                              src={remove}
                              onClick={(e) => {
                                e.stopPropagation();
                                if (user.role !== 'Admin') {
                                  setErrorText('Permission denied');
                                  setErrorType('error');
                                  setError(true);
                                  return;
                                }

                                setIsLoading(true);
                                deleteUser(row).then(() => {
                                  setIsLoading(false);
                                  setErrorText(`${row.fullName} was successfully removed`);
                                  setError(true);
                                });
                              }}
                              alt="delete"
                              style={{ marginLeft: '24px' }}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <Paper className={classes.paginationPaper}>
            <TablePagination
              rowsPerPageOptions={[25, 50, 75, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage="Results"
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <Paper className={classes.tabPaginationPaper}>
              <div
                style={{
                  fontWeight: 'bold',
                  color: page === 0 ? '#dcdfe4' : tenantData.mainColor,
                  cursor: 'pointer',
                }}
                onClick={(e) => {
                  if (page !== 0) {
                    setPage(page - 1);
                  }
                }}
              >
                Prev
              </div>
              <Pagination
                className={classes.tabPagination}
                count={Math.ceil(rows.length / rowsPerPage)}
                page={page + 1}
                hideNextButton
                hidePrevButton
                onChange={handleChangePageTab}
                defaultPage={1}
                color="primary"
              />
              <div
                style={{
                  fontWeight: 'bold',
                  color: page < Math.ceil(rows.length / rowsPerPage) - 1 ? tenantData.mainColor : '#dcdfe4',
                  cursor: 'pointer',
                }}
                onClick={(e) => {
                  if (page < Math.ceil(rows.length / rowsPerPage) - 1) {
                    setPage(page + 1);
                  }
                }}
              >
                Next
              </div>
            </Paper>
          </Paper>
        </Paper>
      </div>
      {isLoading ? <LoadScreen /> : null}
      <Alert variant={errorType} message={errorText} open={error} onClose={() => setError(false)} />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    tenantData: state.tenant,
    locationsData: state.locations,
    teamsData: state.teams,
    user: state.user,
  };
};

export default connect(mapStateToProps)(EnhancedTable);
