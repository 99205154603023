import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import XLSX from 'xlsx';

import { IconButton, InputAdornment, OutlinedInput, FormControl, InputLabel } from '@material-ui/core';
import { Search } from '@material-ui/icons';

import GtinTable from './gtin-table/gtin-table';
import GtinModal from './gtin-modal/gtin-modal';

import { FileUpload } from '../../../shared/FileUpload';
import StyledButton from '../../../shared/Button';
import Alert from '../../../shared/Alert';
import { searchFilter } from '../../../shared/sharedFunctions';
import LoadScreen from '../../../load-screen/LoadScreen';

import { createGtinReferenceFromCSV } from '../../../../actions/gtinActions';

import '../../manager.scss';

const GtinManager = (props) => {
  const { gtin, tenantData, createGtinReferenceFromCSV } = props;

  const [modalEditMode, setModalEditMode] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editCurrentGtin, setEditCurrentGtin] = useState({});
  const [errorType, setErrorType] = useState('error');
  const [errorText, setErrorText] = useState('');
  const [error, setError] = useState(false);

  const openDrawer = () => {
    if (props.location.pathname === '/settings/gtin-reference') {
      setDrawerIsOpen(true);
    }
  };

  const closeDrawer = () => {
    setDrawerIsOpen(false);
    setModalEditMode(false);
  };

  const handleFileUpload = (file) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = async (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });

      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];

      /* Skip first ROW with headers */
      const range = XLSX.utils.decode_range(ws['!ref']);
      range.s.r = 1; // <-- zero-indexed, so setting to 1 will skip row 0
      ws['!ref'] = XLSX.utils.encode_range(range);

      /* Convert array of arrays */
      /* XLSX.utils.sheet_add_aoa(ws, heading) */
      const data = XLSX.utils.sheet_to_json(ws, {
        header: ['gtin', 'ref', 'description', 'classification'],
        skipHeader: true,
      });

      try {
        setIsLoading(true);
        await createGtinReferenceFromCSV(tenantData.docId, data);
      } catch (err) {
        setErrorText('Not valid data');
        setErrorType('error');
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };

    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  };

  return (
    <>
      <div className="manager-container">
        <div className="manager-head">
          <div className="page-title-container">
            <h3 className="page-title">{`${
              props.location.pathname === '/settings' ? 'Settings' : 'GTIN Reference'
            }`}</h3>
            <p className="count">({gtin.list.length})</p>
          </div>

          <div className="search-container m-r-md">
            <div className="search-input d-flex align-start flex-center">
              {searchVisible ? (
                <div className="search-input-container">
                  <FormControl size="small" variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Search...</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      value={searchValue}
                      type="text"
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <div
                            className="h-100 d-flex align-center cursor-pointer"
                            onClick={() => {
                              setSearchValue('');
                              setSearchVisible(false);
                            }}
                          >
                            <Search />
                          </div>
                        </InputAdornment>
                      }
                      labelWidth={70}
                    />
                  </FormControl>
                </div>
              ) : (
                <IconButton
                  onClick={() => {
                    setSearchVisible(true);
                  }}
                >
                  <Search />
                </IconButton>
              )}
            </div>
          </div>

          <FileUpload handleFile={handleFileUpload} />

          <StyledButton
            onClick={openDrawer}
            maxContent={props.location.pathname === '/settings/gtin-reference'}
            text="Add GTIN"
          />
        </div>
        <div className="table flex-1">
          {isLoading ? <LoadScreen /> : null}
          <GtinTable
            rows={searchFilter(gtin.list, searchValue)}
            setModalEditMode={setModalEditMode}
            setEditCurrentGtin={setEditCurrentGtin}
            openDrawer={openDrawer}
            setIsLoading={setIsLoading}
          />
        </div>
      </div>
      <GtinModal
        modalEditMode={modalEditMode}
        editCurrentGtin={editCurrentGtin}
        drawerIsOpen={drawerIsOpen}
        closeDrawer={closeDrawer}
      />
      <Alert variant={errorType} message={errorText} open={error} onClose={() => setError(false)} />
    </>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createGtinReferenceFromCSV,
    },
    dispatch,
  );

const mapStateToProps = (state) => {
  return {
    tenantData: state.tenant,
    gtin: state.gtin,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GtinManager));
