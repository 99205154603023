import React, { useState } from 'react';

import { connect } from 'react-redux';

const CancelButton = (props) => {
  const { tenant, onClick } = props;

  const simpleStyle = {
    color: '#5b667d',
    fontWeight: 'bold',
    fontSize: 16,
    cursor: 'pointer',
    margin: 0,
  };

  const hoveredStyle = { ...simpleStyle, color: tenant.mainColor };

  const [style, setStyle] = useState(simpleStyle);

  return (
    <p
      style={style}
      onClick={onClick}
      onMouseOver={() => {
        setStyle(hoveredStyle);
      }}
      onMouseOut={() => {
        setStyle(simpleStyle);
      }}
    >
      Cancel
    </p>
  );
};

const mapStateToProps = (state) => {
  return {
    tenant: state.tenant,
  };
};

export default connect(mapStateToProps)(CancelButton);
