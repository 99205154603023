import {
  GTIN_COLLECTION_ADD,
  GTIN_COLLECTION_LOAD,
  GTIN_COLLECTION_DELETE,
  GTIN_COLLECTION_GET,
  GTIN_COLLECTION_UPDATE,
} from '../actions/actionTypes';

const initialState = {
  list: [],
  loading: false,
};

const gtinReducer = (state = initialState, action) => {
  switch (action.type) {
    case GTIN_COLLECTION_LOAD:
      return { ...state, loading: true };
    case GTIN_COLLECTION_GET:
      return { ...state, loading: false, list: action.data };
    case GTIN_COLLECTION_ADD:
      return {
        ...state,
        list: [...state.list, action.data],
        loading: false,
      };
    case GTIN_COLLECTION_UPDATE:
      const updated = state.list.find((item) => item.id === action.id);
      const newGtinReference = { ...updated, ...action.data };

      return {
        ...state,
        list: state.list.map((item) => (item.id === action.id ? newGtinReference : item)),
        loading: false,
      };

    case GTIN_COLLECTION_DELETE:
      return {
        ...state,
        list: state.list.filter((item) => item.id !== action.id),
        loading: false,
      };
    default:
      return state;
  }
};

export default gtinReducer;
