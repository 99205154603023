import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';

import { formatDate } from '../../../../shared/sharedFunctions';
import { getCountProductsTotal } from '../../utils';

import CustomTableHead from '../../../table/TableHead';
import { withRouter } from 'react-router-dom';

import remove from '../../../../../assets/icons/delete.svg';

function descendingComparator(a, b, orderBy) {
  let value1;
  let value2;

  if (orderBy === 'totalItems') {
    value1 = getCountProductsTotal(a);
    value2 = getCountProductsTotal(b);
  } else {
    value1 = a[orderBy];
    value2 = b[orderBy];
  }

  if (value2 < value1) {
    return -1;
  }
  if (value2 > value1) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'location', numeric: false, disablePadding: false, label: 'Location' },
  { id: 'id', numeric: false, disablePadding: false, label: 'Count ID' },
  { id: 'date', numeric: false, disablePadding: false, label: 'Date' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'totalItems', numeric: false, disablePadding: false, label: 'Total Items' },
];

const scheduledHeadCells = [
  { id: 'location', numeric: false, disablePadding: false, label: 'Location' },
  { id: 'id', numeric: false, disablePadding: false, label: 'Count ID' },
  { id: 'date', numeric: false, disablePadding: false, label: 'Date' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'totalItems', numeric: false, disablePadding: false, label: 'Total Items' },
  { id: 'delete', numeric: false, disablePadding: false, label: '' },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  paper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  table: {
    minWidth: 750,
    height: '100%',
    cursor: 'pointer',
  },
  container: {
    maxHeight: window.innerHeight - 364,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tabPaginationPaper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    border: 'none',
    boxShadow: 'none',
    paddingRight: 20,
    minWidth: 200,
  },
  paginationPaper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItem: 'center',
  },
  tabPagination: {
    display: 'flex',
    alignItems: 'flex-end',
    padding: 10,
  },
}));

const CountsTable = (props) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('date');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const {
    rows,
    openDrawer,
    users,
    subLocations,
    setDetailCount,
    schedules,
    tenantData,
    history,
    tabValue,
    removeScheduledCount,
  } = props;
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const tableHeadCells = tabValue === 2 ? scheduledHeadCells : headCells;

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const openDetailsModal = (count) => {
    history.push(`/counts/${count.id}`);
  };

  const handleChangePageTab = (event, newPage) => {
    setPage(newPage - 1);
  };

  return (
    <Fragment>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer className={classes.container}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <CustomTableHead
                classes={classes}
                headCells={tableHeadCells}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                hideCheckbox
              />
              <TableBody classes={{ root: classes.tableBody }}>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        onClick={(e) => {
                          openDetailsModal(row);
                        }}
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell align="left">
                          <p className="table-black-text m-0">{row.location.name}</p>
                        </TableCell>
                        <TableCell align="left">
                          <p className="table-black-text m-0">{row.id.toUpperCase()}</p>
                        </TableCell>
                        <TableCell align="left">
                          <p className="table-black-text m-0">{formatDate(tenantData.dateFormat, row.date)}</p>
                        </TableCell>
                        <TableCell align="left">
                          <p className="table-black-text m-0">{row.schedule ? row.schedule.name : row.name}</p>
                        </TableCell>
                        <TableCell align="left">
                          <div style={{ fontWeight: 'bold', fontSize: 16, color: tenantData.mainColor }}>
                            {getCountProductsTotal(row)}
                          </div>
                        </TableCell>

                        {tabValue === 2 && (
                          <TableCell align="left">
                            <div className="d-flex flex-end align-center p-r-lg">
                              <img
                                style={{ marginLeft: '24px' }}
                                src={remove}
                                alt="delete"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  removeScheduledCount(row);
                                }}
                              />
                            </div>
                          </TableCell>
                        )}

                        {/* <TableCell align="left">*/}
                        {/*  <div className='d-flex space-around align-center' onClick={(e)=>{e.stopPropagation()}} >*/}
                        {/*    <CountDownload fromTable={true} count={{...row, date: new Date(row.date)}} component={<AssignmentReturnedIcon color='primary'/>}/>*/}
                        {/*  </div>*/}
                        {/*</TableCell>*/}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <Paper className={classes.paginationPaper}>
            <TablePagination
              rowsPerPageOptions={[25, 50, 75, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage="Results"
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <Paper className={classes.tabPaginationPaper}>
              <div
                style={{
                  fontWeight: 'bold',
                  color: page === 0 ? '#dcdfe4' : tenantData.mainColor,
                  cursor: 'pointer',
                }}
                onClick={(e) => {
                  if (page !== 0) {
                    setPage(page - 1);
                  }
                }}
              >
                Prev
              </div>
              <Pagination
                className={classes.tabPagination}
                count={Math.ceil(rows.length / rowsPerPage)}
                page={page + 1}
                hideNextButton
                hidePrevButton
                onChange={handleChangePageTab}
                defaultPage={1}
                color="primary"
              />
              <div
                style={{
                  fontWeight: 'bold',
                  color: page < Math.ceil(rows.length / rowsPerPage) - 1 ? tenantData.mainColor : '#dcdfe4',
                  cursor: 'pointer',
                }}
                onClick={(e) => {
                  if (page < Math.ceil(rows.length / rowsPerPage) - 1) {
                    setPage(page + 1);
                  }
                }}
              >
                Next
              </div>
            </Paper>
          </Paper>
        </Paper>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    tenantData: state.tenant,
    users: state.usersCollection,
    locations: state.locations,
    subLocations: state.subLocations,
  };
};

export default connect(mapStateToProps)(withRouter(CountsTable));
