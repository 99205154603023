import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ResultsTable from './ResultsTable';
import StyledButton from '../../../../shared/Button';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#ffffff',
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: theme.spacing(2, 4, 3),
    width: 1300,
    height: 900,
  },
}));

const ResultsModal = ({ open, handleClose, data }) => {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className="w-100 d-flex align-center">
            <div className="title-text w-100">Upload results</div>
          </div>
          <ResultsTable rows={data.map((item) => ({ ...item, firstName: item.firstName, lastName: item.lastName }))} />
          <StyledButton text="Close" onClick={handleClose} />
        </div>
      </Fade>
    </Modal>
  );
};

export default ResultsModal;
