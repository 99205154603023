import { AUTH_LOGIN, AUTH_SIGN_UP, AUTH_CREATE_BUSINESS, AUTH_RESET } from '../actions/actionTypes';

const initialState = {
  fullName: '',
  email: '',
  password: '',
  businessName: '',
  businessType: '',
  employeeCount: '0-10',
  country: '',
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_LOGIN:
      return { ...state, email: action.value.email, password: action.value.password };
    case AUTH_SIGN_UP:
      return { ...state, email: action.value.email, fullName: action.value.fullName, password: action.value.password };
    case AUTH_CREATE_BUSINESS:
      return {
        ...state,
        businessName: action.value.businessName,
        businessType: action.value.businessType,
        employeeCount: action.value.employeeCount,
        country: action.value.country,
      };
    case AUTH_RESET:
      return initialState;
    default:
      return state;
  }
};

export default authReducer;
