import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import { Link } from 'react-router-dom';

import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import LoadScreen from '../../load-screen/LoadScreen';
import Alert from '../../shared/Alert';

import './reset-password-form.scss';
import { bindActionCreators } from 'redux';
import { resetPassword, checkSignUpData } from '../../../actions/userActions';

const useStyles = makeStyles({
  input: {
    margin: 20,
    alignSelf: 'center',
    '&hover': {
      borderColor: '#00aeef',
    },
  },
  button: {
    backgroundColor: '#00aeef',
    borderColor: '#00aeef',
    float: 'center',
    color: '#ffffff',
    borderRadius: 30,
    paddingRight: 40,
    paddingLeft: 40,
    fontSize: 14,
    textAlign: 'center',
    marginLeft: 12,
    '&:hover': {
      borderColor: '#00aeef',
      color: '#00aeef',
    },
    '&:disabled': {
      color: 'gray',
      backgroundColor: '#f9f9f9',
    },
  },
  buttonBack: {
    backgroundColor: '#00aeef',
    borderColor: '#00aeef',
    float: 'center',
    color: '#ffffff',
    borderRadius: 30,
    width: 150,
    fontSize: 14,
    textAlign: 'center',
    margin: 40,
    '&:hover': {
      borderColor: '#00aeef',
      color: '#00aeef',
    },
  },
});

const ResetPasswordForm = (props) => {
  const classes = useStyles();
  const [submit, setSubmit] = useState(false);
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const changeEmail = (e) => {
    if (e.target.value !== ' ') {
      setEmail(e.target.value);
    } else {
      setEmail('');
      e.preventDefault();
    }
  };

  const onSubmit = (e) => {
    const { resetPassword } = props;

    if (!email) {
      return;
    }

    setIsLoading(true);

    resetPassword(email)
      .then(() => {
        setSubmit(true);
        setIsLoading(false);
      })
      .catch(() => {
        setError(true);
        setIsLoading(false);
      });
  };

  const checkSubmit = () => {
    if (submit === false) {
      return (
        <Fragment>
          <div className="w-100 d-flex flex-center align-center">
            <form className="d-flex direction-column form-container" autoComplete="off">
              <TextField
                className={classes.input}
                id="email"
                type="email"
                label="Email"
                placeholder="E.g email@myemail.com"
                fullWidth
                onKeyDown={(e) => {
                  if (!email)
                    if (e.keyCode === 13) {
                      onSubmit();
                    }
                }}
                onChange={changeEmail}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <div className="d-flex w-100 hint-container">
                <p className="reset-hint-text">
                  Enter your email address and we'll send you details on how to reset your password
                </p>
              </div>
              <div className="d-flex w-100 flex-center align-center">
                <Button
                  disabled={!email}
                  className={classes.button}
                  color="primary"
                  variant="outlined"
                  onClick={onSubmit}
                >
                  Reset
                </Button>
              </div>
            </form>
          </div>
          {isLoading ? <LoadScreen /> : null}
          <Alert variant="error" open={error} message="Email not found" onClose={() => setError(false)} />
        </Fragment>
      );
    } else {
      return (
        <div className="w-100 h-30 d-flex flex-center align-center">
          <div className="w-100 d-flex direction-column flex-center align-center">
            <div className="w-100 d-flex direction-column align-center space-around">
              <div className="circle-check-container d-flex align-center flex-center">
                <Icon style={{ color: '#00AAEF' }}>check_circle</Icon>
                <p className="reset-message-text">A password reset link has been sent to your email</p>
              </div>
              <div className="d-flex w-100 flex-center align-center">
                <Link to="/" style={{ textDecoration: 'none' }}>
                  <Button className={classes.buttonBack} color="primary" variant="outlined">
                    Back to Login
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
  return checkSubmit();
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetPassword,
      checkSignUpData,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(ResetPasswordForm);
