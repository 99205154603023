import { GET_COUNTRIES_DATA, CLEAR_COUNTRIES_DATA } from '../actions/actionTypes';

const initialState = [];

const countriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COUNTRIES_DATA:
      return action.value;
    case CLEAR_COUNTRIES_DATA:
      return initialState;
    default:
      return state;
  }
};

export default countriesReducer;
