import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, Redirect, withRouter } from 'react-router-dom';

import Aside from '../../../components/app/users/aside/UsersAside';
import UsersManager from '../../../components/app/users/users-manager/UsersManager';
import TeamsManager from '../../../components/app/users/teams-manager/TeamsManager';

import './users.scss';
import '../../page.scss';

const Users = (props) => {
  return (
    <Router>
      <div className="page-container">
        <div className="main-container">
          <div className="page-aside-container">
            <Aside />
          </div>
          <div className="page-main-container">
            <Switch>
              <Route exact path="/users" component={withRouter(UsersManager)} />
              <Route path="/users/teams" component={withRouter(TeamsManager)} />
              <Redirect to="/users" />
            </Switch>
          </div>
        </div>
      </div>
    </Router>
  );
};

const mapStateToProps = (state) => {
  return {
    loginData: state.auth,
    userData: state.user,
    tenantData: state.tenant,
  };
};

export default connect(mapStateToProps)(Users);
