import React, { useState, Fragment } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { makeStyles } from '@material-ui/core/styles';

import { getItemById } from '../../../shared/sharedFunctions';
import { changePassword, setCurrentUser, uploadPhoto } from '../../../../actions/userActions';
import { updateUser } from '../../../../actions/usersCollectionActions';

import { Button, TextField, LinearProgress } from '@material-ui/core';
import Avatar from 'react-avatar';
import MuiPhoneNumber from 'material-ui-phone-number';
import StyledButton from '../../../shared/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FaceIcon from '@material-ui/icons/Face';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import LockIcon from '@material-ui/icons/Lock';
import visibility from '../../../../assets/icons/visibility.svg';
import visibilityOff from '../../../../assets/icons/visibility_off.svg';

import './account-manager.scss';
import LoadScreen from '../../../load-screen/LoadScreen';
import Alert from '../../../shared/Alert';
import { displayFullName, FULL_NAME_REGEX } from '../../../../util/helpers';

const useStyles = makeStyles({
  progressBar: {
    height: 8,
    borderRadius: 4,
  },
  visibilityIcon: {
    cursor: 'pointer',
  },
  uploadButton: {
    width: 220,
    height: 220,
    borderRadius: '50%',
  },
  cloudIcon: {
    position: 'absolute',
    top: 108,
    left: 108,
    color: '#aaaaaa',
    zIndex: 1000,
  },
});

const AccountManager = (props) => {
  const { userData, tenant, teams, locations, changePassword, updateUser, setCurrentUser, uploadPhoto } = props;
  const { fullName } = userData;

  const [firstName, setFirstName] = useState(fullName.split(FULL_NAME_REGEX)[0]);
  const [lastName, setLastName] = useState(fullName.split(FULL_NAME_REGEX)[1]);
  const [phone, setPhone] = useState(userData.phone);
  const [photo, setPhoto] = useState(userData.photo);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatNewPassword, setRepeatNewPassword] = useState('');
  const [oldPasswordVisible, setOldPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [newPasswordRepeatVisible, setNewPasswordRepeatVisible] = useState(false);
  const [showUploadIcon, setShowUploadIcon] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorType, setErrorType] = useState('error');
  const [errorText, setErrorText] = useState('');

  let uploadRef;

  const showIcon = () => {
    setShowUploadIcon(true);
  };

  const hideIcon = () => {
    setShowUploadIcon(false);
  };

  const onDeletePhoto = () => {
    setPhoto('');
  };

  const classes = useStyles();

  const handleFileChange = (e) => {
    setIsLoading(true);
    if (e.target.files[0]) {
      uploadPhoto({ file: e.target.files[0], tenantId: tenant.id, userId: userData.id }).then((res) => {
        setIsLoading(false);
        setPhoto(res);
      });
    }
  };

  const onUpdateSubmit = () => {
    setIsLoading(true);
    updateUser({ ...userData, firstName, lastName, phone, lastEmail: userData.email, photo }).then((res) => {
      setCurrentUser(res);
      setIsLoading(false);
    });
  };

  const checkVisiblePassword = (value, setFunction) => {
    return value ? (
      <img
        className={classes.visibilityIcon}
        src={visibilityOff}
        alt="visible"
        onClick={() => {
          setFunction(false);
        }}
      />
    ) : (
      <img
        className={classes.visibilityIcon}
        src={visibility}
        alt="visible"
        onClick={() => {
          setFunction(true);
        }}
      />
    );
  };

  const newPasswordHandleChange = (e) => {
    setNewPassword(e.target.value);
  };

  const repeatNewPasswordHandleChange = (e) => {
    setRepeatNewPassword(e.target.value);
  };

  const oldPasswordHandleChange = (e) => {
    setOldPassword(e.target.value);
  };

  const onChangePassword = () => {
    if (newPassword === repeatNewPassword) {
      setIsLoading(true);
      changePassword(userData.email, oldPassword, newPassword, userData.tenantId)
        .then(() => {
          setIsLoading(false);
          setErrorType('success');
          setErrorText('Password was changed.');
          setError(true);
        })
        .catch(() => {
          setIsLoading(false);
          setErrorType('error');
          setErrorText('Old password is wrong. Please, try again');
          setError(true);
        });
    } else {
      setIsLoading(false);
      setErrorType('error');
      setErrorText('Please fill in all the fields correctly.');
      setError(true);
      setNewPassword('');
      setOldPassword('');
      setRepeatNewPassword('');
    }
  };

  return (
    <Fragment>
      <div className="w-100 h-100 d-flex direction-column">
        <div className="w-100 d-flex direction-column">
          <div className="main-data">
            <div className="avatar-container">
              <div className="p-lg position-relative" onMouseOver={showIcon} onMouseOut={hideIcon}>
                {showUploadIcon ? (
                  <CloudUploadIcon onMouseOver={showIcon} fontSize="large" className={classes.cloudIcon} />
                ) : null}
                <Button
                  className={classes.uploadButton}
                  onClick={(e) => {
                    uploadRef.click();
                  }}
                  onMouseOver={showIcon}
                >
                  <Avatar
                    onMouseOver={showIcon}
                    name={displayFullName(fullName)}
                    src={photo ? photo : ''}
                    size={200}
                    round
                  />
                  <input
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                    ref={(ref) => (uploadRef = ref)}
                  />
                </Button>
              </div>
              {photo ? <StyledButton width="90%" text="Delete" onClick={onDeletePhoto} /> : null}
            </div>
            <div className="main-data-fields">
              <div className="personal-info">
                <div className="d-flex align-center m-t-lg">
                  <div className="m-r-sm d-flex align-end">
                    <FaceIcon color="primary" />
                  </div>
                  <div className="drawer-title">Personal</div>
                </div>
                <div className="input-row m-t-lg">
                  <div className="input-container">
                    <TextField
                      id="first-name"
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                      fullWidth
                      label="First name"
                      placeholder="E.g John"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className="input-container">
                    <TextField
                      id="last-name"
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                      fullWidth
                      label="Last name"
                      placeholder="E.g Doe"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="contact-info">
                <div className="d-flex m-t-lg align-end">
                  <div className="m-r-sm d-flex align-center">
                    <ContactPhoneIcon color="primary" />
                  </div>
                  <div className="drawer-title">Contact Information</div>
                </div>
                <div className="input-row m-t-lg">
                  <div className="input-container">
                    <TextField
                      id="email"
                      fullWidth
                      value={userData.email}
                      disabled
                      label="Email"
                      placeholder="example@gmail.com"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className="input-container">
                    <MuiPhoneNumber
                      value={phone}
                      fullWidth
                      label="Phone number"
                      placeholder="Your phone number"
                      defaultCountry={'au'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="save-button">
            <StyledButton text="Save" onClick={onUpdateSubmit} />
          </div>
        </div>
        <div className="profile-info">
          <div className="main-profile-info">
            <div className="d-flex align-end m-t-lg">
              <div className="m-r-sm d-flex align-center">
                <AccountBoxIcon color="primary" />
              </div>
              <div className="drawer-title">Profile Information</div>
            </div>
            <div className="profile-position">
              <div className="drawer-title">Position:</div>
              <p
                style={{
                  fontSize: 16,
                  color: tenant.mainColor,
                  fontWeight: 'bold',
                  marginLeft: 12,
                }}
              >
                {userData.position}
              </p>
            </div>
            <div className="d-flex m-t-sm">
              <div className="drawer-title">Role:</div>
              <p
                style={{
                  fontSize: 16,
                  color: tenant.mainColor,
                  fontWeight: 'bold',
                  marginLeft: 12,
                }}
              >
                {userData.role}
              </p>
            </div>
            <div className="w-100">
              {/*<div>*/}
              {/*  <div className="d-flex space-between">*/}
              {/*    <div className="drawer-title">Counts Submitted</div>*/}
              {/*    <p style={{*/}
              {/*      fontSize: 16,*/}
              {/*      color: tenant.mainColor,*/}
              {/*      fontWeight: "bold",*/}
              {/*    }}>*/}
              {/*      0/14*/}
              {/*    </p>*/}
              {/*  </div>*/}
              {/*  <div>*/}
              {/*    <LinearProgress*/}
              {/*        variant="determinate"*/}
              {/*        color="primary"*/}
              {/*        className={classes.progressBar}*/}
              {/*        value={50}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*</div>*/}
              <div className="d-flex">
                <div className="w-50">
                  <div className="drawer-title">Teams:</div>
                  <div className="w-100">
                    {userData.teams.map((team) => (
                      <div
                        style={{
                          fontSize: 16,
                          color: tenant.mainColor,
                          fontWeight: 'bold',
                          marginTop: 12,
                        }}
                      >
                        {getItemById(teams, team) ? getItemById(teams, team).name : ''}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="w-50">
                  <div className="drawer-title">Locations:</div>
                  <div className="w-100">
                    {userData.locations.map((location) => (
                      <div
                        style={{
                          fontSize: 16,
                          color: tenant.mainColor,
                          fontWeight: 'bold',
                          marginTop: 12,
                        }}
                      >
                        {getItemById(locations, location) ? getItemById(locations, location).name : ''}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="password-change">
            <div className="d-flex align-center m-t-lg">
              <div className="m-r-sm d-flex align-end">
                <LockIcon color="primary" />
              </div>
              <div className="drawer-title">Reset Password</div>
            </div>
            <div className="reset-input">
              <TextField
                className={classes.input}
                id="password"
                value={oldPassword}
                onChange={oldPasswordHandleChange}
                type={oldPasswordVisible ? 'text' : 'password'}
                label="Previous Password"
                fullWidth
                InputProps={{
                  endAdornment: checkVisiblePassword(oldPasswordVisible, setOldPasswordVisible),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="reset-input">
              <TextField
                className={classes.input}
                id="password"
                type={newPasswordVisible ? 'text' : 'password'}
                value={newPassword}
                onChange={newPasswordHandleChange}
                label="New Password"
                fullWidth
                InputProps={{
                  endAdornment: checkVisiblePassword(newPasswordVisible, setNewPasswordVisible),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="reset-input">
              <TextField
                className={classes.input}
                id="password"
                value={repeatNewPassword}
                onChange={repeatNewPasswordHandleChange}
                type={newPasswordRepeatVisible ? 'text' : 'password'}
                label="Repeat New Password"
                fullWidth
                InputProps={{
                  endAdornment: checkVisiblePassword(newPasswordRepeatVisible, setNewPasswordRepeatVisible),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="reset-submit">
              <div className="reset-button">
                <StyledButton onClick={onChangePassword} text="Reset" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading ? <LoadScreen /> : null}
      <Alert variant={errorType} message={errorText} open={error} onClose={() => setError(false)} />
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changePassword,
      updateUser,
      setCurrentUser,
      uploadPhoto,
    },
    dispatch,
  );

const mapStateToProps = (state) => {
  return {
    userData: state.user,
    tenant: state.tenant,
    teams: state.teams,
    locations: state.locations,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountManager);
