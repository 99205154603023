import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Pagination from '@material-ui/lab/Pagination';
import { Image } from '@material-ui/icons';

import { formatDate } from '../../../../shared/sharedFunctions';
import CustomTableHead from '../../../table/TableHead';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'subLocation',
    numeric: false,
    disablePadding: false,
    label: 'Sub-Location',
  },
  {
    id: 'product',
    numeric: false,
    disablePadding: false,
    disableSort: true,
    label: 'Product',
  },
  { id: 'qty', numeric: false, disablePadding: false, label: 'Qty' },
  { id: 'counter', numeric: false, disablePadding: false, label: 'Counter' },
  { id: 'date', numeric: false, disablePadding: false, label: 'Date/Time' },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  paper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  table: {
    minWidth: 750,
    height: '100%',
    cursor: 'pointer',
  },
  container: {
    maxHeight: window.innerHeight - 471,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tabPaginationPaper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    border: 'none',
    boxShadow: 'none',
    paddingRight: 20,
    minWidth: 200,
  },
  paginationPaper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItem: 'center',
  },
  tabPagination: {
    display: 'flex',
    alignItems: 'flex-end',
    padding: 10,
  },
  valueLabel: {
    color: '#707070',
    fontWeight: 'bold',
  },
  productValue: {
    fontWeight: 'bold',
  },
}));

const CountsTable = (props) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('date');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const { rows, tenantData, gtinReference } = props;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleChangePageTab = (event, newPage) => {
    setPage(newPage - 1);
  };

  const renderRow = (row, index) => {
    const isItemSelected = isSelected(row.id);
    const labelId = `enhanced-table-checkbox-${index}`;

    return (
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
        selected={isItemSelected}
      >
        {!props.hideCheckbox && (
          <TableCell padding="checkbox">
            <Checkbox
              onClick={(event) => handleClick(event, row.lot)}
              checked={isItemSelected}
              inputProps={{ 'aria-labelledby': labelId }}
            />
          </TableCell>
        )}
        <TableCell align="left">
          <p className="table-black-text m-0">{row.subLocation}</p>
        </TableCell>
        <TableCell align="left">{renderProductCell(row)}</TableCell>
        <TableCell align="left" color="primary">
          <div
            className="table-black-text m-0"
            style={{
              fontWeight: 'bold',
              fontSize: 16,
              color: tenantData.mainColor,
            }}
          >
            {row.qty}
          </div>
        </TableCell>
        <TableCell align="left">
          <p className="table-black-text m-0">{row.counter}</p>
        </TableCell>
        <TableCell align="left">
          <p className="table-black-text m-0">{formatDate(tenantData.dateFormat, row.date.toDate())}</p>
        </TableCell>
      </TableRow>
    );
  };

  const renderProductCell = (row) => (
    <div className="d-flex flex-wrap align-center">
      {row.ref && renderProductValue('REF', row.ref)}
      {row.lot && renderProductValue('LOT', row.lot)}
      {row.values &&
        row.values
          .sort((_, el) => {
            if (
              el.label.toLowerCase() === 'ref' ||
              el.label.toLowerCase() === 'reference' ||
              el.label.toLowerCase() === 'company' ||
              el.label.toLowerCase() === 'gtin'
            ) {
              return 1;
            } else if (el.label.toLowerCase() === 'lot') {
              return 1;
            } else {
              return -1;
            }
          })
          .map((item) => renderProductValue(item.label, item.value))}
      {row.imageUrl && renderImageButton(row.imageUrl)}
    </div>
  );

  const renderProductValue = (label, value) => {
    if (label.toLowerCase() === 'expiration date' || label.toLowerCase() === 'expiry') {
      return (
        <div className="m-r-lg">
          <div className={classes.valueLabel}>{label}</div>
          <div className={classes.productValue}>{addDashesToDate(value)}</div>
        </div>
      );
    } else if (label.toLowerCase() === 'gtin') {
      const gtin = gtinReference.find((item) => item.gtin === value);

      return (
        <div className="m-r-lg">
          <div className={classes.valueLabel}>{label}</div>
          <div className={classes.productValue}>{gtin ? gtin.ref : value}</div>
        </div>
      );
    } else {
      return (
        <div className="m-r-lg">
          <div className={classes.valueLabel}>{label}</div>
          <div className={classes.productValue}>{value}</div>
        </div>
      );
    }
  };

  const addDashesToDate = (str) => {
    if (!str) {
      return '';
    }
    return `${str[0]}${str[1]}/${str[2]}${str[3]}/${str[4]}${str[5]}`;
  };

  const renderImageButton = (url) => {
    return (
      <a href={url} download target="_blank">
        <Image color="primary" />
      </a>
    );
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <CustomTableHead
              hideCheckbox={props.hideCheckbox}
              classes={classes}
              headCells={headCells}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody classes={{ root: classes.tableBody }}>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => renderRow(row, index))}
            </TableBody>
          </Table>
        </TableContainer>
        <Paper className={classes.paginationPaper}>
          <TablePagination
            rowsPerPageOptions={[25, 50, 75, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage="Results"
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
          <Paper className={classes.tabPaginationPaper}>
            <div
              style={{
                fontWeight: 'bold',
                color: page === 0 ? '#dcdfe4' : tenantData.mainColor,
                cursor: 'pointer',
              }}
              onClick={(e) => {
                if (page !== 0) {
                  setPage(page - 1);
                }
              }}
            >
              Prev
            </div>
            <Pagination
              className={classes.tabPagination}
              count={Math.ceil(rows.length / rowsPerPage)}
              page={page + 1}
              hideNextButton
              hidePrevButton
              onChange={handleChangePageTab}
              defaultPage={1}
              color="primary"
            />
            <div
              style={{
                fontWeight: 'bold',
                color: page < Math.ceil(rows.length / rowsPerPage) - 1 ? tenantData.mainColor : '#dcdfe4',
                cursor: 'pointer',
              }}
              onClick={(e) => {
                if (page < Math.ceil(rows.length / rowsPerPage) - 1) {
                  setPage(page + 1);
                }
              }}
            >
              Next
            </div>
          </Paper>
        </Paper>
      </Paper>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    tenantData: state.tenant,
    users: state.usersCollection,
    locations: state.locations,
    subLocations: state.subLocations,
    gtinReference: state.gtin.list,
  };
};

export default connect(mapStateToProps)(withRouter(CountsTable));
