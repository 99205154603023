import firebase from '../firebase/Firebase';

import { api } from '../util';
import urls from '../constants/urls';

import { USERS_COLLECTION_GET, USERS_COLLECTION_ADD, USERS_COLLECTION_UPDATE } from './actionTypes';

import { userSchema } from '../errors/schema';

import { createdUserError } from '../errors/validation';

export const getUsersCollection = (tenantId) => async (dispatch) => {
  try {
    const usersSnapshot = await api.post(urls.usersCollection, { tenantId });

    return dispatch({ type: USERS_COLLECTION_GET, value: JSON.parse(usersSnapshot.body) });
  } catch (e) {
    console.log(e);
    return dispatch({ type: USERS_COLLECTION_GET, value: [] });
  }
};

export const createUser = (data) => async (dispatch, getState) => {
  const maxEmployee = getState().tenant.maxEmployee;
  return await api.post(urls.userCreate, { ...data, maxEmployee }).then(async (authResult) => {
    const validation = await userSchema.isValid(data);
    if (!validation) return { status: 'error', error: 'Validation Error. Please, check all the fields' };
    if (authResult.statusCode === 200) {
      const user = JSON.parse(authResult.body);
      firebase.auth.tenantId = data.tenantId;
      await firebase.doResetPassword(user.email);
      dispatch({ type: USERS_COLLECTION_ADD, user });
      return { status: 'success', user };
    } else {
      return { status: 'error', error: JSON.parse(authResult.body).error.message };
    }
  });
};

export const updateUser = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api
      .post(urls.userUpdateAuth, data)
      .then(() => {
        const {
          firstName,
          lastName,
          email,
          tenantId,
          phone,
          role,
          position,
          description,
          locations,
          teams,
          id,
          emailVerified,
          photo,
        } = data;
        const dbUserDoc = {
          fullName: `${firstName}_${lastName}`,
          email,
          tenantId,
          phone,
          role,
          position,
          description,
          locations,
          teams,
          id,
          photo,
          emailVerified,
          active: true,
        };
        api.post(urls.userUpdateDB, dbUserDoc).then((res) => {
          dispatch({
            type: USERS_COLLECTION_UPDATE,
            user: dbUserDoc,
          });
          resolve(dbUserDoc);
        });
      })
      .catch(reject);
  });
};

export const deleteUser = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api
      .post(urls.userDelete, data)
      .then(() => {
        dispatch({
          type: USERS_COLLECTION_UPDATE,
          user: { ...data, active: false },
        });
        resolve();
      })
      .catch(reject);
  });
};
