import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import '../../aside.scss';

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
  },
  mainTab: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  secondaryTab: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#5b667d',
  },
}));

const ReportsAside = ({ location }) => {
  const classes = useStyles();

  return (
    <div className="container d-flex">
      <div className="tabs-list">
        <div className="tab-container">
          <Link to={'/reports'} className={classes.link}>
            <p className={location.pathname === '/reports' ? classes.mainTab : classes.secondaryTab}>Overdue</p>
          </Link>
        </div>
        <div className="tab-container">
          <Link to={'/reports/completed-counts'} className={classes.link}>
            <p className={location.pathname === '/reports/completed-counts' ? classes.mainTab : classes.secondaryTab}>
              Completed Counts
            </p>
          </Link>
        </div>
        <div className="tab-container">
          <Link to={'/reports/items-counted'} className={classes.link}>
            <p className={location.pathname === '/reports/items-counted' ? classes.mainTab : classes.secondaryTab}>
              Items Counted
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ReportsAside);
