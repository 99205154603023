import React from 'react';
import FieldView from './FieldView';
import { Radio } from '@material-ui/core';

const useScanConfig = (scanConfig) => {
  const fields = scanConfig && scanConfig.qrScanConfig ? [...scanConfig.qrScanConfig] : [];
  const scanType = scanConfig && scanConfig.scanType ? scanConfig.scanType : 'QR';

  return { fields, scanType };
};

const CustomBarcodeView = ({ tenant }) => {
  const scanConfig = useScanConfig(tenant.scanConfig);
  const { scanType, fields } = scanConfig;
  return (
    <>
      <div className="drawer-title m-t-lg">Customize decoding (Scanning)</div>
      <div className="w-50 d-flex space-between">
        <div className="d-flex">
          <Radio
            checked={scanType === 'QR'}
            color="primary"
            value="QR"
            name="radio-button-demo"
            inputProps={{ 'aria-label': 'QR' }}
            disabled
          />
          <div className="table-text d-flex align-center">QR Code</div>
        </div>
        <div className="d-flex">
          <Radio
            checked={scanType === 'GTIN'}
            value="GTIN"
            color="primary"
            name="radio-button-demo"
            inputProps={{ 'aria-label': 'GTIN' }}
            disabled
          />
          <div className="table-text d-flex align-center">Data Matrix (GTIN)</div>
        </div>
      </div>
      {scanType === 'QR' ? (
        <>
          <div className="w-100 d-flex m-t-lg">
            <div className="flex-1 table-text">Field Title</div>
            <div className="flex-1 table-text">Section №</div>
          </div>
          <div className="w-100 m-t-md">
            {fields.map((item, index) => (
              <FieldView key={`${item.title}_${index}`} viewOnly {...item} />
            ))}
          </div>
        </>
      ) : null}
    </>
  );
};

export default CustomBarcodeView;
