import React from 'react';
import { TableBody, TableCell, TableRow } from '@material-ui/core';

import { getComparator, stableSort } from '../../../../util/helpers';

import { formatDate } from '../../../shared/sharedFunctions';

export const ItemsCountedTableBody = (props) => {
  const { classes, rows, page, order, orderBy, rowsPerPage, isSelected, handleRowSelect } = props;

  const renderProductValue = (label, value, index) => {
    if (
      label.toLowerCase() === 'expiration date' ||
      label.toLowerCase() === 'expiry' ||
      label.toLowerCase() === 'description'
    ) {
      return;
    } else {
      return (
        <div className="m-r-lg" key={`${index}-${label}`}>
          <div className={classes.valueLabel}>{label}</div>
          <div className={classes.productValue}>{value}</div>
        </div>
      );
    }
  };

  const renderProductCell = (row) => (
    <div className="d-flex flex-wrap align-center space-between">
      {row.ref && renderProductValue('REF', row.ref)}
      {row.lot && renderProductValue('LOT', row.lot)}
      {row.values &&
        row.values
          .sort((_, el) => {
            if (
              el.label.toLowerCase() === 'ref' ||
              el.label.toLowerCase() === 'reference' ||
              el.label.toLowerCase() === 'company' ||
              el.label.toLowerCase() === 'gtin'
            ) {
              return 1;
            } else if (el.label.toLowerCase() === 'lot') {
              return 1;
            } else {
              return -1;
            }
          })
          .map((item, index) => renderProductValue(item.label, item.value, index))}
    </div>
  );

  return (
    <TableBody classes={{ root: classes.tableBody }}>
      {stableSort(rows, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, index) => {
          const isItemSelected = isSelected(row.id);
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <TableRow
              hover
              onClick={(event) => handleRowSelect(event, row.id)}
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={`${row.id}-${index}`}
              selected={isItemSelected}
            >
              <TableCell component="th" id={labelId} scope="row">
                {renderProductCell(row)}
              </TableCell>
              <TableCell align="left">
                <p className="table-black-text m-0">{row.qty}</p>
              </TableCell>
              <TableCell align="left">
                <p className="table-black-text m-0">{row.location.name}</p>
              </TableCell>
              <TableCell align="left">
                <p className="table-black-text m-0">{row.counter}</p>
              </TableCell>
              <TableCell align="left">
                <p className="table-black-text m-0">{formatDate('DD/MM/YYYY', row.date)}</p>
              </TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  );
};
