import firebase from '../firebase/Firebase';
import {
  GTIN_COLLECTION_LOAD,
  GTIN_COLLECTION_ADD,
  GTIN_COLLECTION_DELETE,
  GTIN_COLLECTION_GET,
  GTIN_COLLECTION_UPDATE,
} from './actionTypes';
import { TENANT_DATA_COLLECTION, GTIN_REFERANCE_COLLECTION } from '../firebase/collections';

const setGtinReference = (gtinReference) => ({
  type: GTIN_COLLECTION_GET,
  data: gtinReference,
});

export const getGtinReference = (tenantDataId) => (dispatch) => {
  dispatch({ type: GTIN_COLLECTION_LOAD });

  const path = `${TENANT_DATA_COLLECTION}/${tenantDataId}/${GTIN_REFERANCE_COLLECTION}`;

  return firebase.db
    .collection(path)
    .get()
    .then((snapshot) => snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })))
    .then((gtinReference) => dispatch(setGtinReference(gtinReference)));
};

export const createGtinReference = (tenantDataId, data) => (dispatch) => {
  const path = `${TENANT_DATA_COLLECTION}/${tenantDataId}/${GTIN_REFERANCE_COLLECTION}`;

  const gtinReference = {
    gtin: data.gtin,
    ref: data.ref,
    description: data.description,
    classification: data.classification,
  };

  return firebase.db
    .collection(path)
    .add(gtinReference)
    .then((doc) =>
      dispatch({
        type: GTIN_COLLECTION_ADD,
        data: { ...gtinReference, id: doc.id },
      }),
    );
};

export const updateGtinReference = (tenantDataId, id, data) => (dispatch) => {
  const path = `${TENANT_DATA_COLLECTION}/${tenantDataId}/${GTIN_REFERANCE_COLLECTION}`;

  const gtinReference = {
    gtin: data.gtin,
    ref: data.ref,
    description: data.description,
    classification: data.classification,
  };

  return firebase.db
    .collection(path)
    .doc(id)
    .set(gtinReference, { merge: true })
    .then(() => dispatch({ type: GTIN_COLLECTION_UPDATE, id, data: gtinReference }));
};

export const deleteGtinReference = (tenantDataId, id) => (dispatch) => {
  const path = `${TENANT_DATA_COLLECTION}/${tenantDataId}/${GTIN_REFERANCE_COLLECTION}`;

  return firebase.db
    .collection(path)
    .doc(id)
    .delete()
    .then(() => dispatch({ type: GTIN_COLLECTION_DELETE, id }));
};

export const createGtinReferenceFromCSV = (tenantDataId, data) => async (dispatch) => {
  const path = `${TENANT_DATA_COLLECTION}/${tenantDataId}/${GTIN_REFERANCE_COLLECTION}`;

  return await Promise.all(
    data.map(async (inputData) => {
      const { gtin = '', ref = '', description = '', classification = '' } = inputData;
      const gtinReference = { gtin, ref, description, classification };

      return firebase.db
        .collection(path)
        .add(gtinReference)
        .then((doc) =>
          dispatch({
            type: GTIN_COLLECTION_ADD,
            data: { ...gtinReference, id: doc.id },
          }),
        );
    }),
  );
};
