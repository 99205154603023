import React, { useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import Icon from '@material-ui/core/Icon';
import AddText from '../../../../../shared/AddText';

import './count-teams.scss';
import remove from '../../../../../../assets/icons/delete.svg';
import { displayFullName } from '../../../../../../util/helpers';
import Badge from '@material-ui/core/Badge';
import Avatar from 'react-avatar';
import accountLogo from '../../../../../../assets/icons/account_circle_grey.svg';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

const CountTeams = (props) => {
  const { usersData, teamsData, teams, users, addTeam, removeUser, removeTeam, addUser, tenant } = props;

  const [userName, setUserName] = useState('');
  const [teamName, setTeamName] = useState('');

  const listText = {
    fontWeight: 'bold',
    color: '#5b667d',
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    margin: 0,
    padding: 0,
  };

  const getNames = (collection) => {
    return collection.map((item) => item.name);
  };

  const getFullNames = (collection) => {
    return collection.map((item) => displayFullName(item.fullName));
  };

  const getItemByName = (collection, name) => {
    return collection.find((item) => item.name === name);
  };

  const getItemByFullName = (collection, fullName) => {
    return collection.find((item) => displayFullName(item.fullName) === displayFullName(fullName));
  };

  const getItemById = (collection, id) => {
    return collection.find((item) => item.id === id);
  };

  return (
    <div className="count-teams">
      <div className="users">
        <div className="users-select">
          <div className="w-100 d-flex align-end">
            <div className="flex-1">
              <Autocomplete
                options={getFullNames(usersData.filter((item) => item.active === true))}
                onChange={(e, v) => {
                  setUserName(v);
                }}
                value={userName}
                id="teams"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Users"
                    placeholder="E.g John Doe"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </div>
            <div className="p-l-sm">
              <IconButton
                onClick={(e) => {
                  if (userName) {
                    addUser(getItemByFullName(usersData, userName));
                    setUserName('');
                  }
                  e.preventDefault();
                }}
                aria-label=""
                color={userName ? 'primary' : 'secondary'}
                size="small"
              >
                <Icon color={userName ? 'primary' : 'secondary'}>add_circle_outline</Icon>
              </IconButton>
            </div>
          </div>
        </div>
        <div className="users-list">
          {users &&
            users.map((user) => {
              return (
                <div key={user} className="d-flex w-100 align-center space-between m-t-sm">
                  <p style={listText}>{displayFullName(getItemById(usersData, user).fullName)}</p>
                  <IconButton
                    onClick={(e) => {
                      removeUser(user);
                    }}
                    aria-label=""
                    color="secondary"
                    size="small"
                  >
                    <DeleteOutlineIcon style={{ color: '#d32f2f' }} />
                  </IconButton>
                </div>
              );
            })}
        </div>
      </div>
      <div className="teams">
        <div className="teams-select">
          <div className="d-flex align-end w-100">
            <div className="flex-1">
              <Autocomplete
                options={getNames(teamsData.filter((item) => item.active === true))}
                onChange={(e, v) => {
                  setTeamName(v);
                }}
                value={teamName}
                id="teams"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Teams"
                    placeholder="E.g Team"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </div>
            <div className="p-l-sm">
              <IconButton
                onClick={(e) => {
                  if (teamName) {
                    addTeam(getItemByName(teamsData, teamName));
                    setTeamName('');
                  }
                  e.preventDefault();
                }}
                aria-label=""
                color={teamName ? 'primary' : 'secondary'}
                size="small"
              >
                <Icon color={teamName ? 'primary' : 'secondary'}>add_circle_outline</Icon>
              </IconButton>
            </div>
          </div>
        </div>
        <div className="teams-list">
          {teams &&
            teams.map((team) => {
              return (
                <div key={team} className="d-flex space-between align-center m-t-sm cursor-pointer">
                  <p style={listText}>{getItemById(teamsData, team).name}</p>
                  <IconButton
                    onClick={(e) => {
                      removeTeam(team);
                    }}
                    aria-label=""
                    color="secondary"
                    size="small"
                  >
                    <DeleteOutlineIcon style={{ color: '#d32f2f' }} />
                  </IconButton>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    usersData: state.usersCollection,
    teamsData: state.teams,
    tenant: state.tenant,
  };
};

export default connect(mapStateToProps)(CountTeams);
