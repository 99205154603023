import { SET_DATE, RESET_DATE } from '../actions/actionTypes';

const date = new Date();

const initialState = {
  date,
  startDate: null,
  endDate: null,
  step: 'All',
};

const dateReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATE:
      return action.dateObject;
    case RESET_DATE:
      return initialState;
    default:
      return state;
  }
};

export default dateReducer;
