import {
  LOCATIONS_COLLECTION_ADD,
  LOCATIONS_COLLECTION_UPDATE,
  LOCATIONS_COLLECTION_GET,
  SUB_LOCATIONS_COLLECTION_UPDATE,
} from './actionTypes';

import firebase from '../firebase/Firebase';

import { LOCATION_DATA_COLLECTION, SUB_LOCATION_DATA_COLLECTION } from '../firebase/collections';

export const createLocation = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const location = {
      name: data.name,
      description: data.description,
      note: data.note,
      address: data.address,
      coordinate: data.coordinate,
      tenantId: data.tenantId,
      region: data.region,
      postcode: data.postcode,
      active: true,
    };
    firebase
      .addToCollection(LOCATION_DATA_COLLECTION, location)
      .then((res) => {
        const promises = [];

        data.subLocations.forEach((item) => {
          promises.push(
            firebase.updateItem(SUB_LOCATION_DATA_COLLECTION, { ...item, location: res }).then(() => {
              dispatch({
                type: SUB_LOCATIONS_COLLECTION_UPDATE,
                subLocation: { ...item, location: res },
              });
            }),
          );
        });

        Promise.all(promises).then(() => {
          location.id = res;
          dispatch({
            type: LOCATIONS_COLLECTION_ADD,
            location,
          });
          resolve();
        });
      })
      .catch(reject);
  });
};

export const createLocationsFromCSV = (tenantId, data) => async (dispatch) => {
  return await Promise.all(
    data.map(async (inputData) => {
      const { name = '', description = '', address = '', region = '', postcode = '', note = '' } = inputData;
      const location = { name, description, address, region, postcode, note, tenantId, active: true, coordinate: '' };

      return firebase.db
        .collection(LOCATION_DATA_COLLECTION)
        .add(location)
        .then((doc) =>
          dispatch({
            type: LOCATIONS_COLLECTION_ADD,
            location: { ...location, id: doc.id },
          }),
        );
    }),
  );
};

export const getLocations = (tenantId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    firebase
      .getCollection(LOCATION_DATA_COLLECTION, tenantId)
      .then((res) => {
        dispatch({ type: LOCATIONS_COLLECTION_GET, value: res });
        resolve();
      })
      .catch(reject);
  });
};

export const updateLocation = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const updatedLocation = {
      id: data.id,
      name: data.name,
      description: data.description,
      note: data.note,
      address: data.address,
      tenantId: data.tenantId,
      coordinate: data.coordinate,
      region: data.region,
      postcode: data.postcode,
      active: true,
    };
    firebase
      .updateItem(LOCATION_DATA_COLLECTION, updatedLocation)
      .then(() => {
        const promises = [];
        data.removedSubLocations.forEach((item) => {
          const updatedSubLocation = { ...item, location: '' };
          promises.push(
            firebase.updateItem(SUB_LOCATION_DATA_COLLECTION, updatedSubLocation).then(() => {
              dispatch({ type: SUB_LOCATIONS_COLLECTION_UPDATE, subLocation: updatedSubLocation });
            }),
          );
        });
        data.subLocations.forEach((item) => {
          const updatedSubLocation = { ...item, location: data.id };
          promises.push(
            firebase.updateItem(SUB_LOCATION_DATA_COLLECTION, updatedSubLocation).then(() => {
              dispatch({ type: SUB_LOCATIONS_COLLECTION_UPDATE, subLocation: updatedSubLocation });
            }),
          );
        });

        Promise.all(promises).then(() => {
          dispatch({ type: LOCATIONS_COLLECTION_UPDATE, location: updatedLocation });
          resolve();
        });
      })
      .catch(() => {
        reject();
      });
  });
};

export const removeLocation = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    firebase
      .disableItem(LOCATION_DATA_COLLECTION, {
        id: data.id,
        coordinate: data.coordinate,
        description: data.description,
        name: data.name,
        note: data.note,
        tenantId: data.tenantId,
        address: data.address,
      })
      .then((res) => {
        const promises = [];

        data.subLocations.forEach((item) => {
          promises.push(
            firebase.updateItem(SUB_LOCATION_DATA_COLLECTION, { ...item, location: '' }).then((res) => {
              dispatch({
                type: SUB_LOCATIONS_COLLECTION_UPDATE,
                subLocation: { ...item, location: '' },
              });
            }),
          );
        });

        Promise.all(promises).then(() => {
          dispatch({
            type: LOCATIONS_COLLECTION_UPDATE,
            location: {
              id: data.id,
              coordinate: data.coordinate,
              description: data.description,
              name: data.name,
              note: data.note,
              tenantId: data.tenantId,
              address: data.address,
              active: false,
            },
          });
          resolve();
        });
      })
      .catch(() => {
        reject();
      });
  });
};
