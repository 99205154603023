import {
  TEAM_COLLECTION_ADD,
  TEAM_COLLECTION_UPDATE,
  TEAM_COLLECTION_CLEAR,
  TEAM_COLLECTION_DELETE,
  TEAM_COLLECTION_GET,
} from '../actions/actionTypes';

const initialState = [];

const teamsReducer = (state = initialState, action) => {
  switch (action.type) {
    case TEAM_COLLECTION_ADD:
      return [...state, action.team];
    case TEAM_COLLECTION_UPDATE:
      return state.map((item) => {
        if (item.id === action.team.id) {
          return action.team;
        }
        return item;
      });
    case TEAM_COLLECTION_DELETE:
      return state.filter((item) => item.id !== action.value);
    case TEAM_COLLECTION_GET:
      return action.value;
    case TEAM_COLLECTION_CLEAR:
      return initialState;
    default:
      return state;
  }
};

export default teamsReducer;
