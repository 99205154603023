import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { removeTeam } from '../../../../actions/teamsCollectionActions';
import { deleteSchedule } from '../../../../actions/schedulesActions';
import { searchFilter } from '../../../shared/sharedFunctions';

import SchedulesTable from './schedules-table/SchedulesTable';
import StyledButton from '../../../shared/Button';
import SchedulesModal from './schedules-modal/SchedulesModal';

import { IconButton, InputAdornment, OutlinedInput, FormControl, InputLabel } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import '../../manager.scss';

const SchedulesManager = (props) => {
  const { schedulesData, teamsData, deleteSchedule } = props;

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [modalEditMode, setModalEditMode] = useState(false);
  const [modalEditSchedule, setModalEditSchedule] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [searchVisible, setSearchVisible] = useState(false);

  const openDrawer = () => {
    setDrawerIsOpen(true);
  };

  const closeDrawer = () => {
    setDrawerIsOpen(false);
    setModalEditMode(false);
  };

  const setEditSchedule = (id) => {
    setModalEditSchedule(schedulesData.find((item) => id === item.id));
  };

  return (
    <Fragment>
      <div className="manager-container">
        <div className="manager-head">
          <div className="page-title-container">
            <h3 className="page-title">Schedule</h3>
            <p className="count">{`(${schedulesData.filter((item) => item.active === true).length})`}</p>
          </div>
          <div className="search-container">
            <div className="search-input d-flex align-start flex-center">
              {searchVisible ? (
                <div className="search-input-container">
                  <FormControl size="small" variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Search...</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      value={searchValue}
                      type="text"
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <div
                            className="h-100 d-flex align-center cursor-pointer"
                            onClick={() => {
                              setSearchValue('');
                              setSearchVisible(false);
                            }}
                          >
                            <SearchIcon />
                          </div>
                        </InputAdornment>
                      }
                      labelWidth={70}
                    />
                  </FormControl>
                </div>
              ) : (
                <IconButton
                  onClick={() => {
                    setSearchVisible(true);
                  }}
                >
                  <SearchIcon />
                </IconButton>
              )}
            </div>
          </div>
          <div>
            <StyledButton onClick={openDrawer} text="Add Schedule" />
          </div>
        </div>
        <div className="table-switch"></div>
        <div className="table flex-1">
          <SchedulesTable
            rows={searchFilter(
              schedulesData.filter((item) => item.active === true),
              searchValue,
            )}
            setModalEditMode={setModalEditMode}
            setEditSchedule={setEditSchedule}
            openDrawer={openDrawer}
            deleteSchedule={deleteSchedule}
          />
        </div>
      </div>
      <SchedulesModal
        editMode={modalEditMode}
        teamsData={teamsData}
        drawerIsOpen={drawerIsOpen}
        closeDrawer={closeDrawer}
        editSchedule={modalEditSchedule}
      />
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      removeTeam,
      deleteSchedule,
    },
    dispatch,
  );

const mapStateToProps = (state) => {
  return {
    usersCollection: state.usersCollection,
    teamsData: state.teams,
    userData: state.user,
    schedulesData: state.schedules,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SchedulesManager);
