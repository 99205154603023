import { COUNTS_COLLECTION_GET, COUNTS_COLLECTION_ADD, COUNTS_COLLECTION_DELETE } from './actionTypes';
import {
  COUNTS_COLLECTION,
  LOCATION_DATA_COLLECTION,
  SUB_LOCATION_DATA_COLLECTION,
  TEAM_DATA_COLLECTION,
  SCHEDULE_DATA_COLLECTION,
  USER_DATA_COLLECTION,
} from '../firebase/collections';

import firebase from '../firebase/Firebase';

export const getCounts = (tenantId) => async (dispatch) => {
  try {
    const countsCollection = await firebase.getCollection(COUNTS_COLLECTION, tenantId);
    Promise.all(
      countsCollection.map(async (countData) => {
        const date = countData.date.toDate();
        const location = await countData.location.get().then((res) => {
          return { ...res.data(), id: res.id };
        });
        const schedule = countData.schedule
          ? await countData.schedule.get().then((res) => ({
              ...res.data(),
              id: res.id,
            }))
          : null;
        const usersPromises = countData.users
          .filter((user) => !!user.get)
          .map((user) => user.get().then((res) => ({ ...res.data(), id: res.id })));
        const teamsPromises = countData.teams.map((team) => team.get().then((res) => ({ ...res.data(), id: res.id })));
        const subLocationsPromises = countData.subLocations.map((subLocation) => {
          if (!!subLocation.get) {
            return subLocation.get().then((res) => ({
              ...res.data(),
              id: res.id,
            }));
          }

          return {
            name: subLocation,
            id: subLocation,
          };
        });
        const users = await Promise.all(usersPromises).then((res) => res);
        const teams = await Promise.all(teamsPromises).then((res) => res);
        const subLocations = await Promise.all(subLocationsPromises).then((res) => res);

        return { ...countData, date, users, location, schedule, teams, subLocations };
      }),
    ).then((value) => dispatch({ type: COUNTS_COLLECTION_GET, value }));
  } catch (e) {
    console.log(e);
  }
};

export const getSingleCount = (id) => async (dispatch) => {
  try {
    const countFetched = await firebase.getItemFromCollection(COUNTS_COLLECTION, id);
    const locationFetched = await countFetched.location.get();
    const location = { ...locationFetched.data(), id: locationFetched.id };
    const scheduleFetched = countFetched.schedule ? await countFetched.schedule.get() : null;
    const schedule = scheduleFetched ? { ...scheduleFetched.data(), id: scheduleFetched.id } : null;
    const userPromises = countFetched.users
      .filter((user) => !!user.get)
      .map((user) =>
        user.get().then((res) => ({
          ...res.data(),
          id: res.id,
        })),
      );
    const users = await Promise.all(userPromises).then((res) => res);
    const teamsPromises = countFetched.teams.map((team) => team.get().then((res) => ({ ...res.data(), id: res.id })));
    const subLocationsPromises = countFetched.subLocations.map((subLocation) => {
      if (!!subLocation.get) {
        return subLocation.get().then((res) => ({
          ...res.data(),
          id: res.id,
        }));
      }

      return {
        name: subLocation,
        id: subLocation,
      };
    });
    const teams = await Promise.all(teamsPromises);
    const subLocations = await Promise.all(subLocationsPromises);

    if (countFetched.scans) {
      Object.keys(countFetched.scans).forEach((key) => {
        countFetched.scans[key].products.forEach(async (product) => {
          if (product.imagePath) {
            product.imageUrl = await firebase.getDownloadUrl(product.imagePath);
          }
        });
      });
    }

    return { ...countFetched, location, schedule, users, teams, subLocations };
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const createCount = (data, customID) => () => {
  return new Promise((resolve, reject) => {
    const count = {
      ...data,
      location: firebase.db.collection(LOCATION_DATA_COLLECTION).doc(data.location),
      id: customID,
      schedule: data.schedule ? firebase.db.collection(SCHEDULE_DATA_COLLECTION).doc(data.schedule) : null,
      subLocations: data.subLocations.map((item) => firebase.db.collection(SUB_LOCATION_DATA_COLLECTION).doc(item)),
      users: data.users?.map((item) => firebase.db.collection(USER_DATA_COLLECTION).doc(item)),
      teams: data.teams?.map((item) => firebase.db.collection(TEAM_DATA_COLLECTION).doc(item)),
    };
    firebase.addToCollectionWithCustomId(COUNTS_COLLECTION, count, customID).then(() => {
      // dispatch({type: COUNTS_COLLECTION_ADD,
      //   count: {
      //     ...data,
      //     id: customID,
      //     location,
      //     date: new Date(data.date.getFullYear(), data.date.getMonth(), data.date.getDate())
      //   }
      // });
      resolve();
    });
  });
};

export const updateCount = (id, count) => () => {
  return firebase.setItem(COUNTS_COLLECTION, id, count);
};

export const createCountsScript = () => (dispatch) => {
  const countIds = ['EX', 'EX2', 'EX3', 'EX4', 'EX5', 'EX6', 'EX7', 'EX8', 'EX9', 'EX10'];
  const active = true;
  const location = firebase.db.collection(LOCATION_DATA_COLLECTION).doc('VtTQoVToB20lfKsBPNPC');
  const subLocationsIds = ['I6iQDD8sxYtOh4wxE0sx', 'o5VN34hLzmSzPlhVexVN'];
  const subLocations = subLocationsIds.map((id) => firebase.db.collection(SUB_LOCATION_DATA_COLLECTION).doc(id));
  const teams = [];
  const usersIds = ['vZUY4enrbsMlHnKotTSbS879AWu2'];
  const users = usersIds.map((id) => firebase.db.collection(USER_DATA_COLLECTION).doc(id));
  const schedule = null;
  const tenantId = 'Sednor-tg71h';
  const userId = 'vZUY4enrbsMlHnKotTSbS879AWu2';
  // const randomString = Math.random().toString(36).substring(7, 15) + Math.random().toString(36).substring(7, 15);
  // const randomScansQuantity = Math.floor(Math.random() * Math.floor(12));
  const array1 = [1, 2, 3, 4, 7, 9, 4, 2, 4, 5, 6, 7, 8, 8, 9, 0, 3, 2, 3];

  const countsObjects = countIds.map((localId, index) => {
    const notes = `Notes for Count Number ${index}, id - ${localId}`;
    const name = `Name TestCount ${index}`;
    const scans = {};
    subLocationsIds.forEach((id) => {
      scans[id] = {
        submitted: true,
        userId,
        products: array1.map((index) => {
          return {
            lot: Math.random().toString(36).substring(7, 15) + Math.random().toString(36).substring(7, 15),
            ref: Math.random().toString(36).substring(7, 15) + Math.random().toString(36).substring(7, 15),
            quantity: Math.floor(Math.random() * Math.floor(12)),
          };
        }),
        submittedAt: new Date(),
      };
    });
    return {
      active,
      name,
      location,
      notes,
      scans,
      subLocations,
      teams,
      schedule,
      tenantId,
      users,
      date: new Date(Date.now()),
    };
  });

  countsObjects.forEach((count, index) => {
    firebase.db
      .collection(COUNTS_COLLECTION)
      .doc(`${countIds[index]}${Date.now()}`.toUpperCase())
      .set(count)
      .then((res) => {
        console.log('success');
      })
      .catch((e) => console.log(e));
  });
};

export const deleteCount = (id) => (dispatch) => {
  return firebase.db
    .collection(COUNTS_COLLECTION)
    .doc(id)
    .delete()
    .then(() => dispatch({ type: COUNTS_COLLECTION_DELETE, id }));
};
