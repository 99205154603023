import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Autocomplete } from '@material-ui/lab';
import { TextField, Input, MenuItem, FormControl, Select, Icon, IconButton, InputLabel } from '@material-ui/core/';

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import { sortAlphaNum } from '../../../../../../util/helpers';

import AddText from '../../../../../shared/AddText';
import remove from '../../../../../../assets/icons/delete.svg';

import './count-locations.scss';

const CountLocations = (props) => {
  const {
    locationsData,
    subLocationsData,
    setLocation,
    tenant,
    subLocations,
    location,
    removeSubLocation,
    addSubLocation,
    resetSubLocations,
  } = props;
  const [locationId, setLocationId] = useState('');
  const [subLocationName, setSubLocationName] = useState([]);

  const listText = {
    fontWeight: 'bold',
    color: '#5b667d',
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    margin: 0,
    padding: 0,
  };

  const getNames = (collection) => {
    return collection.map((item) => item.name);
  };

  const getSubLocationsData = () => {
    const temp = subLocationsData.filter(
      (item) => item.location === locationId && item.active === true && !subLocations.includes(item.id),
    );
    return temp.map((item) => item.name).sort(sortAlphaNum);
  };

  const getItemByName = (collection, name) => {
    return collection.filter((item) => name.includes(item.name));
  };

  const getItemById = (collection, id) => {
    return collection.find((item) => item.id === id);
  };

  const handleSelectChange = (event) => {
    setSubLocationName(event.target.value);
  };

  return (
    <div className="count-locations">
      <div className="locations-input">
        <Autocomplete
          options={getNames(locationsData.filter((item) => item.active === true))}
          onChange={(e, v) => {
            setLocation(v);
            resetSubLocations();
            setSubLocationName([]);
            if (locationsData.find((item) => item.name === v)) {
              setLocationId(locationsData.find((item) => item.name === v).id);
            } else {
              setLocationId('');
            }
          }}
          value={location}
          id="locations"
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label="Main Location"
              placeholder="E.g  Warehouse"
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
      </div>
      <div className="count-sub-locations">
        <div className="count-sub-location-select">
          <div className="d-flex align-end">
            <div className="flex-1">
              <FormControl fullWidth>
                <InputLabel id="label-sub-locations">Sub Locations</InputLabel>
                <Select
                  labelId="label-sub-locations"
                  id="sub-locations"
                  multiple
                  fullWidth
                  value={subLocationName}
                  onChange={handleSelectChange}
                  input={<Input />}
                >
                  {getSubLocationsData().map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}

                  {!getSubLocationsData().length && (
                    <MenuItem value={''} disabled>
                      <em>None</em>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
            <div className="p-l-sm">
              <IconButton
                onClick={() => {
                  addSubLocation(getItemByName(subLocationsData, subLocationName));
                  setSubLocationName([]);
                }}
                color={subLocationName ? 'primary' : 'secondary'}
                aria-label=""
                size="small"
              >
                <Icon color={subLocationName ? 'primary' : 'secondary'}>add_circle_outline</Icon>
              </IconButton>
            </div>
          </div>
        </div>
      </div>
      <div className="sub-locations-list">
        {subLocations.map((subLocation) => {
          return (
            <div key={subLocation} className="d-flex w-50 space-between item align-center m-t-sm">
              <div style={listText}>{getItemById(subLocationsData, subLocation).name}</div>
              <IconButton
                onClick={() => {
                  removeSubLocation(subLocation);
                }}
                aria-label=""
                color="secondary"
                size="small"
              >
                <DeleteOutlineIcon style={{ color: '#d32f2f' }} />
              </IconButton>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    locationsData: state.locations,
    subLocationsData: state.subLocations,
    tenant: state.tenant,
  };
};

export default connect(mapStateToProps)(CountLocations);
