export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_SIGN_UP = 'AUTH_SIGN_UP';
export const AUTH_CREATE_BUSINESS = 'AUTH_CREATE_BUSINESS';
export const AUTH_RESET = 'AUTH_RESET';

export const USER_SIGN_IN = 'USER_SIGN_IN';
export const USER_SIGN_OUT = 'USER_SIGN_OUT';
export const USER_PHOTO_UPDATE = 'USER_PHOTO_UPDATE';

export const USERS_COLLECTION_GET = 'USERS_COLLECTION_GET';
export const USERS_COLLECTION_CLEAR = 'USERS_COLLECTION_CLEAR';
export const USERS_COLLECTION_ADD = 'USERS_COLLECTION_ADD';
export const USERS_COLLECTION_UPDATE = 'USERS_COLLECTION_UPDATE';
export const USERS_COLLECTION_DELETE = 'USERS_COLLECTION_DELETE';

export const TENANT_SIGN_IN = 'TENANT_SIGN_IN';
export const TENANT_SIGN_OUT = 'TENANT_SIGN_OUT';
export const TENANT_UPDATE = 'TENANT_UPDATE';
export const TENANT_UPDATE_SCAN = 'TENANT_UPDATE_SCAN';

export const GET_COUNTRIES_DATA = 'GET_COUNTRIES_DATA';
export const CLEAR_COUNTRIES_DATA = 'CLEAR_COUNTRIES_DATA';

export const TEAM_COLLECTION_ADD = 'TEAMS_COLLECTION_ADD';
export const TEAM_COLLECTION_UPDATE = 'TEAMS_COLLECTION_UPDATE';
export const TEAM_COLLECTION_GET = 'TEAMS_COLLECTION_GET';
export const TEAM_COLLECTION_CLEAR = 'TEAMS_COLLECTION_CLEAR';
export const TEAM_COLLECTION_DELETE = 'TEAMS_COLLECTION_DELETE';

export const LOCATIONS_COLLECTION_ADD = 'LOCATIONS_COLLECTION_ADD';
export const LOCATIONS_COLLECTION_UPDATE = 'LOCATIONS_COLLECTION_UPDATE';
export const LOCATIONS_COLLECTION_GET = 'LOCATIONS_COLLECTION_GET';
export const LOCATIONS_COLLECTION_CLEAR = 'LOCATIONS_COLLECTION_CLEAR';
export const LOCATIONS_COLLECTION_DELETE = 'LOCATIONS_COLLECTION_DELETE';

export const SUB_LOCATIONS_COLLECTION_ADD = 'SUB_LOCATIONS_COLLECTION_ADD';
export const SUB_LOCATIONS_COLLECTION_UPDATE = 'SUB_LOCATIONS_COLLECTION_UPDATE';
export const SUB_LOCATIONS_COLLECTION_GET = 'SUB_LOCATIONS_COLLECTION_GET';
export const SUB_LOCATIONS_COLLECTION_CLEAR = 'SUB_LOCATIONS_COLLECTION_CLEAR';
export const SUB_LOCATIONS_COLLECTION_DELETE = 'SUB_LOCATIONS_COLLECTION_DELETE';

export const SCHEDULES_COLLECTION_ADD = 'SCHEDULES_COLLECTION_ADD';
export const SCHEDULES_COLLECTION_UPDATE = 'SCHEDULES_COLLECTION_UPDATE';
export const SCHEDULES_COLLECTION_GET = 'SCHEDULES_COLLECTION_GET';
export const SCHEDULES_COLLECTION_CLEAR = 'SCHEDULES_COLLECTION_CLEAR';
export const SCHEDULES_COLLECTION_DELETE = 'SCHEDULES_COLLECTION_DELETE';

export const COUNTS_COLLECTION_ADD = 'COUNTS_COLLECTION_ADD';
export const COUNTS_COLLECTION_UPDATE = 'COUNTS_COLLECTION_UPDATE';
export const COUNTS_COLLECTION_GET = 'COUNTS_COLLECTION_GET';
export const COUNTS_COLLECTION_CLEAR = 'COUNTS_COLLECTION_CLEAR';
export const COUNTS_COLLECTION_DELETE = 'COUNTS_COLLECTION_DELETE';

export const GTIN_COLLECTION_LOAD = 'GTIN_COLLECTION_LOAD';
export const GTIN_COLLECTION_ADD = 'GTIN_COLLECTION_ADD';
export const GTIN_COLLECTION_UPDATE = 'GTIN_COLLECTION_UPDATE';
export const GTIN_COLLECTION_GET = 'GTIN_COLLECTION_GET';
export const GTIN_COLLECTION_DELETE = 'GTIN_COLLECTION_DELETE';

export const SET_DATE = 'SET_DATE';
export const RESET_DATE = 'RESET_DATE';
