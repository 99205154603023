import React, { Fragment, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Members from './Members';
import TeamDetails from './TeamDetails';
import StyledButton from '../../../../shared/Button';

import { createTeam, updateTeam } from '../../../../../actions/teamsCollectionActions';

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import LoadScreen from '../../../../load-screen/LoadScreen';
import Alert from '../../../../shared/Alert';

import '../../users-manager/create-user-modal/create-user-modal.scss';
import CancelButton from '../../../../shared/CancelButton';

const useStyles = makeStyles({
  drawer: {
    width: '100%',
  },
  drawerPaper: {
    boxShadow: '-4px 1px 13px 1px  rgba(87,73,87,1)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '50%',
  },
  nameInput: {
    width: '40%',
  },
  positionInput: {
    width: '100%',
  },
  tab: {
    marginTop: 20,
    marginBottom: 20,
    borderBottom: '1px solid #E9E9E9',
    padding: 0,
  },
  tabItem: {
    display: 'flex',
    padding: '0 !important',
    justifyContent: 'flex-start !important',
  },
  radioGroup: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  startTextWrapper: {
    alignItems: 'flex-start !important',
  },
});

const TeamModal = (props) => {
  const classes = useStyles();
  const { drawerIsOpen, closeDrawer, tenantData, createTeam, usersCollection, editMode, editTeam, updateTeam } = props;

  useEffect(() => {
    const stateEditSet = (editTeam) => {
      if (editMode) {
        setName(editTeam.name);
        setDescription(editTeam.description);
        setMembers(usersCollection.filter((user) => user.teams.some((team) => team === editTeam.id)));
        setInitialMembersList(usersCollection.filter((user) => user.teams.some((team) => team === editTeam.id)));
      } else {
        stateReset();
      }
    };

    stateEditSet(editTeam);
  }, [editMode, editTeam, usersCollection]);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [members, setMembers] = useState([]);
  const [drawerTabValue, setDrawerTabValue] = useState(0);
  const [error, setError] = useState(false);
  const [errorType, setErrorType] = useState('error');
  const [errorText, setErrorText] = useState('');
  const [initialMembersList, setInitialMembersList] = useState([]);

  const addMember = (member) => {
    if (!member || members.some((item) => item.id === member.id)) {
      return;
    }
    setMembers([...members, member]);
  };

  const removeMember = (member) => {
    setMembers(members.filter((item) => item.id !== member.id));
  };

  const stateReset = () => {
    setName('');
    setDescription('');
    setMembers([]);
    setDrawerTabValue(0);
  };

  const collectData = () => {
    return { name, description, members, tenantId: tenantData.id };
  };

  // ---------------------Not actual ----------------//
  const [isLoading, setIsLoading] = useState(false);

  const drawerTabValueHandleChange = (event, newValue) => {
    setDrawerTabValue(newValue);
  };

  const onSubmit = (e) => {
    setIsLoading(true);
    if (members.length === 0 || !name) {
      setIsLoading(false);
      setErrorText('Input all required fields');
      setErrorType('warning');
      setError(true);
      return;
    }

    if (editMode) {
      updateTeam({
        name,
        description,
        active: editTeam.active,
        members,
        initialMembersList,
        id: editTeam.id,
      })
        .then(() => {
          setIsLoading(false);
          closeDrawer();
        })
        .catch(() => {
          setIsLoading(false);
          closeDrawer();
          setErrorText('Permission denied');
          setErrorType('error');
          setError(true);
        });
    } else {
      createTeam(collectData())
        .then(() => {
          setIsLoading(false);
          closeDrawer();
        })
        .catch(() => {
          setIsLoading(false);
          closeDrawer();
          setErrorText('Permission denied');
          setErrorType('error');
          setError(true);
        });
    }
  };

  return (
    <Fragment>
      <Drawer
        className={classes.drawer}
        anchor={'right'}
        open={drawerIsOpen}
        classes={{ paperAnchorRight: classes.drawerPaper }}
        ModalProps={{ onBackdropClick: closeDrawer }}
      >
        <div className="drawer-container">
          <div className="drawer-title">{editMode ? 'Edit Team' : 'Add Team'}</div>
          {editMode ? <div className="table-black-text">{editTeam.name}</div> : null}
          <Paper elevation={0}>
            <Tabs
              value={drawerTabValue}
              indicatorColor="primary"
              textColor="primary"
              className={classes.tab}
              onChange={drawerTabValueHandleChange}
            >
              <Tab className={classes.tabItem} classes={{ wrapper: classes.startTextWrapper }} label="Team Details" />
              <Tab className={classes.tabItem} label="Team Members" />
            </Tabs>
          </Paper>
          {drawerTabValue === 0 ? (
            <TeamDetails name={name} description={description} setName={setName} setDescription={setDescription} />
          ) : (
            <Members
              addMember={addMember}
              removeMember={removeMember}
              members={members}
              usersCollection={usersCollection}
            />
          )}
        </div>
        <div className="submit-modal-footer">
          <CancelButton onClick={closeDrawer} />
          <StyledButton text="Save" onClick={onSubmit} />
        </div>
      </Drawer>
      {isLoading ? <LoadScreen /> : null}
      <Alert variant={errorType} message={errorText} open={error} onClose={() => setError(false)} />
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createTeam,
      updateTeam,
    },
    dispatch,
  );

const mapStateToProps = (state) => {
  return {
    tenantData: state.tenant,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamModal);
