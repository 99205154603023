import React from 'react';
import PropTypes from 'prop-types';

import { TableCell, TableHead, TableRow, TableSortLabel, Checkbox } from '@material-ui/core';

const headCells = [
  { id: 'gtin', numeric: false, disablePadding: true, label: 'GTIN' },
  { id: 'ref', numeric: false, disablePadding: false, label: 'REF' },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
  },
  {
    id: 'classification',
    numeric: false,
    disablePadding: false,
    label: 'Classification',
  },
  { id: 'edit', numeric: false, disablePadding: false, label: ' ' },
];

export const GtinHeadTable = (props) => {
  const { classes, order, orderBy, onRequestSort, numSelected, rowCount, onSelectAllClick } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            className={classes.checked}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

GtinHeadTable.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
