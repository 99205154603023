import React, { memo } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import LoadIcon from './LoadIcon';

import './load-screen.scss';

const LoadScreen = () => {
  return (
    <div className="load-screen">
      <div>
        <LoadIcon />
      </div>
    </div>
  );
};

export default memo(LoadScreen);
