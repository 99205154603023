import React, { useEffect } from 'react';

import { TextField } from '@material-ui/core';

import './customize-settings.scss';
import { formatDate } from '../../../shared/sharedFunctions';
import CustomBarcodeView from '../custom-barcode/CustomBarcodeView';

const CustomizeSettings = (props) => {
  const { tenant, openDrawer } = props;

  useEffect(() => {}, [props.tenant]);

  return (
    <div className="w-100 h-100 d-flex direction-column d-flex direction-column">
      <div className="customize-item">
        <div className="drawer-title">Colour</div>
        <div className="w-100 m-t-lg">
          <TextField
            fullWidth
            label="Main Colour"
            value={tenant.mainColor}
            InputProps={{
              startAdornment: (
                <div
                  style={{
                    width: 20,
                    height: 20,
                    background: tenant.mainColor,
                    marginRight: 10,
                    borderRadius: 4,
                  }}
                ></div>
              ),
              readOnly: true,
            }}
            onClick={openDrawer}
          />
        </div>
      </div>
      <div className="customize-item">
        <div className="drawer-title">Company Logo</div>
        <div className="w-100 d-flex m-t-lg">
          <TextField
            fullWidth
            label="Logo file"
            value={tenant.logoFile.name}
            InputProps={{
              readOnly: true,
            }}
            onClick={openDrawer}
          />
        </div>
      </div>
      <div className="customize-item">
        <div className="drawer-title">Data Format</div>
        <div className="w-100 m-t-lg d-flex space-between">
          <div className="w-40">
            <TextField
              fullWidth
              label="Select Format"
              value={tenant.dateFormat}
              InputProps={{
                readOnly: true,
              }}
              onClick={openDrawer}
            />
          </div>
          <div className="w-40">
            <TextField
              fullWidth
              label="Example Data"
              value={formatDate(tenant.dateFormat, new Date())}
              InputProps={{
                readOnly: true,
              }}
              onClick={openDrawer}
            />
          </div>
        </div>
      </div>
      <CustomBarcodeView tenant={tenant} />
    </div>
  );
};

export default CustomizeSettings;
