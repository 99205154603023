import { IconButton } from '@material-ui/core';
import { PostAdd } from '@material-ui/icons';

const SheetJSFT = ['xlsx', 'xls', 'csv'].map((x) => `.${x}`).join(',');

export const FileUpload = ({ handleFile }) => {
  const handleChange = (e) => {
    const files = e.target.files;
    if (files && files[0]) handleFile(files[0]);
  };

  return (
    <form>
      <input
        style={{ display: 'none' }}
        type="file"
        id="file"
        accept={SheetJSFT}
        onChange={handleChange}
        onClick={(event) => (event.target.value = null)}
      />
      <label htmlFor="file">
        <IconButton color="primary" aria-label="upload file" component="span">
          <PostAdd fontSize="large" color="primary" />
        </IconButton>
      </label>
    </form>
  );
};
