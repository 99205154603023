import React from 'react';

import './counts-modal.scss';

import { formatDate } from '../../../../shared/sharedFunctions';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Avatar from 'react-avatar';
import { connect } from 'react-redux';
import { displayFullName } from '../../../../../util/helpers';

const CountDetails = (props) => {
  const { scans, subLocations, users, date, tenant } = props;

  const useStyles = makeStyles({
    table: {
      minWidth: 550,
    },
    tableHead: {
      background: '#f5f7fb',
    },
  });

  const getItemById = (collection, id) => {
    return collection.find((item) => item.id === id);
  };

  const classes = useStyles();

  const getRows = () => {
    if (scans) {
      const items = [];

      for (let key in scans) {
        items.push({
          ...scans[key],
          subLocation: key,
          subTotal: scans[key].products.reduce((sum, item) => {
            return sum + item.quantity;
          }, 0),
        });
      }
      return items;
    } else {
      return [];
    }
  };

  const rows = getRows();

  return (
    <div className="w-100 h-100 m-t-lg">
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="data table">
          <TableHead>
            <TableRow className={classes.tableHead}>
              <TableCell>User</TableCell>
              <TableCell align="left">Date</TableCell>
              <TableCell align="left">Location</TableCell>
              <TableCell align="right">SubTotal</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name}>
                <TableCell align="left">
                  <div className="d-flex align-center">
                    <Avatar name={displayFullName(getItemById(users, row.userId).fullName)} size={24} round />
                    <div className="table-column">
                      <div style={{ fontWeight: 'bold', fontSize: 16, color: tenant.mainColor }}>
                        {displayFullName(getItemById(users, row.userId).fullName)}
                      </div>
                      <p className="small-text m-0">{getItemById(users, row.userId).email}</p>
                    </div>
                  </div>
                </TableCell>
                <TableCell align="left">
                  <p className="table-black-text m-0">{formatDate(tenant.dateFormat, date)}</p>
                </TableCell>
                <TableCell align="left">
                  <p className="table-black-text m-0">{getItemById(subLocations, row.subLocation).name}</p>
                </TableCell>
                <TableCell align="right">
                  <div style={{ fontWeight: 'bold', fontSize: 16, color: tenant.mainColor }}>{row.subTotal}</div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    tenant: state.tenant,
  };
};

export default connect(mapStateToProps)(CountDetails);
