import { TENANT_SIGN_IN, TENANT_SIGN_OUT, TENANT_UPDATE, TENANT_UPDATE_SCAN } from '../actions/actionTypes';

const initialState = {
  id: '',
  businessName: '',
  businessType: '',
  employeeCount: '',
  country: '',
  mainColor: '#00AAEF',
  dateFormat: '',
  dataEmail: '',
  dataEmail2: '',
  email: '',
  barCodeScans: {
    string: '',
    keys: [],
  },
  isLoaded: false,
};

const tenantReducer = (state = initialState, action) => {
  switch (action.type) {
    case TENANT_SIGN_IN:
      const { tenant } = action;
      return {
        ...tenant,
        isLoaded: true,
      };
    case TENANT_UPDATE:
      return { ...action.tenant, isLoaded: true };
    case TENANT_UPDATE_SCAN:
      return { ...state, barCodeScan: { ...action.value } };
    case TENANT_SIGN_OUT:
      return initialState;
    default:
      return state;
  }
};

export default tenantReducer;
