import React from 'react';

import { Link, withRouter } from 'react-router-dom';

import '../../aside.scss';
import { connect } from 'react-redux';

const LocationsAside = (props) => {
  const { tenantData } = props;

  return (
    <div className="container d-flex">
      <div className="tabs-list">
        <div className="tab-container">
          <Link to={'/locations'} style={{ textDecoration: 'none' }}>
            <p
              style={
                props.location.pathname === '/locations'
                  ? {
                      color: tenantData.mainColor,
                      fontSize: 16,
                      fontWeight: 'bold',
                    }
                  : {
                      color: '#5b667d',
                      fontSize: 16,
                      fontWeight: 'bold',
                    }
              }
            >
              Locations
            </p>
          </Link>
        </div>
        <div className="tab-container">
          <Link to={'/locations/sub-locations'} style={{ textDecoration: 'none' }}>
            <p
              style={
                props.location.pathname === '/locations/sub-locations'
                  ? {
                      color: tenantData.mainColor,
                      fontSize: 16,
                      fontWeight: 'bold',
                    }
                  : {
                      color: '#5b667d',
                      fontSize: 16,
                      fontWeight: 'bold',
                    }
              }
            >
              Sub-Locations
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    tenantData: state.tenant,
  };
};

export default withRouter(connect(mapStateToProps)(LocationsAside));
