import React, { useState } from 'react';

import { Table, TableContainer, TablePagination, Paper, makeStyles } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

import { ItemsCountedTableHead } from './ItemsCountedTableHead';
import { ItemsCountedTableBody } from './ItemsCountedTableBody';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  paper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    cursor: 'pointer',
    '& td': {
      minWidth: 150,
    },
  },
  container: {
    height: '100%',
    maxHeight: window.innerHeight - 260,
  },
  tabPaginationPaper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    border: 'none',
    boxShadow: 'none',
    paddingRight: 20,
    minWidth: 200,
  },
  paginationPaper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItem: 'center',
  },
  tabPagination: {
    display: 'flex',
    alignItems: 'flex-end',
    padding: 10,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  valueLabel: {
    color: '#707070',
    fontWeight: 'bold',
  },
  productValue: {
    fontWeight: 'bold',
    width: 100,
  },
}));

const ItemsCountedTable = (props) => {
  const classes = useStyles();
  const { rows, tenant, selected, setSelected } = props;

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('date');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((count) => count.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRowSelect = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePageTab = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <div id="locations-table" className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <ItemsCountedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <ItemsCountedTableBody
              classes={classes}
              rows={rows}
              page={page}
              order={order}
              orderBy={orderBy}
              rowsPerPage={rowsPerPage}
              isSelected={isSelected}
              handleRowSelect={handleRowSelect}
            />
          </Table>
        </TableContainer>
        <Paper className={classes.paginationPaper}>
          <TablePagination
            rowsPerPageOptions={[25, 50, 75, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage="Results"
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
          <Paper className={classes.tabPaginationPaper}>
            <div
              style={{
                fontWeight: 'bold',
                color: page === 0 ? '#dcdfe4' : tenant.mainColor,
                cursor: 'pointer',
              }}
              onClick={(e) => {
                if (page !== 0) {
                  setPage(page - 1);
                }
              }}
            >
              Prev
            </div>
            <Pagination
              className={classes.tabPagination}
              count={Math.ceil(rows.length / rowsPerPage)}
              page={page + 1}
              hideNextButton
              hidePrevButton
              onChange={handleChangePageTab}
              defaultPage={1}
              color="primary"
            />
            <div
              style={{
                fontWeight: 'bold',
                color: page < Math.ceil(rows.length / rowsPerPage) - 1 ? tenant.mainColor : '#dcdfe4',
                cursor: 'pointer',
              }}
              onClick={(e) => {
                if (page < Math.ceil(rows.length / rowsPerPage) - 1) {
                  setPage(page + 1);
                }
              }}
            >
              Next
            </div>
          </Paper>
        </Paper>
      </Paper>
    </div>
  );
};

export default ItemsCountedTable;
