import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';

import edit from '../../../../../assets/icons/edit.svg';
import remove from '../../../../../assets/icons/delete.svg';
import LoadScreen from '../../../../load-screen/LoadScreen';
import Alert from '../../../../shared/Alert';
import Pagination from '@material-ui/lab/Pagination';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Team Name' },
  { id: 'description', numeric: false, disablePadding: false, label: 'Description' },
  { id: 'edit', numeric: false, disablePadding: false, label: ' ' },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  paper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    height: '100%',
    cursor: 'pointer',
  },
  container: {
    maxHeight: window.innerHeight - 470,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tabPaginationPaper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    border: 'none',
    boxShadow: 'none',
    paddingRight: 20,
    minWidth: 200,
  },
  paginationPaper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItem: 'center',
  },
  tabPagination: {
    display: 'flex',
    alignItems: 'flex-end',
    padding: 10,
  },
}));

const EnhancedTable = (props) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorType, setErrorType] = useState('success');
  const [errorText, setErrorText] = useState('');

  const { rows, removeTeam, setModalEditMode, setModalEditTeam, getTeamData, openDrawer, tenantId, tenantData } = props;

  const editTeam = (id) => {
    setModalEditMode(true);
    setModalEditTeam(getTeamData(id));
    openDrawer();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleRowClick = (event, row) => {
    event.stopPropagation();
    editTeam(row.id);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getTeamMembers = (id) => {
    const { usersCollection } = props;

    return usersCollection.filter((user) => {
      return user.teams.some((team) => team === id);
    });
  };

  const handleChangePageTab = (event, newPage) => {
    setPage(newPage - 1);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <Fragment>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer className={classes.container}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody classes={{ root: classes.tableBody }}>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleRowClick(event, row)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell component="th" id={labelId} scope="row">
                          <div className="table-black-text">{row.name}</div>
                        </TableCell>
                        <TableCell align="left">
                          <p className="table-text m-0">{row.description}</p>
                        </TableCell>
                        <TableCell align="left">
                          <div className="d-flex flex-end align-center p-r-lg">
                            <img
                              src={edit}
                              onClick={(e) => {
                                e.stopPropagation();
                                editTeam(row.id);
                              }}
                              alt="edit"
                            />
                            <img
                              style={{ marginLeft: '24px' }}
                              src={remove}
                              onClick={(e) => {
                                e.stopPropagation();
                                setIsLoading(true);
                                removeTeam({ ...row, tenantId, members: getTeamMembers(row.id) })
                                  .then(() => {
                                    setIsLoading(false);
                                    setErrorText(`${row.name} was successfully removed`);
                                    setError(true);
                                  })
                                  .catch(() => {
                                    setIsLoading(false);
                                    setErrorText(`Permission denied`);
                                    setErrorType('error');
                                    setError(true);
                                  });
                              }}
                              alt="delete"
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <Paper className={classes.paginationPaper}>
            <TablePagination
              rowsPerPageOptions={[25, 50, 75, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage="Results"
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <Paper className={classes.tabPaginationPaper}>
              <div
                style={{
                  fontWeight: 'bold',
                  color: page === 0 ? '#dcdfe4' : tenantData.mainColor,
                  cursor: 'pointer',
                }}
                onClick={(e) => {
                  if (page !== 0) {
                    setPage(page - 1);
                  }
                }}
              >
                Prev
              </div>
              <Pagination
                className={classes.tabPagination}
                count={Math.ceil(rows.length / rowsPerPage)}
                page={page + 1}
                hideNextButton
                hidePrevButton
                onChange={handleChangePageTab}
                defaultPage={1}
                color="primary"
              />
              <div
                style={{
                  fontWeight: 'bold',
                  color: page < Math.ceil(rows.length / rowsPerPage) - 1 ? tenantData.mainColor : '#dcdfe4',
                  cursor: 'pointer',
                }}
                onClick={(e) => {
                  if (page < Math.ceil(rows.length / rowsPerPage) - 1) {
                    setPage(page + 1);
                  }
                }}
              >
                Next
              </div>
            </Paper>
          </Paper>
        </Paper>
      </div>
      {isLoading ? <LoadScreen /> : null}
      <Alert variant={errorType} message={errorText} open={error} onClose={() => setError(false)} />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    tenantData: state.tenant,
  };
};

export default connect(mapStateToProps)(EnhancedTable);
