import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Drawer, makeStyles } from '@material-ui/core';

import LoadScreen from '../../../../load-screen/LoadScreen';
import { GtinModalDetails } from './gtin-modal-details';

import CancelButton from '../../../../shared/CancelButton';
import StyledButton from '../../../../shared/Button';
import Alert from '../../../../shared/Alert';

import { createGtinReference, updateGtinReference } from '../../../../../actions/gtinActions';

const useStyles = makeStyles({
  drawer: {
    width: '100%',
  },
  drawerPaper: {
    boxShadow: '-4px 1px 13px 1px  rgba(87,73,87,1)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '50%',
  },
});

const GtinModal = (props) => {
  const classes = useStyles();
  const {
    drawerIsOpen,
    closeDrawer,
    editCurrentGtin,
    modalEditMode,
    createGtinReference,
    updateGtinReference,
    tenantData,
  } = props;

  const [gtin, setGtin] = useState('');
  const [ref, setRef] = useState('');
  const [description, setDescription] = useState('');
  const [classification, setClassification] = useState('');

  const [error, setError] = useState(false);
  const [errorType, setErrorType] = useState('error');
  const [errorText, setErrorText] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const stateEditSet = (editGtinReference) => {
      if (modalEditMode) {
        setGtin(editGtinReference.gtin);
        setRef(editGtinReference.ref);
        setDescription(editGtinReference.description);
        setClassification(editGtinReference.classification);
      } else {
        stateReset();
      }
    };

    stateEditSet(editCurrentGtin);
  }, [modalEditMode, editCurrentGtin]);

  const collectData = () => ({
    gtin,
    ref,
    description,
    classification,
  });

  const stateReset = () => {
    setGtin('');
    setRef('');
    setDescription('');
    setClassification('');
  };

  const onSubmit = (e) => {
    if (!gtin || !ref || !description || !classification) {
      setErrorText('Input all required fields');
      setErrorType('error');
      setError(true);
      return;
    }

    setIsLoading(true);

    if (modalEditMode) {
      updateGtinReference(tenantData.docId, editCurrentGtin.id, collectData());
    } else {
      createGtinReference(tenantData.docId, collectData());
    }

    closeDrawer();
    setIsLoading(false);
    stateReset();
  };

  return (
    <>
      <Drawer
        className={classes.drawer}
        anchor={'right'}
        open={drawerIsOpen}
        classes={{ paperAnchorRight: classes.drawerPaper }}
        ModalProps={{ onBackdropClick: closeDrawer }}
      >
        <div className="drawer-container">
          <div className="drawer-title">{modalEditMode ? 'Edit GTIN' : 'Add GTIN'}</div>
          {modalEditMode ? <div className="table-black-text">{editCurrentGtin.gtin}</div> : null}

          <GtinModalDetails
            gtin={gtin}
            setGtin={setGtin}
            reference={ref}
            setReference={setRef}
            description={description}
            setDescription={setDescription}
            classification={classification}
            setClassification={setClassification}
          />
        </div>
        <div className="submit-modal-footer">
          <CancelButton onClick={closeDrawer} />
          <StyledButton text="Save" onClick={onSubmit} />
        </div>
      </Drawer>
      {isLoading ? <LoadScreen /> : null}
      <Alert variant={errorType} message={errorText} open={error} onClose={() => setError(false)} />
    </>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createGtinReference,
      updateGtinReference,
    },
    dispatch,
  );

const mapStateToProps = (state) => ({
  tenantData: state.tenant,
});

export default connect(mapStateToProps, mapDispatchToProps)(GtinModal);
