import { AUTH_LOGIN, AUTH_CREATE_BUSINESS, AUTH_SIGN_UP, AUTH_RESET } from './actionTypes';

export const authLogin = (value) => (dispatch) => {
  dispatch({
    type: AUTH_LOGIN,
    value,
  });
};

export const authSignUp = (value) => (dispatch) => {
  dispatch({
    type: AUTH_SIGN_UP,
    value,
  });
};

export const authCreateBusiness = (value) => (dispatch) => {
  dispatch({
    type: AUTH_CREATE_BUSINESS,
    value,
  });
};

export const authReset = () => (dispatch) => {
  dispatch({
    type: AUTH_RESET,
  });
};
