import React from 'react';
import TextField from '@material-ui/core/TextField';

export const GtinModalDetails = (props) => {
  const { gtin, setGtin, reference, setReference, description, setDescription, classification, setClassification } =
    props;

  return (
    <div className="d-flex flex-1 direction-column">
      <div className="location-details m-b-md">
        <div className="m-t-lg">
          <TextField
            value={gtin}
            onChange={(e) => setGtin(e.target.value)}
            id="gtin"
            fullWidth
            label="GTIN"
            placeholder="Enter GTIN"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="m-t-lg">
          <TextField
            value={reference}
            onChange={(e) => setReference(e.target.value)}
            id="reference"
            fullWidth
            label="REF"
            placeholder="Enter REF"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="m-t-lg">
          <TextField
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            id="description"
            fullWidth
            label="Description"
            placeholder="Type description"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="m-t-lg">
          <TextField
            value={classification}
            onChange={(e) => setClassification(e.target.value)}
            id="classification"
            fullWidth
            label="Classification"
            placeholder="Type classification"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </div>
    </div>
  );
};
