import * as yup from 'yup';

export const userSchema = yup.object().shape({
  email: yup.string().email('Bad email format').required('Email is required').trim(' '),
  firstName: yup.string().required('First name is required').trim(' '),
  lastName: yup.string().required('Last name is required').trim(' '),
  role: yup.string().required('Role is required').oneOf(['Admin', 'Manager', 'Operative']),
  id: yup.string('id must be a string'),
  phone: yup.string(''),
  locations: yup.array(),
  teams: yup.array(),
  tenantId: yup.string(),
  description: yup.string(),
});
