import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { authSignUp } from '../../../actions/authActions';
import { checkSignUpData } from '../../../actions/userActions';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import Alert from '../../shared/Alert';
import LoadScreen from '../../load-screen/LoadScreen';

import './sign-up-form.scss';
import CustomButton from '../../shared/CustomButton';
import { displayFullName } from '../../../util/helpers';

const useStyles = makeStyles({
  input: {
    marginTop: 20,
    '&hover': {
      borderColor: '#00aeef',
    },
  },
  button: {
    backgroundColor: '#00aeef',
    borderColor: '#00aeef',
    float: 'center',
    color: '#ffffff',
    borderRadius: 30,
    paddingRight: 40,
    paddingLeft: 40,
    fontSize: 14,
    textAlign: 'center',
    marginLeft: 12,
    '&:hover': {
      borderColor: '#00aeef',
      color: '#00aeef',
    },
    '&:disabled': {
      color: 'gray',
      backgroundColor: '#f9f9f9',
    },
  },
  visibilityIcon: {
    cursor: 'pointer',
  },
});

const SignUpForm = (props) => {
  const classes = useStyles();

  const { loginData } = props;

  const [fullName, setFullName] = useState(displayFullName(loginData.fullName));
  const [email, setEmail] = useState(loginData.email);
  const [password, setPassword] = useState(loginData.password);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState(false);

  const emailValidation = (email) => {
    const regExp =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regExp.test(email.toLowerCase());
  };

  const changeEmail = (e) => {
    if (e.target.value !== ' ') {
      setEmail(e.target.value);
    } else {
      setEmail('');
      e.preventDefault();
    }
  };

  const changeFullName = (e) => {
    if (e.target.value !== ' ') {
      setFullName(e.target.value);
    } else {
      setFullName('');
      e.preventDefault();
    }
  };

  const changePassword = (e) => {
    if (e.target.value !== ' ') {
      setPassword(e.target.value);
    } else {
      setPassword('');
      e.preventDefault();
    }
  };

  const onSubmit = (e) => {
    const { authSignUp, checkSignUpData } = props;

    setIsLoading(true);

    if (!fullName || !email || !password) {
      setIsLoading(false);
      return;
    }
    if (emailValidation(email) === false) {
      setIsLoading(false);
      setErrorText('Not valid email');
      setError(true);
      return;
    }

    checkSignUpData(email)
      .then((res) => {
        setIsLoading(false);
        setErrorText(`${res.docs[0].data().email} is already in use`);
        setError(true);
      })
      .catch(() => {
        setIsLoading(false);
        authSignUp({ email, password, fullName });
        props.history.push('/business');
      });
  };

  return (
    <Fragment>
      <div className="w-100 d-flex flex-center align-center direction-column">
        <div className="subtitle-container">
          <p className="subtitle-text">Get started for free. No credit card require 30 day trial</p>
        </div>
        <form className="d-flex direction-column w-50 form-container" noValidate autoComplete="off">
          <TextField
            className={classes.input}
            value={fullName}
            id="full-name"
            type="text"
            label="Full Name"
            placeholder="E.g. Sam Smith"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                onSubmit();
              }
            }}
            onChange={changeFullName}
          />

          <TextField
            value={email}
            className={classes.input}
            id="email"
            type="email"
            label="Email"
            placeholder="E.g email@myemail.com"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                onSubmit();
              }
            }}
            onChange={changeEmail}
          />

          <TextField
            value={password}
            className={classes.input}
            id="password"
            type="password"
            label="Password"
            placeholder="Your password here"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                onSubmit();
              }
            }}
            onChange={changePassword}
          />
          <div className="sign-up-verify-info">
            <p className="sign-up-verify-text">We'll send a email link to verify your sign-up</p>
          </div>
          <div className="d-flex w-100 align-center flex-center submit-container">
            {/*<Button*/}
            {/*  onClick={onSubmit}*/}
            {/*  className={classes.button}*/}
            {/*  color="primary"*/}
            {/*  variant="outlined"*/}
            {/*  disabled={email === '' || password === '' || fullName === ''}*/}
            {/*>*/}
            {/*  Get started for free*/}
            {/*</Button>*/}
            <CustomButton
              onClick={onSubmit}
              color={'transparent'}
              borderColor="#00aee"
              textColor="#00aeef"
              width="max-content"
              disabled={email === '' || password === '' || fullName === ''}
              text="Get started for free"
            />
          </div>
        </form>
      </div>
      <Alert
        variant="error"
        message={errorText}
        open={error}
        onClose={() => {
          setError(false);
        }}
      />
      {isLoading ? <LoadScreen /> : null}
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      authSignUp,
      checkSignUpData,
    },
    dispatch,
  );

const mapStateToProps = (state) => {
  return {
    loginData: state.auth,
  };
};

SignUpForm.propTypes = {
  authSignUp: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpForm);
