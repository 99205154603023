import React from 'react';
import TextField from '@material-ui/core/TextField';

import './team-modal.scss';

const TeamDetails = (props) => {
  const { name, setName, description, setDescription } = props;

  return (
    <div className="d-flex flex-1 direction-column">
      <div className="drawer-title">Team Details</div>
      <div className="team-details">
        <TextField
          value={name}
          onChange={(e) => setName(e.target.value)}
          id="team-name"
          fullWidth
          label="Team Name"
          placeholder="E.g Warehouse Team"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          id="description"
          fullWidth
          label="Description"
          placeholder="Enter description"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
    </div>
  );
};

export default TeamDetails;
