import {
  COUNTS_COLLECTION_ADD,
  COUNTS_COLLECTION_CLEAR,
  COUNTS_COLLECTION_DELETE,
  COUNTS_COLLECTION_GET,
  COUNTS_COLLECTION_UPDATE,
} from '../actions/actionTypes';

const initialState = { list: [], isLoaded: false };

const countsReducer = (state = initialState, action) => {
  switch (action.type) {
    case COUNTS_COLLECTION_ADD:
      return { ...state, list: [...state.list, action.value] };
    case COUNTS_COLLECTION_UPDATE:
      return { ...state, list: state.list.map((count) => (count.id === action.count.id ? action.count : count)) };
    case COUNTS_COLLECTION_DELETE:
      return {
        ...state,
        list: state.list.filter((item) => item.id !== action.id),
        isLoaded: false,
      };
    case COUNTS_COLLECTION_GET:
      return { list: action.value, isLoaded: true };
    case COUNTS_COLLECTION_CLEAR:
      return initialState;
    default:
      return state;
  }
};

export default countsReducer;
