import React, { Fragment, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import StyledButton from '../../../../shared/Button';

import { updateLocation } from '../../../../../actions/locationsCollectionActions';
import { createLocation } from '../../../../../actions/locationsCollectionActions';
import { createSchedule } from '../../../../../actions/schedulesActions';
import { updateSchedule } from '../../../../../actions/schedulesActions';
import { deleteSchedule } from '../../../../../actions/schedulesActions';
import { createCount } from '../../../../../actions/countsActions';

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import LoadScreen from '../../../../load-screen/LoadScreen';
import Alert from '../../../../shared/Alert';

import ScheduleSettings from './ScheduleSettings';
import ScheduleTeams from './ScheduleTeams';
import ScheduleLocations from './ScheduleLocations';
import Scheduling from './Scheduling';
import CancelButton from '../../../../shared/CancelButton';

const SchedulesModal = (props) => {
  const useStyles = makeStyles({
    drawer: {
      width: '100%',
    },
    drawerPaper: {
      boxShadow: '-4px 1px 13px 1px  rgba(87,73,87,1)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '50%',
    },
    nameInput: {
      width: '40%',
    },
    positionInput: {
      width: '100%',
    },
    tab: {
      marginTop: 20,
      marginBottom: 20,
      borderBottom: '1px solid #E9E9E9',
      padding: 0,
    },
    tabItem: {
      display: 'flex',
      padding: '0 !important',
      justifyContent: 'flex-start !important',
      textAlign: 'left',
    },
    radioGroup: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    startTextWrapper: {
      alignItems: 'flex-start !important',
    },
  });

  const classes = useStyles();
  const {
    drawerIsOpen,
    closeDrawer,
    tenantData,
    subLocationsData,
    locationsData,
    editMode,
    editSchedule,
    createSchedule,
    teamsData,
    usersData,
    updateSchedule,
    createCount,
    user,
  } = props;

  useEffect(() => {
    const stateEditSet = (editSchedule) => {
      if (editMode) {
        setName(editSchedule.name);
        setDate(editSchedule.date);
        setDuration(editSchedule.duration);
        setFrequency(editSchedule.frequency);
        setSingleCountOnly(editSchedule.singleCountOnly);
        setTeams(editSchedule.teams);
        setUsers(editSchedule.users);
        setMainLocation(locationsData.find((item) => editSchedule.location === item.id).name);
        setSubLocations(editSchedule.subLocations);
      } else {
        stateReset();
      }
    };

    stateEditSet(editSchedule);
  }, [editMode, editSchedule, locationsData]);

  const listText = {
    fontWeight: 'bold',
    color: '#5b667d',
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    margin: 0,
    padding: 0,
  };

  const [name, setName] = useState('');
  const [date, setDate] = useState(new Date(Date.now()));
  const [frequency, setFrequency] = useState('');
  const [duration, setDuration] = useState('');
  const [singleCountOnly, setSingleCountOnly] = useState(false);
  const [teams, setTeams] = useState([]);
  const [users, setUsers] = useState([]);
  const [mainLocation, setMainLocation] = useState('');
  const [subLocations, setSubLocations] = useState([]);
  const [error, setError] = useState(false);
  const [errorType, setErrorType] = useState('error');
  const [errorText, setErrorText] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [drawerTabValue, setDrawerTabValue] = useState(0);

  const addTeam = (team) => {
    if (!team || teams.includes(team.id)) {
      return;
    }
    setTeams([...teams, team.id]);
  };

  const addUser = (user) => {
    if (!user || users.includes(user.id)) {
      return;
    }
    setUsers([...users, user.id]);
  };

  const addSubLocation = (subLocation) => {
    const subLocationsId = subLocation.filter((item) => !subLocations.includes(item.id)).map((item) => item.id);

    if (!subLocation || subLocations.some((item) => item === subLocation.includes(item))) {
      return;
    }
    setSubLocations([...subLocations, ...subLocationsId]);
  };

  const removeUser = (id) => {
    setUsers(users.filter((item) => item !== id));
  };

  const removeTeam = (id) => {
    setTeams(teams.filter((item) => item !== id));
  };

  const removeSubLocation = (id) => {
    setSubLocations(subLocations.filter((item) => item !== id));
  };

  const stateReset = () => {
    setName('');
    setDate(new Date(Date.now()));
    setDuration('');
    setFrequency('');
    setSingleCountOnly(false);
    setTeams([]);
    setUsers([]);
    setMainLocation('');
    setSubLocations([]);
  };

  const collectData = () => {
    return {
      name,
      date: new Date(date),
      duration,
      frequency,
      singleCountOnly,
      teams,
      location: locationsData.find((item) => mainLocation === item.name).id,
      users,
      subLocations,
      lastCreatedCount: date,
      tenantId: tenantData.id,
    };
  };

  const generateCustomCountId = () => {
    return `${mainLocation.slice(0, 2)}${Date.now()}`.toUpperCase();
  };

  const collectCountData = () => {
    return {
      date,
      location: locationsData.find((item) => mainLocation === item.name).id,
      users,
      teams,
      subLocations,
      tenantId: tenantData.id,
      schedule: null,
    };
  };

  const drawerTabValueHandleChange = (event, newValue) => {
    setDrawerTabValue(newValue);
  };

  const onSubmit = (e) => {
    if (!name || !date || !mainLocation || (!singleCountOnly && (!duration || !frequency))) {
      setErrorText('Input all required fields');
      setErrorType('error');
      setError(true);
      return;
    }

    if (user.role === 'Operative') {
      setErrorText('Permission Denied');
      closeDrawer();
      setErrorType('error');
      setError(true);
      return;
    }

    setIsLoading(true);
    if (editMode) {
      updateSchedule({
        ...collectData(),
        id: editSchedule.id,
      }).then(() => {
        closeDrawer();
        setIsLoading(false);
      });
    } else {
      if (singleCountOnly) {
        createCount(collectCountData(), generateCustomCountId()).then(() => {
          closeDrawer();
          setIsLoading(false);
        });
      } else {
        createSchedule(collectData()).then((schedule) => {
          createCount({ ...collectCountData(), schedule }, generateCustomCountId()).then(() => {
            closeDrawer();
            setIsLoading(false);
          });
        });
      }
    }
  };

  const tabValueHandler = () => {
    if (drawerTabValue === 0) {
      return (
        <ScheduleSettings
          name={name}
          setName={setName}
          singleCountOnly={singleCountOnly}
          setSingleCountOnly={setSingleCountOnly}
          date={date}
          setDate={setDate}
          frequency={frequency}
          setFrequency={setFrequency}
          duration={duration}
          setDuration={setDuration}
        />
      );
    } else if (drawerTabValue === 1) {
      return (
        <ScheduleTeams
          users={users}
          teams={teams}
          addUser={addUser}
          addTeam={addTeam}
          removeUser={removeUser}
          removeTeam={removeTeam}
          teamsData={teamsData}
          usersData={usersData}
          subLocationsData={subLocationsData}
          listText={listText}
        />
      );
    } else if (drawerTabValue === 2) {
      return (
        <ScheduleLocations
          mainLocation={mainLocation}
          subLocations={subLocations}
          setMainLocation={setMainLocation}
          addSubLocation={addSubLocation}
          removeSubLocation={removeSubLocation}
          locationsData={locationsData}
          subLocationsData={subLocationsData}
          listText={listText}
        />
      );
    } else if (drawerTabValue === 3) {
      return (
        <Scheduling
          frequency={frequency}
          duration={duration}
          setFrequency={setFrequency}
          setDuration={setDuration}
          singleCountOnly={singleCountOnly}
          setSingleCountOnly={setSingleCountOnly}
        />
      );
    }
  };

  return (
    <Fragment>
      <Drawer
        className={classes.drawer}
        anchor={'right'}
        open={drawerIsOpen}
        classes={{ paperAnchorRight: classes.drawerPaper }}
        ModalProps={{ onBackdropClick: closeDrawer }}
      >
        <div className="drawer-container">
          <div className="drawer-title m-t-md">{editMode ? 'Edit Schedule' : 'Add Schedule'}</div>
          {editMode ? <div className="table-black-text">{editSchedule.name}</div> : null}
          <Paper elevation={0}>
            <Tabs
              value={drawerTabValue}
              indicatorColor="primary"
              textColor="primary"
              className={classes.tab}
              onChange={drawerTabValueHandleChange}
            >
              <Tab className={classes.tabItem} classes={{ wrapper: classes.startTextWrapper }} label="Settings" />
              <Tab className={classes.tabItem} classes={{ wrapper: classes.startTextWrapper }} label="Users & Teams" />
              <Tab className={classes.tabItem} classes={{ wrapper: classes.startTextWrapper }} label="Locations" />
              <Tab className={classes.tabItem} classes={{ wrapper: classes.startTextWrapper }} label="Schedule" />
            </Tabs>
          </Paper>
          {tabValueHandler()}
        </div>
        <div className="submit-modal-footer">
          <CancelButton onClick={closeDrawer} />
          <StyledButton text="Save" onClick={onSubmit} />
        </div>
      </Drawer>
      {isLoading ? <LoadScreen /> : null}
      <Alert variant={errorType} message={errorText} open={error} onClose={() => setError(false)} />
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateLocation,
      createLocation,
      createSchedule,
      updateSchedule,
      deleteSchedule,
      createCount,
    },
    dispatch,
  );

const mapStateToProps = (state) => {
  return {
    tenantData: state.tenant,
    teamsData: state.teams,
    subLocationsData: state.subLocations,
    locationsData: state.locations,
    usersData: state.usersCollection,
    user: state.user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SchedulesModal);
