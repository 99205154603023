import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, Redirect, withRouter } from 'react-router-dom';

import LocationsAside from '../../../components/app/locations/aside/LocationsAside';
import LocationsManager from '../../../components/app/locations/locations-manager/LocationsManager';
import SchedulesManager from '../../../components/app/locations/schedules-manager/SchedulesManager';

import '../../page.scss';

const Locations = (props) => {
  return (
    <Router>
      <div className="page-container">
        <div className="main-container">
          <div className="page-aside-container">
            <LocationsAside />
          </div>
          <div className="page-main-container">
            <Switch>
              <Route exact path="/locations" component={withRouter(LocationsManager)} />
              <Route path="/locations/schedules" component={withRouter(SchedulesManager)} />
              <Route exact path="/locations/sub-locations" component={withRouter(LocationsManager)} />
              <Redirect to="/users" />
            </Switch>
          </div>
        </div>
      </div>
    </Router>
  );
};

const mapStateToProps = (state) => {
  return {
    loginData: state.auth,
    userData: state.user,
    tenantData: state.tenant,
  };
};

export default connect(mapStateToProps)(Locations);
