import React, { useState } from 'react';

import { formatDate } from '../../../shared/sharedFunctions';

import { TextField, Button } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import PublishIcon from '@material-ui/icons/Publish';
import { ChromePicker } from 'react-color';
import CustomBarcodeEdit from '../custom-barcode/CustomBarcodeEdit';

const CustomizeFields = (props) => {
  const {
    color,
    setColor,
    dateFormat,
    setDateFormat,
    logoFile,
    setLogoFile,
    scanType,
    changeScanType,
    onEditField,
    addField,
    saveField,
    deleteField,
    fields,
  } = props;

  const dateFormats = ['MM/DD/YYYY', 'DD/MM/YYYY', 'YYYY.MM.DD', 'YYYY/MM/DD'];

  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleColorChange = (color) => {
    setColor(color.hex);
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setLogoFile(e.target.files[0]);
    }
  };

  const popover = {
    position: 'absolute',
    zIndex: '2',
  };
  const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  };

  return (
    <div className="w-100 h-100 d-flex direction-column">
      <div className="w-100">
        <div className="drawer-title">Colour</div>
        <div className="w-100 p-t-lg">
          <TextField
            fullWidth
            label="Main Colour"
            value={color}
            InputProps={{
              startAdornment: (
                <div
                  style={{
                    width: 20,
                    height: 20,
                    background: color,
                    marginRight: 10,
                    borderRadius: 4,
                  }}
                  onClick={handleClick}
                ></div>
              ),
              readOnly: true,
            }}
          />
          {displayColorPicker ? (
            <div style={popover}>
              <div style={cover} onClick={handleClose} />
              <ChromePicker color={color} onChange={handleColorChange} />
            </div>
          ) : null}
        </div>
      </div>
      <div className="w-100 m-t-lg">
        <div className="drawer-title">Company Logo</div>
        <div className="w-100 d-flex align-center">
          <div className="w-50 m-t-lg">
            <TextField
              fullWidth
              label="Logo file"
              value={logoFile ? logoFile.name : ''}
              InputProps={{
                readOnly: true,
              }}
            />
          </div>
          <div className="w-50">
            <Button variant="contained" component="label">
              <PublishIcon />
              <input type="file" onChange={handleFileChange} style={{ display: 'none' }} />
            </Button>
          </div>
        </div>
      </div>
      <div className="w-100 m-t-lg">
        <div className="drawer-title">Data Format</div>
        <div className="w-100 m-t-lg d-flex space-between">
          <div className="w-40">
            <Autocomplete
              options={dateFormats}
              onChange={(e, v) => {
                setDateFormat(v);
              }}
              value={dateFormat}
              id="sub-locations"
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Select Date Format"
                  placeholder="DD-MM-YYYY"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </div>
          <div className="w-40">
            <TextField
              fullWidth
              label="Example Data"
              value={formatDate(dateFormat, new Date())}
              InputProps={{
                readOnly: true,
              }}
            />
          </div>
        </div>
      </div>
      <CustomBarcodeEdit
        saveField={saveField}
        onEditField={onEditField}
        addField={addField}
        handleChange={changeScanType}
        scanType={scanType}
        fields={fields}
        deleteField={deleteField}
      />
    </div>
  );
};

export default CustomizeFields;
