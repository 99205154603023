import { isBefore } from 'date-fns';

const isCountCompleted = (count) => {
  if (count.archived) {
    return false;
  }
  if (!count.scans || !Object.keys(count.scans).length) {
    return false;
  }

  return Object.keys(count.scans).every((key) => count.scans[key].submitted);
};

const isCountInProgress = (count) => {
  if (count.archived) {
    return false;
  }
  if (!count.scans || !Object.keys(count.scans).length) {
    return count.date && isBefore(count.date, new Date());
  }
  return !Object.keys(count.scans).every((key) => count.scans[key].submitted);
};

const isCountScheduled = (count) => {
  if (count.archived) {
    return false;
  }
  if (count.date && isBefore(count.date, new Date())) {
    return false;
  }
  return !count.scans || !Object.keys(count.scans).length;
};

const getCountProductsTotal = (count) => {
  const products = [];
  if (count.scans) {
    for (let key in count.scans) {
      count.scans[key].products.forEach((product) => {
        products.push(product);
      });
    }
    return products.reduce((total, item) => {
      return total + item.quantity;
    }, 0);
  } else {
    return 0;
  }
};

export { isCountCompleted, isCountInProgress, isCountScheduled, getCountProductsTotal };
